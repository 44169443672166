import useClickOutside from "../../hooks/useClickOutside";
import styles from "./DropdownList.module.css";
import ArrowDown from "../../components/icons/ArrowDown";


interface IProps {
    customClassName?: string,
    dropdownListFor?: string,
    data: any [], 
    nameKey: string, 
    selectedValue: string | number, 
    clickHandler: (args: any) => void,
}


const DropdownList = (props: IProps) => {

    const { customClassName = "", dropdownListFor = "", data, nameKey, selectedValue, clickHandler } = props;

    const { elementRef, isVisible, setIsVisible } = useClickOutside({ exceptions: [] });
    const toggleDropdown = () => setIsVisible(!isVisible);

    return (
        <div className = {`${styles.dropdown_list} ${styles[customClassName]}`} >
            <div 
                className = {styles.hidden_div}
                ref = {elementRef}
                onClick = {toggleDropdown}
            >
            </div>
            <p>
                {dropdownListFor === "view_item" ? `Show ${selectedValue}` : selectedValue}
            </p>
            <ArrowDown />
            <div 
                className={styles.dropdown_item_list} 
                style={{ display: isVisible ? "block" : "none" }}
            >
                <ul>
                    {data.map(item => {
                        return (
                            <li 
                                key = {item._id} 
                                onClick = {() => clickHandler(item)}
                            >
                                {item[nameKey]}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );

}


export default DropdownList;