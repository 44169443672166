import { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../auth/UserAuth";
import { RootState } from "../../store";
import { FETCH_CALENDAR_API_DATA, FETCH_APPOINTMENT_DATA } from "../../store/calendar/constants";
import { setSomeInitialState, updateState } from "../../store/calendar/calendarReducer";
import styles from "./Calendar.module.css";
import Modal from "../../components/common/modal/Modal";
import LoadingError from "./common/LoadingError";
import CalendarHeader from "./calendar-header/CalendarHeader";
import DayView from "./DayView";
import WeekView from "./WeekView";
import MonthView from "./MonthView";
import Hours from "./Hours";
import ClientSearch from "./client-search/ClientSearch";
import AppointmentModal from "./appointment-modal/AppointmentModal";
import AddAppointmentModal from "./book-appointment/AddAppointmentModal";
import ClientDetailsModal from "./client-search/ClientDetailsModal";
import ActivityByModal from "./appointment-modal/ActivityByModal";
import ActionMessage from "../../components/common/messages/ActionMessage";
import RescheduleModal from "./reschedule-modal/RescheduleModal";
import DeleteAppointment from "./DeleteAppointment";
import SelectLocation from "./SelectLocation";


const Calendar: React.FC = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const userState = useSelector((state: RootState) => state.userState);
    const { 
        loading,
        appointmentList,
        selectedLocationIds,
        locationList,
        selectedStylistList,
        weekViewDates,
        monthViewDates,
        calendarViewType, 
        isRescheduling, 
        isBookingAppointment,
        appointmentInAction,
        clientInAction,
        clientAppointments,
        clientList,
        showActivityByModal,
        serverResponseMessage,
        isDeleting, 
    } = calendarState;
    const { user } = userState;

    useEffect(() => {

        if(!UserAuth()) {
            navigate("/");
            return;
        }

        if(!user) return;

        // User must be on trial period or must have bought a sub plan to access the calendar
        if(!user.stripeSubscription && !user.isOnTrialPeriod) {
            navigate("/");
            return;
        }

        dispatch(setSomeInitialState());
        dispatch({ type: FETCH_CALENDAR_API_DATA });

    }, [user, navigate, dispatch]);

    useEffect(() => {

        const timer = setTimeout(() => {
            if(selectedLocationIds === "") return;
            dispatch({ type: FETCH_APPOINTMENT_DATA });
        }, 10)

        return () => clearTimeout(timer);
       
    }, [selectedLocationIds, dispatch])

    const switchToDayView = useCallback((monthDate: Date) => {
        dispatch(updateState({
            name: "calendarViewType",
            value: "day"
        }));
        dispatch(updateState({
            name: "dayViewDate",
            value: monthDate
        }));
    }, [dispatch])

    const hideActionMessage = () => {
        dispatch(updateState({
            name: "serverResponseMessage",
            value: ""
        }))
    }

    const closeModal = () => {
        dispatch(updateState({
            name: "clientInAction",
            value: null
        }));
        dispatch(updateState({
            name: "clientAppointments",
            value: []
        }));
    }

    return (
        <>
            <div className = {styles.calendar_route}>
                {
                    serverResponseMessage
                    &&
                    <ActionMessage 
                        actionMessage = {serverResponseMessage}
                        hideActionMessage={hideActionMessage}
                    />
                }
                <div className={styles.search_select_location}>
                    <ClientSearch />
                    {locationList.length > 1 ? <SelectLocation />: null}
                </div>
                <div className = {styles.calendar}>
                    {
                        loading
                        ?
                        <LoadingError 
                            message="Fetching calendar data..."
                        />
                        :
                        (selectedStylistList.length < 1 || locationList.length < 1)
                        ?
                        <LoadingError 
                            message="No calendar to show"
                        />
                        :
                        <>
                            <CalendarHeader />
                            <div className = {styles.main_calendar}>
                                {calendarViewType !== "month" && <Hours />}
                                {calendarViewType === "day" && <DayView />}
                                {
                                    calendarViewType === "week"
                                    ?
                                    <WeekView 
                                        user={user}
                                        selectedStylistList={selectedStylistList}
                                        appointmentList={appointmentList}
                                        weekViewDates={weekViewDates}
                                        switchToDayView={switchToDayView}
                                    />
                                    :
                                    null
                                }
                                {
                                    calendarViewType === "month"
                                    ? 
                                    <MonthView 
                                        selectedStylistList={selectedStylistList}
                                        appointmentList={appointmentList}
                                        weekViewDates={weekViewDates}
                                        monthViewDates={monthViewDates}
                                        switchToDayView={switchToDayView}
                                    />
                                    :
                                    null
                                }
                            </div>
                        </>
                    }
                </div>
            </div>
            {appointmentInAction ? <AppointmentModal /> : null}
            {isBookingAppointment ? <AddAppointmentModal /> : null}
            {
                (clientInAction && !isBookingAppointment)
                ?
                <Modal 
                    minWidth="50%"
                    zIndex="3"
                    modalContent={<ClientDetailsModal />}
                    onCloseModal={closeModal}
                />
                :
                null
            }
            {showActivityByModal ? <ActivityByModal /> : null}
            {isRescheduling ? <RescheduleModal /> : null}
            {isDeleting && appointmentInAction ? <DeleteAppointment />: null}
        </>
    );

}

export default Calendar;