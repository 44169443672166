import styles from "./CalendarHeader.module.css";
import DateNavigation from "./DateNavigation";
import ViewNavigation from "./ViewNavigation";
import ViewDateInfo from "./ViewDateInfo";


const CalendarHeader: React.FC = () => {

    return (  
        <div className={styles.calendar_header}>
            <DateNavigation />
            <ViewDateInfo />
            <ViewNavigation />
        </div>
    );

}

export default CalendarHeader;