import { useSelector, useDispatch } from "react-redux";
import { FaRegClock } from "react-icons/fa";
import { RootState } from "../../../store";
import { AvailableDateTimes } from "../../../types/pages/Calendar";
import { addNewAppointmentInfo } from "../../../store/calendar/calendarReducer";
import useWindowWidth from "../../../hooks/useWindowWidth";
import styles from "./DateCard.module.css";
import HiddenDiv from "../common/HiddenDiv";
import CheckIcon from "../common/CheckIcon";

interface Iprops {
    dateTime: AvailableDateTimes,
    dateSlideLeftPosition: string | number
}


const DateCard: React.FC<Iprops> = ({ dateTime, dateSlideLeftPosition }) => {

    const { windowWidth } = useWindowWidth();
    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { selectedDateForRescheduling } = calendarState.newAppointmentInfo;

    const { dateString, dateItem: { date, weekDay, month } } = dateTime; 

    const selectDate = (choosenDate: string) => {
        dispatch(addNewAppointmentInfo({
            name: "selectedDateForRescheduling",
            value: choosenDate
        }));
    } 

    // Dynamic Styling 
    const gradient = "linear-gradient(to bottom, #67DAF8, #0844CC)";
    const stylingCondition1 = selectedDateForRescheduling === dateString;
    const dynamicStyles = {
        cardWrapper: { left: dateSlideLeftPosition },
        card: { 
            display: windowWidth > 800 ? "flex" : "none",
            backgroundImage: stylingCondition1 ? gradient : "none" 
        },
        responsive_card: { 
            display: windowWidth <= 800 ? "flex" : "none",
            backgroundImage: stylingCondition1 ? gradient : "none"
        },
        p1: { color: stylingCondition1 ? "white" : "rgba(0, 0, 0, 0.5)" },
        p2: { color: stylingCondition1 ? "white" : "#2666CF", fontSize: "2rem", fontWeight: "bold" },
        p3: { color: stylingCondition1 ? "white" : "#2666CF" },
        icon: { fontSize: "24px", color: "#2666CF" }
    }

    return (
        <>
            <div className = {styles.date_card_wrapper} style = {dynamicStyles.cardWrapper}>
                <HiddenDiv 
                    handleClick = {selectDate}
                    handleClickArgs = {dateString} 
                />
                <div className={styles.date_card} style = {dynamicStyles.card}>
                    <CheckIcon />
                    <div className = {styles.date_card_content}>
                        <p style = {dynamicStyles.p1}>{weekDay}</p>
                        <p style = {dynamicStyles.p2}>{date}</p>
                        <p style = {dynamicStyles.p3}>{month}</p>
                    </div>
                    <span className = {styles.clockIcon_container}>
                        <FaRegClock 
                            color ="#2666CF" 
                            size = "1.5rem" 
                        />
                    </span>
                </div>
                <div className = {styles.responsive_date_card} style = {dynamicStyles.responsive_card}>
                    <CheckIcon />
                    <p style = {{ color: stylingCondition1 ? "white" : "black" }}>{weekDay}</p>
                    <p style = {{ color: stylingCondition1 ? "white" : "black" }}>{date}</p>
                    <p style = {{ color: stylingCondition1 ? "white" : "black" }}>{month}</p>
                </div>
            </div>
        </>
    );

}


export default DateCard;