import { IClientPackage } from "../../types/models/ClientPackage";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import styles from "./index.module.css";
import ClientPackageItemInfo from "./ClientPackageItemInfo";

interface ClientPackageDetailsProps {
    clientPkgInAction: IClientPackage
}


const ClientPackageDetails: React.FC<ClientPackageDetailsProps> = ({ clientPkgInAction }) => {

    const { day, month, year, hours, minutes } = formatDateTime(new Date(clientPkgInAction.boughtAt * 1000));
    const paymentMethod = clientPkgInAction.paymentMethod === "credit_card" ? "CREDIT CARD" : clientPkgInAction.paymentMethod.toUpperCase();

    const itemInfoList = [
        { title: "Buy Date", content: `${month} ${day}, ${year} @ ${hours}:${minutes}` },
        { title: "Title", content: clientPkgInAction.title },
        { title: "Description", content: clientPkgInAction.description },
        { title: "Client Name", content: clientPkgInAction.clientName },
        { title: "ClientEmail", content: clientPkgInAction.clientEmail },
        { title: "Client Phone", content: clientPkgInAction.clientPhone },
        { title: "Price", content: `€${clientPkgInAction.price.toFixed(2)}` },
        { title: "Payment Method", content: paymentMethod },
        { title: "Bought Online", content: clientPkgInAction.isBoughtAndPaidOnline ? "Yes" : "No" },
        { title: "Status", content: clientPkgInAction.status === "active" ? "Active" : "Used Up" }
    ];

    return (
        <div className={styles.client_pkg_details}>
            {itemInfoList.map(item => {
                return (
                    <ClientPackageItemInfo 
                        key={item.title}
                        title={item.title}
                        content={item.content}
                    />
                );
            })}
            <div className={styles.pkg_treatment_list}>
                <h3>Treatments And Sessions</h3>
                <table>
                    <tbody>
                        {clientPkgInAction.treatmentList.map(item => {
                            return (
                                <tr key={item.treatmentId}>
                                    <td>{item.treatmentTitle}</td>
                                    <td>{item.sessionsUsed} used</td>
                                    <td>{item.sessionsLeft} left</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );

}

export default ClientPackageDetails;