const CouponIcon = (): JSX.Element => {

    return (
        <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.6669 13.15L19.6667 13.15L2.33333 13.15H2.33314C1.94002 13.1495 1.56292 12.9931 1.28498 12.7149C1.0069 12.437 0.850509 12.0599 0.85 11.6669L0.85 11.6667L0.85 10.2637V10.2632C0.851159 9.91891 0.971824 9.58572 1.19148 9.32054L1.30699 9.41623L1.19148 9.32054C1.41082 9.05575 1.71532 8.87528 2.05288 8.80998C2.60938 8.691 3.08026 8.3227 3.3296 7.81108L3.32962 7.81105C3.57931 7.29901 3.57918 6.70048 3.32934 6.18857L3.32933 6.18854C3.07983 5.67703 2.60877 5.30872 2.05217 5.19002C1.71473 5.12457 1.41054 4.94412 1.19133 4.67931L19.6669 13.15ZM19.6669 13.15C20.06 13.1495 20.4371 12.9931 20.715 12.7149C20.9931 12.437 21.1495 12.0599 21.15 11.6669V11.6667V2.33333V2.33314C21.1495 1.94003 20.9931 1.56294 20.715 1.28499C20.437 1.00689 20.0599 0.850497 19.6669 0.85H19.6667L2.33334 0.85L2.33314 0.85C1.94006 0.850509 1.56301 1.0069 1.28506 1.28498C1.28505 1.28499 1.28504 1.285 1.28503 1.28502C1.28502 1.28503 1.285 1.28504 1.28499 1.28505C1.00689 1.56298 0.8505 1.94005 0.850003 2.33314V2.33333L0.850003 3.7363L0.850004 3.73681M19.6669 13.15L0.850004 3.73681M0.850004 3.73681C0.851165 4.08104 0.971645 4.41407 1.1913 4.67926L0.850004 3.73681ZM2.48333 2.48314L19.5167 2.48182L19.5167 11.5165H2.48587L2.50487 10.3815C3.50613 10.1335 4.34858 9.45333 4.80072 8.52153L4.66577 8.45604L4.80072 8.52153C5.27034 7.55367 5.26633 6.42299 4.78968 5.45851C4.33127 4.53085 3.48524 3.85714 2.48333 3.61583V2.48314Z" fill="#99B2C6" stroke="#99B2C6" strokeWidth="0.3"/>
            <path d="M15.0892 12.2442C15.2423 12.3973 15.4501 12.4833 15.6667 12.4833C15.8832 12.4833 16.091 12.3973 16.2442 12.2442C16.3973 12.091 16.4833 11.8832 16.4833 11.6667V1.66666C16.4833 1.37484 16.3277 1.10522 16.075 0.959374C15.8223 0.813542 15.511 0.813543 15.2583 0.959373C15.0057 1.10522 14.85 1.37483 14.85 1.66666V11.6667C14.85 11.8832 14.936 12.091 15.0892 12.2442Z" fill="#99B2C6" stroke="#99B2C6" strokeWidth="0.3"/>
        </svg>

    );

}

export default CouponIcon;
