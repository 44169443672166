import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { config } from "../../Constants";
import { RootState } from "../../store";
import { FETCH_PACKAGE_DATA } from "../../store/packages/constants";
import { 
    addNewPackageInfo,
    closeAddEditPackageModal,
    updateState 
} from "../../store/packages/packageReducer";
import { getAuthTokenConfig } from "../../helpers/others/getAuthTokenConfig";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import useWindowWidth from "../../hooks/useWindowWidth";
import { Modal, ModalBackground, ModalContent } from "../../components/common/Modal";
import styles from "./index.module.css";
import InputField from "./InputField";
import TextAreaInputField from "./TextAreaInputField";
import Button from "./Button";
import SelectTreatment from "./SelectTreatment";
import PackageHighlights from "./PackageHighlights";


const AddEditPackage = () => {

    const dispatch = useDispatch();
    const packageState = useSelector((state: RootState) => state.packageState);
    const { devicesWidth: { isLargeDevice } } = useWindowWidth();
    const { 
        treatmentList,
        newPackageInfo, 
        validationError, 
        isAdding, 
        isEditing, 
        packageItemInAction,
        searchText, 
        skip, 
        limit
    } = packageState;
    const { 
        title, 
        description, 
        price, 
        highlights,
        selectedTreatmentList,
        areAllTreatmentsSelected,
        allTreatmentsSessionCount 
    } = newPackageInfo;

    const [hightlightItem, setHighlightItem] = useState<string>(""); 
    const [validationErrorMsg, setValidationErrorMsg] = useState<string>();

    const closeModal = () => {
        dispatch(closeAddEditPackageModal());
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    
        const { name, value } = event.target;

        if(name === "highlightItem") {
            setHighlightItem(value);
            return;
        }

        dispatch(addNewPackageInfo({ name, value }));

    }

    const addHighLight = () => {

        const foundHighlight = highlights.find(item => item === hightlightItem);
        if(foundHighlight) return alert("Already added");

        dispatch(addNewPackageInfo({
            name: "highlights",
            value: [...highlights, hightlightItem]
        }));

    }

    const removeHighlight = (highlight: string) => {

        const newHighlights = highlights.filter(item => item !== highlight);

        dispatch(addNewPackageInfo({
            name: "highlights",
            value: newHighlights
        }));

    }

    const savePackage = async() => {

        // Clear previous validationErrorMsg
        setValidationErrorMsg(undefined);

        if(!title || !description || !price) {
            dispatch(updateState({
                name: "validationError",
                value: true
            }));
            return;
        }

        if(isNaN(Number(price))) {
            setValidationErrorMsg("Price must be a number");
            return;
        }

        if(!areAllTreatmentsSelected) {

            // If user forgot to select at least one treatment
            if(!selectedTreatmentList.length) {
                setValidationErrorMsg("Please select at least one treatment with sessions");
                return;
            }

            // If user selected individual treatments but forgot to put the sessions
            const isSessionCountMissing = selectedTreatmentList.some(item => {
                return !item.sessions || isNaN(Number(item.sessions));
            });

            if(isSessionCountMissing) {
                setValidationErrorMsg("Session count is missing or it's not correct");
                return;
            }

        }

        let newTreatmentList = selectedTreatmentList.map(item => {
            return {
                treatment: item.treatment._id,
                sessions: Number(item.sessions)
            }
        });

        if(areAllTreatmentsSelected) {

            if(!allTreatmentsSessionCount || isNaN(Number(allTreatmentsSessionCount))) {
                setValidationErrorMsg("Session count is missing or it's not correct");
                return;
            }

            newTreatmentList = treatmentList.map(item => {
                return {
                    treatment: item._id,
                    sessions: Number(allTreatmentsSessionCount)
                }
            });

        }

        dispatch(closeAddEditPackageModal());

        let endpoint = config.url.BACKEND_API_URL + "/packages/create";
        let actionMessage = "Creating...";

        if(isEditing) {
            endpoint = config.url.BACKEND_API_URL + "/packages/edit";
            actionMessage = "Updating...";
        } 

        dispatch(updateState({
            name: "actionMessage",
            value: actionMessage
        }));

        const authConfig = getAuthTokenConfig();
        const requestBody = {
            packageId: packageItemInAction?._id,
            title,
            description,
            price: Number(price),
            highlights,
            treatmentList: newTreatmentList
        };

        try {

            await axios.post(endpoint, requestBody, authConfig);
            dispatch(updateState({
                name: "actionMessage",
                value: "Successfully Saved"
            }));
            
            dispatch({ 
                type: FETCH_PACKAGE_DATA, 
                payload: { 
                    searchText,
                    skip,
                    limit,
                    fetchTreatmentData: false
                } 
            })

        } catch(error) {
            const { message } = handleApiError(error);
            dispatch(updateState({
                name: "actionMessage",
                value: message
            }));
        }
    
    }

    
    return (
        <Modal>
            <ModalBackground />
            <ModalContent 
                width={isLargeDevice ? "45%" : "95%"} 
                height="90%"
                backgroundColor="#F9F9FB"
            >
                <Button 
                    customClassName="modal_cls_btn"
                    buttonText="X"
                    handleClick={closeModal}
                />
                <div className={styles.add_edit_package}>
                    <h1>{isAdding ? "Create package" : "Edit pacakge"}</h1>
                    <div className={styles.package_basic_info}>
                        <InputField 
                            labelText="Title"
                            type="text"
                            name="title"
                            value={title}
                            handleChange={handleChange}
                            validationError={validationError}
                            validationErrorMessage="title can't be blank"
                        />
                        <TextAreaInputField 
                            labelText="Description"
                            rows={4}
                            columns={5}
                            name="description"
                            value={description}
                            handleChange={handleChange}
                            validationError={validationError}
                            validationErrorMessage="description can't be blank"
                        />
                        <InputField 
                            labelText="Price"
                            type="text"
                            name="price"
                            value={price}
                            handleChange={handleChange}
                            validationError={validationError}
                            validationErrorMessage="price can't be blank"
                        />
                    </div>
                    <PackageHighlights 
                        highlightItem={hightlightItem}
                        highlights={highlights}
                        handleChange={handleChange}
                        addHighLight={addHighLight}
                        removeHighLight={removeHighlight}
                    />
                    <SelectTreatment />
                    {
                        validationErrorMsg
                        ?
                        <p className={styles.validation_error_msg}>
                            {validationErrorMsg}
                        </p>
                        :
                        null
                    }
                    <div className={styles.save_btn_wrapper}>
                        <Button 
                            customClassName="save_btn"
                            buttonText="Save"
                            handleClick={savePackage}
                        />
                    </div>
                </div>
            </ModalContent>
        </Modal>
    );

}


export default AddEditPackage;