import styles from "./Button.module.css";

interface ButtonType {
    customClassName?: string,
    buttonText: string,
    disabled?: boolean,
    handleClick: (event: React.MouseEvent<HTMLButtonElement>, args?: any) => void
    handleClickArgs?: any
}


const Button: React.FC<ButtonType> = (props) => {

    const { 
        customClassName = "", 
        buttonText, 
        disabled = false, 
        handleClick,
        handleClickArgs 
    } = props;

    return (
        <button 
            className = {`${styles[customClassName]}`}
            disabled = {disabled}
            onClick = {(event) => handleClick(event, handleClickArgs)}
        >
            {buttonText}
        </button>
    );

}


export default Button;