import useWindowWidth from "../../hooks/useWindowWidth";
import styles from "./index.module.css";

interface OverviewCardProps {
    icon: React.ReactNode
    iconBgColor?: string
    heading: string
    content: string
}


const OverviewCard: React.FC<OverviewCardProps> = ({ icon, iconBgColor, heading, content }) => {

    const { windowWidth } = useWindowWidth();
  
    return (
        <div className={styles.overview_card}>
            {
                windowWidth > 1000
                ?
                <div 
                    className={styles.overview_card_icon}
                    style = {{
                        backgroundColor: iconBgColor ?? "#A8DDE6"
                    }}
                >
                    {icon}
                </div>
                :
                null
            }
            <div className={styles.overview_card_info}>
                <h3>{heading}</h3>
                <p>{content}</p>
            </div>
        </div>
    );

}


export default OverviewCard;