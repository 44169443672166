import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { 
    AppointmentClientState,
    FetchedAppointmentClientData 
} from "../../types/pages/AppointmentClient";
import { IAppointmentClient } from "../../types/models/AppointmentClient";

const initialState: AppointmentClientState = {
    loading: true,
    apiErrorMsg: null,
    validationError: false,
    appointmentClientList: [],
    appointmentClientInAction: null,
    newAppointmentClientInfo: {
        fullName: "",
        email: "",
        phone: "",
        note: "",
    },
    searchText: "",
    skip: 0, 
    limit: 10,
    totalAppointmentClientCount: 0,
    isAdding: false,
    isEditing: false,
    isDeleting: false,
    actionMessage: null
}


const appointmentClientSlice = createSlice({
    name: "appointment_clients",
    initialState,
    reducers: {

        fetchDataLoading: (state) => {
            return { ...state, loading: true }
        },

        fetchDataSuccess: (state, action: PayloadAction<FetchedAppointmentClientData>) => {
            const { appointmentClientList, totalAppointmentClientCount } = action.payload;
            return {
                ...state,
                loading: false,
                appointmentClientList,
                totalAppointmentClientCount
            }
        },

        fetchDataFail: (state, action:PayloadAction<string | null>) => {
            return {
                ...state,
                loading: false,
                apiErrorMsg: action.payload
            }
        },

        updateState: (state, action: PayloadAction<{ name: string, value: any }>) => {
            const { name, value } = action.payload;
            return {
                ...state,
                [name]: value
            }
        },

        addNewAppointmentClientInfo: (state, action: PayloadAction<{ name: string, value: string }>) => {
            const { name, value } = action.payload;
            return {
                ...state,
                newAppointmentClientInfo: {
                    ...state.newAppointmentClientInfo,
                    [name]: value
                }
            }
        },

        closeAddEditAppointmentClientModal: (state) => {
            return { 
                ...state, 
                validationError: false,
                isAdding: false,
                isEditing: false,
                appointmentClientInAction: null,
                newAppointmentClientInfo: {
                    fullName: "",
                    email: "",
                    phone: "",
                    note: "",
                }
            }
        },

        openEditAppointmentClientModal: (state, action: PayloadAction<IAppointmentClient>) => {
            const appointmentClient = action.payload;
            return { 
                ...state, 
                isEditing: true,
                appointmentClientInAction: appointmentClient,
                newAppointmentClientInfo: { 
                    fullName: appointmentClient.fullName,
                    email: appointmentClient.email,
                    phone: appointmentClient.phone,
                    note: appointmentClient.note ?? ""
                } 
            }
        },

        toggleAppointmentClientDeletePrompt: (state, action: PayloadAction<null | IAppointmentClient>) => {
            return {
                ...state,
                appointmentClientInAction: action.payload,
                isDeleting: !state.isDeleting
            }
        }

    }
})

export const {
    fetchDataLoading,
    fetchDataSuccess,
    fetchDataFail,
    updateState,
    addNewAppointmentClientInfo,
    closeAddEditAppointmentClientModal,
    openEditAppointmentClientModal,
    toggleAppointmentClientDeletePrompt,
} = appointmentClientSlice.actions;

export default appointmentClientSlice.reducer;