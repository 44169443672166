import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { IAppointment } from "../../../types/models/Appointment";
import { updateState } from "../../../store/calendar/calendarReducer";
import styles from "./ClientSearch.module.css";

const AppointmentTable: React.FC = () => {

    const { windowWidth } = useWindowWidth();

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { clientAppointments } = calendarState;

    const openAppointmentDetails = (appointment: IAppointment) => {
        dispatch(updateState({
            name: "appointmentInAction",
            value: appointment
        }));
    } 
  
    return (
        <div className = {styles.appointment_table}>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Treatment</th>
                        { windowWidth > 1000 && <th>Stylist</th> }
                        { windowWidth > 1000 && <th>Payment Status</th> }
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {clientAppointments.map(appointment => {

                        const { startTime, stylist, treatmentTitle, paymentStatus, status } = appointment;

                        const indexOfT = startTime.indexOf("T");
                        const appointmentDate = startTime.substring(0, indexOfT);
                        const appointmentTime = startTime.substring(indexOfT + 1, startTime.length);

                        return (
                            <tr 
                                className = {styles.td_table_row}
                                key = {appointment._id} 
                                onClick = {() => openAppointmentDetails(appointment)}
                            >
                                <td>
                                    <p>{appointmentDate}</p>
                                    <p>{appointmentTime}</p>
                                </td>
                                <td>{treatmentTitle}</td>
                                { windowWidth > 1000 && <td>{stylist}</td> }
                                { windowWidth > 1000 && <td>{paymentStatus}</td> }
                                <td>{status}</td>
                            </tr>
                        );

                    })}
                </tbody>
            </table>
        </div>
    );

}


export default AppointmentTable;