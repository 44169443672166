import { IUser } from "../../types/models/User";
import styles from "./UserTable.module.css";
import EditIcon from "../../components/icons/EditIcon";
import DeleteIcon from "../../components/icons/DeleteIcon";
import IconButton from "./IconButton";

interface ActionButtonProps {
    user: IUser
    showButtonText: boolean
    canDelete?: boolean
    handleClick: (args: { actionType: string, user: IUser}) => void
}


const ActionButtons: React.FC<ActionButtonProps> = (props) => {

    const { user, showButtonText, canDelete = true, handleClick } = props;

    return (
        <div className={styles.action_buttons}> 
            <IconButton 
                customClassName="edit_icon_button"
                buttonText={showButtonText ? "Edit" : ""}
                icon={<EditIcon />}
                handleClick={handleClick}
                args={{ actionType: "Edit", user }}
            />
            <IconButton 
                customClassName="delete_icon_button"
                buttonText={showButtonText ? "Delete" : ""}
                disabled={!canDelete}
                icon={<DeleteIcon />}
                handleClick={handleClick}
                args={{ actionType: "Delete", user }}
            />
        </div>            
    );

}

export default ActionButtons;