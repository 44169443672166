import { CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";


export const validateCreditCard = (elements: any) => {

    let message: string | null = null;

    const creditCardNumberElement: any = elements.getElement(CardNumberElement);
    const creditCardExpiryElement: any = elements.getElement(CardExpiryElement);
    const creditCardCVCElement: any = elements.getElement(CardCvcElement);

    // if any of the three card element's input field hasn't been filled it's _parent property
    // will contain a class "StripeElement--empty". If this happens user must be alerted to fill it. 
    const isCardNumberEmpty = creditCardNumberElement._parent.classList.contains("StripeElement--empty");
    const isCardExpiryEmpty = creditCardExpiryElement._parent.classList.contains("StripeElement--empty");
    const isCardCVCEmpty = creditCardCVCElement._parent.classList.contains("StripeElement--empty");

    if(isCardNumberEmpty || isCardExpiryEmpty || isCardCVCEmpty) {
        message = "Please fill all the required card details";
    } 

    return message;

}