import axios from "axios";
import Cookies from "universal-cookie";
import { config } from "../../../Constants";


export const fetchData = async() => {

    const cookies = new Cookies();

    const headers = { "auth-token": cookies.get("auth_token") };

    const endpoint = config.url.BACKEND_API_URL + "/users/subinv";
  
    const response = await axios.get(endpoint, { headers });
    return response.data;

}