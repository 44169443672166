import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { RootState } from "../../store";
import { config } from "../../Constants";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import styles from "./Login.module.css";
import LoginForm from "./LoginForm";
import LogoImage from "../../components/login-signup/LogoImage";
import DesignImage from "../../components/login-signup/DesignImage";
import ErrorMessage from "../../components/login-signup/ErrorMessage";
import ForgotPassword from "./ForgotPassword";


const Login = () => {

    const cookies = new Cookies();
    const navigate = useNavigate();

    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;

    const [userInfo, setUserInfo] = useState<{ [key: string]: string }>({
        email: "",
        password: ""
    });
    const [validationError, setValidationError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loginErrorMsg, setLoginErrorMsg] = useState<string>();
    const [showForgotPasswordSection, setShowForgotPasswordSection] = useState<boolean>(false);

    // We need to fetch subscription and invoice data of the user
    useEffect(() => {

        if(!user) return;

        // If logged in user is Tenant then he/she must be redirected to tenant profile
        if(user.userType === "Tenant") {
            navigate(`/user/${user.tenantId}/profile-tenant`);
            return;
        }

        // Other user types should be redirected to user profile
        navigate(`/user/${user.tenantId}/profile-user`);

    }, [user, navigate])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUserInfo(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        });
    }

    const handleLogin = async(event: SubmitEvent) => {

        event.preventDefault();

        const { email, password } = userInfo;

        if(!email || !password) {
            setValidationError(true);
            return;
        }

        setLoading(true);

        const endpoint = `${config.url.BACKEND_API_URL}/users/login`;

        try {

            const response = await axios.post(endpoint, { email: email.toLowerCase(), password });
            const { token, tenantId, userType } = response.data;
    
            const cookieOptions = {
                path: "/", 
                secure: true, 
                maxAge: 86400
            };
            cookies.set("auth_token", token, cookieOptions);

            setLoading(false);

            if(userType !== "Tenant") {
                navigate(`/user/${tenantId}/profile-user`);
                return;
            }

            navigate(`/user/${tenantId}/profile-tenant`);

        } catch(error: any) {
            const { message } = handleApiError(error);
            setLoginErrorMsg(message);
            setLoading(false);
        }

    }


    return (
        <div className={styles.login}>
            <div className={styles.user_credentials}>
                <LogoImage />
                {
                    showForgotPasswordSection
                    ?
                    <ForgotPassword />
                    :
                    <>
                        <h2>Welcome back!</h2>
                        <p>Please enter your credentials</p>
                        {loginErrorMsg ? <ErrorMessage message={loginErrorMsg ?? ""} /> : null}
                        <LoginForm 
                            loading={loading}
                            email={userInfo.email}
                            password={userInfo.password}
                            validationError={validationError}
                            handleChange={handleChange}
                            handleLogin={handleLogin}
                            setShowForgotPasswordSection={setShowForgotPasswordSection}
                        />
                        <div className={styles.no_account}>
                            <p>Don't have an account?</p>
                            <Link to="/signup">
                                Sign up
                            </Link>
                        </div>
                    </>
                }
            </div>
            <DesignImage />
        </div>
    );

}


export default Login;