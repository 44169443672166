import { useSelector } from "react-redux";
import { ILocation } from "../../types/models/Location";
import { RootState } from "../../store";
import styles from "./LocationTable.module.css";
import ActionButtons from "./ActionButtons";

interface IProps {
    handleClick: (args: { actionType: string, location: ILocation}) => void
}


const ResponsiveLocationTable: React.FC<IProps> = ({ handleClick }) => {

    const locationState = useSelector((state: RootState) => state.locationState);
    const { locationList } = locationState;
   
  
    return (
        <div className = {styles.responsive_location_table}>
            {locationList.map(location => {
                const { _id, title, city, country } = location;
                
                return (
                    <div 
                        key = {_id}
                        className = {styles.responsive_location_item}
                    >
                        <div className={styles.responsive_title_actions}>
                            <p className = {styles.responsive_location_title}>
                                {title}
                            </p>
                            <ActionButtons
                                showButtonText = {false} 
                                location={location}
                                handleClick={handleClick}
                            />
                        </div>
                        <p className = {styles.responsive_location_country}>
                            {country}
                        </p>
                        <p>{city}</p>
                    </div>
                );
            })}
        </div>
    );

}

export default ResponsiveLocationTable;