import { IoMdClose } from "react-icons/io";
import styles from "./PackageHighlights.module.css";
import TextAreaInputField from "./TextAreaInputField";

interface PackageHighlightsProps {
    highlightItem: string
    highlights: string[]
    handleChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
    addHighLight: () => void
    removeHighLight: (highlight: string) => void
}


const PackageHighlights: React.FC<PackageHighlightsProps> = ({ 
    highlightItem, 
    highlights, 
    handleChange, 
    addHighLight, 
    removeHighLight 
}) => {

    const isButtonDisabled = highlightItem === "" ? true : false;

    return (
        <div className={styles.package_highlights}>
            <div className={styles.highlight_input}>
                <TextAreaInputField 
                    customClassName="highlight_input"
                    labelText="Package Highlights"
                    rows={3}
                    columns={5}
                    name="highlightItem"
                    value={highlightItem}
                    handleChange={handleChange}
                />
                <div className={styles.highlight_input_button}>
                    <button 
                        disabled={isButtonDisabled}
                        onClick={addHighLight}
                        style={{
                            backgroundColor: isButtonDisabled ? "lightgray" : "#FFAAA5"
                        }}
                    >
                        Add Highlight
                    </button>
                </div>
            </div>
            {highlights.map(item => {
                return (
                    <div key={item} className={styles.highlight}>
                        <p>{item}</p>
                        <button onClick={() => removeHighLight(item)}>
                            <IoMdClose color={"rgba(0, 0, 0, 0.53)"}  />
                        </button>
                    </div>
                );
            })}
        </div>
    );

}


export default PackageHighlights;