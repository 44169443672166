import styles from "./index.module.css";

interface GiftCardItemInfoProps {
    title: string
    content: string
}

const GiftCardItemInfo: React.FC<GiftCardItemInfoProps> = ({ title, content }) => {

    return (
        <div className={styles.gift_card_item_info}>
            <p>{title}</p>
            <p>{content}</p>
        </div>
    );

}

export default GiftCardItemInfo;