import { useSelector, useDispatch } from "react-redux";
import { TfiCreditCard } from "react-icons/tfi";
import { SiSepa } from "react-icons/si";
import { RootState } from "../../../store";
import { IUser } from "../../../types/models/User";
import { openUpdatePaymentMethodModal } from "../../../store/profile-tenant/tenantProfileReducer";
import { getFormattedDateTime } from "../../../helpers/date-time/getFormattedDateTime";
import styles from "./UpdatePaymentMethod.module.css";

const NextPayment: React.FC = () => {

    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.userState);
    const user = userState.user as IUser;

    const handleClick = () => dispatch(openUpdatePaymentMethodModal());

    const foundSubPrice = user.stripeSubscription?.subscriptionPlan.prices.find((item) => {
        return item.stripePriceId === user.stripeSubscription?.stripePriceId;
    });
    
    
    let nextSubPaymentDate = null;
    if(user.stripeSubscription) {
        const newDate = new Date(user.stripeSubscription.currentPaymentPeriodEndDate * 1000);
        nextSubPaymentDate = getFormattedDateTime(newDate);
    }

    const price = foundSubPrice?.amount ?? 0;

    const isSepa = user?.stripeSubscription?.paymentMethod === "Sepa Debit" 
  
    return (
        <div className = {styles.next_payment}>
            <div className = {styles.next_payment_content}>
                <div className = {styles.payment_info}>
                    <h4>Next Payment</h4>
                    {
                        foundSubPrice 
                        &&
                        <p className={styles.pay_amount}>€{price.toFixed(2)}</p>
                    }
                    {
                        nextSubPaymentDate
                        &&
                        <p className = {styles.pay_date}>
                            {`On ${nextSubPaymentDate.month} ${nextSubPaymentDate.day}th, ${nextSubPaymentDate.year}`}
                        </p>
                    }
                </div>
                <div className = {styles.card_icon}>
                    {!isSepa ? <TfiCreditCard size={"2.5rem"} /> : null}
                    {isSepa ? <SiSepa size={"2.5rem"} /> : null}
                </div>
            </div>
            <button 
                className = {styles.payment_method_btn}
                onClick={handleClick}
            >
                Update Payment Method
            </button>
        </div>
    );

}

export default NextPayment;
