import { useState } from "react";
import axios from "axios";
import { config } from "../../../Constants";
import { getAuthTokenConfig } from "../../../helpers/others/getAuthTokenConfig";
import { handleApiError } from "../../../helpers/error-handlers/handleApiError";

type Return = {
    accountId?: string
    errorMsg?: string
}

const useCreateStripeAccount = () => {
    
    const [loading, setLoading] = useState<boolean>(false);

    const createStripeAccount = async(): Promise<Return> => {

        setLoading(true);

        const endpoint = `${config.url.BACKEND_API_URL}/users/create-stripe-account`;
        const authConfig = getAuthTokenConfig();
   
        try {

            const response = await axios.post(endpoint, {}, authConfig);
            setLoading(false);
            return {
                accountId: response.data.accountId,
            }

        } catch (error) {
            setLoading(false);
            let { message } = handleApiError(error);
            return {
                errorMsg: message
            };
        } 

    }

    return { 
        loading, 
        createStripeAccount
    };

}

export default useCreateStripeAccount;