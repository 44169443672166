import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { RootState } from "../../store";
import { ITenantInvoice } from "../../types/models/TenantInvoice";
import { convertHexToRgb } from "../../helpers/others/convertHexToRgb";
import { createPDFTable } from "../../helpers/others/createPdfTable";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import DownloadIconButton from "../../components/common/buttons/DownloadIconButton";

interface TenantInvoicePDFButtonProps {
    tenantInvoice: ITenantInvoice
}

const TenantInvoicePDFButton: React.FC<TenantInvoicePDFButtonProps> = ({ tenantInvoice }) => {

    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;

    const downloadTenantInvoicePDF = (invoice: ITenantInvoice) => {

        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const doc = new jsPDF(orientation, unit, size);

        // Define header dimensions and padding
        const headerHeight = 180;
        const margin = 40;
        const padding = 20;

        // Define header colors
        const headerBackgroundColor = convertHexToRgb('#475467'); // Light grey background
        const headerTextColor = convertHexToRgb('#FFFFFF'); // Black text

        // Draw header background
        doc.setFillColor(headerBackgroundColor[0], headerBackgroundColor[1], headerBackgroundColor[2]);

        const pageSize = doc.internal.pageSize.getWidth() - (2 * margin);
        doc.rect(margin, margin, pageSize, headerHeight, "F"); // "F" for filled rectangle

        const invoiceCreationDate = new Date(invoice.createdAt * 1000);
        const { day, month, year } = formatDateTime(invoiceCreationDate);
    
        let textFontSize = 12;

        // Define the texts for the left and right sides of the header
        const leftTexts = [
            { text: 'Invoiced By:' },
            { text: "Tecuty" },
            { text: "The Hague" },
            { text: "Invoice Number" },
            { text: invoice.invoiceNumber },
        ];

        const rightTexts = [
            { text: 'Invoiced To:' },
            { text: user?.companyName ?? "" },
            { text: `${user?.companyAddress.city}` ?? "" },
            { text: `${user?.companyAddress.country}` ?? "" },
            { text: user?.stripeSubscription?.vatId ?? "" },
            { text: "Invoice Date" },
            { text: `${day} ${month}, ${year}` },
        ];

        // Set the initial positions
        let leftYPosition = margin + padding + 40;
        const leftXPosition = margin + padding;
        let rightYPosition = margin + padding + 40;
        const rightXPosition = 100 + doc.internal.pageSize.getWidth() / 2;

        // Set text color
        doc.setTextColor(headerTextColor[0], headerTextColor[1], headerTextColor[2]);
        doc.setFontSize(12)

        // Add left side texts to the header
        leftTexts.forEach((item, index) => {
            if(index === 3) leftYPosition = 190;
            doc.text(item.text, leftXPosition, leftYPosition);
            leftYPosition += textFontSize + 4; // Adjust line height based on font size
        });
    
        // Add right side texts to the header
        rightTexts.forEach((item, index) => {
            if(index === 5) rightYPosition = 190;
            doc.text(item.text, rightXPosition, rightYPosition);
            rightYPosition += textFontSize + 4; // Adjust line height based on font size
        });

        const bookituTextColor = convertHexToRgb("#FFAAA5")

        doc.setTextColor(bookituTextColor[0], bookituTextColor[1], bookituTextColor[2]);
        doc.setFontSize(28);
       
        doc.text("Bookitu", 60, 75);

        const { invoiceMonthName } = invoice;

        const invoiceMonth = invoiceMonthName.substring(0, 1).toUpperCase() + invoiceMonthName.substring(1, invoiceMonthName.length);

        doc.setFontSize(textFontSize + 2)
        doc.setTextColor(0, 0, 0);
        doc.text("Invoice Period", leftXPosition, 250);
        doc.text(`${invoiceMonth}, ${invoice.invoiceYear}`, rightXPosition, 250);

        const headers = [["Description", "Amount"]];

        const vatOnFee = (invoice.invoiceFeeAmount * 21) / 100;
        const total = invoice.invoiceAmount - (invoice.invoiceFeeAmount + vatOnFee);

        const data = [
            ["Invoice Amount", `€${invoice.invoiceAmount.toFixed(2)}`],
            ["Total", `€${invoice.invoiceAmount.toFixed(2)}`],
            ["Fee (1.88%)", `€${invoice.invoiceFeeAmount.toFixed(2)}`],
            ["21% VAT (on fee)", `€${vatOnFee.toFixed(2)}`],
            ["Total (to receive)", `€${total.toFixed(2)}`]
        ]

        const content = createPDFTable({ headers, data, startY: 270, boldRowIndexes: [1] });

        //@ts-ignore
        doc.autoTable(content);
        doc.save("invoice.pdf")
       
    }

    return (
        <DownloadIconButton 
            onDownload={downloadTenantInvoicePDF}
            args={tenantInvoice}
        />                       
    );

}

export default TenantInvoicePDFButton;