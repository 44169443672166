import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import useWindowWidth from "../../../hooks/useWindowWidth";
import styles from "./SelectDate.module.css";
import DateCard from "./DateCard";
import NextIconButton from "../common/NextIconButton";
import PrevIconButton from "../common/PrevIconButton";

interface SlideType {
    move: number, 
    moveToLeftBy: number | string
}


function SelectDate() {

    const { devicesWidth } = useWindowWidth();
    const { isSmartphone, isMiniTab, isAverageTab, isLargeTab } = devicesWidth;

    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { availableDateTimes } = calendarState;

    const [slide, setSlide] = useState<SlideType>({ move: 0, moveToLeftBy: 0 });

    // When value of availableDateTime changes we need to set the slide state to it's initial state. Because
    // it could happen that user changes stylist of selected treatments to find a time that is perfect for her
    useEffect(() => {
        setSlide({ move: 0, moveToLeftBy: 0 });
    }, [availableDateTimes])

    const slideToNextDate = () => {

        const slideMovePlusOne = slide.move + 1;

        setSlide(currentValue => { return { ...currentValue, move: slide.move + 1 }; });

        let position = 20 * (slideMovePlusOne);
        if(isSmartphone) position = 33.33 * (slideMovePlusOne);
        if(isMiniTab || isAverageTab || isLargeTab) position = 25 * (slideMovePlusOne);

        setSlide(currentValue => { return { ...currentValue, moveToLeftBy: "-" + position + "%" } })
        
    }

    const slideToPreviousDate = () => {

        const slideMoveMinusOne = slide.move - 1;

        let position = 20 * (slideMoveMinusOne);
        if(isSmartphone) position = 33.33 * (slideMoveMinusOne);
        if(isMiniTab || isAverageTab || isLargeTab) position = 25 * (slideMoveMinusOne);

        setSlide(currentValue => { return { ...currentValue, moveToLeftBy: "-" + position + "%" }; });

        // As long as move is more than 1 we can subtract 1 from it
        if(slide.move) setSlide(currentValue => { return { ...currentValue, move: slideMoveMinusOne }; });

    }


    return (
        <div className = {styles.select_date}>
            <PrevIconButton 
                handleClick={slideToPreviousDate}
            />
            <NextIconButton 
                handleClick={slideToNextDate}
            />
            <div className={styles.available_dates} >
                <div className= {styles.available_date_list}>
                    {availableDateTimes.map(dateTime => {
                        return (
                            <DateCard 
                                key = {dateTime.dateString}
                                dateTime = {dateTime}
                                dateSlideLeftPosition = {slide.moveToLeftBy}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
        
    );

}


export default SelectDate;