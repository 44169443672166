import styles from "./Signup.module.css";
import InputField from "./InputField";
import SubmitButton from "../../components/login-signup/SubmitButton";

interface SignupFormProps {
    loading: boolean
    userInfo: { [key: string]: string }
    validationError: boolean
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleUserSignup: (event: SubmitEvent) => void
}


const SignupForm: React.FC<SignupFormProps> = ({ loading, userInfo, validationError, handleChange, handleUserSignup }) => {

    const { 
        firstName, 
        lastName, 
        companyName, 
        email, phone, 
        password, 
        password2 
    } = userInfo;

    return (
        <div className={styles.signup_form}>
            <form>
                <InputField 
                    labelText="First Name"
                    type="text"
                    name="firstName"
                    value={firstName}
                    placeholder="Enter your first name"
                    handleChange={handleChange}
                    validationError={validationError}
                    validationErrorMessage="first name is required"
                />
                <InputField 
                    labelText="Last Name"
                    type="text"
                    name="lastName"
                    value={lastName}
                    placeholder="Enter your last name"
                    handleChange={handleChange}
                    validationError={validationError}
                    validationErrorMessage="last name is required"
                />
                <InputField 
                    labelText="Salon Name"
                    type="text"
                    name="companyName"
                    value={companyName}
                    placeholder="Enter your salon name"
                    handleChange={handleChange}
                    validationError={validationError}
                    validationErrorMessage="salon name is required"
                />
                <InputField 
                    labelText="Email"
                    type="email"
                    name="email"
                    value={email}
                    placeholder="Enter your email"
                    handleChange={handleChange}
                    validationError={validationError}
                    validationErrorMessage="email is required"
                />
                <InputField 
                    labelText="Phone"
                    type="text"
                    name="phone"
                    value={phone}
                    placeholder="Enter your phone"
                    handleChange={handleChange}
                    validationError={validationError}
                    validationErrorMessage="phone is required"
                />
                <InputField 
                    labelText="Password"
                    type="password"
                    name="password"
                    value={password}
                    placeholder="Enter your password"
                    handleChange={handleChange}
                    validationError={validationError}
                    validationErrorMessage="password is required"
                />
                <InputField 
                    labelText="Confirm Password"
                    type="password"
                    name="password2"
                    value={password2}
                    handleChange={handleChange}
                    placeholder="Re-enter your password"
                    validationError={validationError}
                    validationErrorMessage="confirmation password is required"
                />
                <SubmitButton
                    disabled={loading} 
                    buttonText={loading ? "Getting Started..." : "Get Started"}
                    handleSubmit={handleUserSignup}
                />
            </form>
        </div>
    );
}

export default SignupForm