import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { 
    updateState,
    addNewUserInfo, 
    setUserType, 
    uploadUserPhoto 
} from "../../../store/user-list/userListReducer";
import styles from "./AddEditUser.module.css";
import InputField from "../InputField";
import FileInputField from "../../../components/common/input-fields/FileInputField";
import CheckboxInputField from "../CheckboxInputField";
import WorkSchedule from "./WorkSchedule";


const AddUserInfo: React.FC = () => {

    const dispatch = useDispatch();
    const userListState = useSelector((state: RootState) => state.userListState);

    const { newUserInfo, userType, isFreelanceStylist, validationError, userPhoto, userItemInAction } = userListState;
    const { fullName, email, phone, password, password2 } = newUserInfo;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const { name, value, checked } = event.target;

        if(name === "isFreelanceStylist") {
            dispatch(updateState({
                name: "isFreelanceStylist",
                value: checked
            }));
            return;
        }

        if(name === "userType") {

            if(checked) {
                dispatch(setUserType("Stylist"));
                return;
            } 

            dispatch(setUserType("Admin"));
            return;

        }

        dispatch(addNewUserInfo({ name, value }));

    }

    const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.files) {
            dispatch(uploadUserPhoto(event.target.files[0]));
        }
    }

    const isUserAStylist = userType === "Stylist";

    return (
        <div className = {styles.user_info}>
            <div className = {styles.file_input_container}>
                <FileInputField 
                    file={userPhoto}
                    imageUrl={userItemInAction?.imageUrl ?? undefined}
                    handleFile={handleFile}
                />
            </div>
            <InputField 
                labelText="Full Name"
                type = "text"
                name = "fullName"
                value = {fullName}
                handleChange = {handleChange}
                validationError = {validationError}
                validationErrorMessage="name can't be blank"
            />
            <InputField 
                labelText="Email"
                type = "email"
                name = "email"
                value = {email}
                handleChange = {handleChange}
                validationError = {validationError}
                validationErrorMessage="email can't be blank"
            />
            <InputField 
                labelText="Phone"
                type = "text"
                name = "phone"
                value = {phone}
                handleChange = {handleChange}
                validationError = {validationError}
                validationErrorMessage="phone can't be blank"
            />
            <InputField 
                labelText="Password (optional if you are editing the user info)"
                type = "password"
                name = "password"
                value = {password}
                handleChange = {handleChange}
            />
            <InputField 
                labelText="Retype Password (optional if you are editing the user info)"
                type = "password"
                name = "password2"
                value = {password2}
                handleChange = {handleChange}
            />
            <CheckboxInputField 
                name="userType"
                value="Performs treatments and services"
                handleChange={handleChange}
                checked={isUserAStylist}
            />
            {
            
                isUserAStylist 
                ? 
                <div className={styles.stylist_section}>
                    <CheckboxInputField 
                        customClassName="freelance_stylist_checkbox"
                        name="isFreelanceStylist"
                        value="Freelance Stylist"
                        handleChange={handleChange}
                        checked={isFreelanceStylist}
                    />
                    <WorkSchedule /> 
                </div>
                : 
                null
            }
        </div>
    );

}


export default AddUserInfo;