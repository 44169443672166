import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { updateState } from "../../../store/calendar/calendarReducer";
import styles from "./AddAppointmentModal.module.css";


const NewAppointmentType: React.FC = () => {

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { newAppointmentType, newAppointmentInfo, isBookingAppointmentForSearchedClient } = calendarState;

    const updateAppointmentType = (appointmentType: string) => {
        dispatch(updateState({
            name: "newAppointmentType",
            value: appointmentType
        }));
        dispatch(updateState({
            name: "newAppointmentInfo",
            value: {
                ...newAppointmentInfo,
                newAppointmentTitle: "",
                selectedLocation: null, 
                selectedCategory: null, 
                selectedTreatment: null,
                clientName: "",
                clientEmail: "",
                clientPhone: "",
                couponCode: "",
                isCouponRedeemed: false,
                couponDiscountAmount: 0
            }
        }));
    };

    const timeBlockButtonStyle = {
        backgroundColor: newAppointmentType === "time_block" ? "#002B5B" : "white",
        color: newAppointmentType === "time_block" ? "white" : "black"
    };
    const regularAppointmentStyle = {
        backgroundColor: newAppointmentType === "regular" ? "#002B5B" : "white", 
        color: newAppointmentType === "regular" ? "white" : "black",
        marginRight: !isBookingAppointmentForSearchedClient ? "20px" : "0px"
    };


    return (
        <div className = {styles.appointment_type}>
            <button 
                onClick = {() => updateAppointmentType("regular")}
                style = {regularAppointmentStyle}
            >
                Regular
            </button>
            {
                !isBookingAppointmentForSearchedClient
                &&
                <button 
                    onClick = {() => updateAppointmentType("time_block")}
                    style = {timeBlockButtonStyle}
                >
                    Time Block
                </button>
            }
        </div>
    );

}

export default NewAppointmentType;