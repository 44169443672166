import Cookies from "universal-cookie";

export const getAuthTokenConfig = () => {

    const cookies = new Cookies();

    const token = cookies.get("auth_token"); 

    const authConfig = {
        headers: {
            "Authorization": `Bearer ${token}`, 
            "auth-token": token 
        }
    }

    return authConfig;
    
}
