import DebitCardImage from "../../../assets/images/debit_card.png";
import styles from "./StripeElements.module.css";


const CardImage: React.FC = () => {

    return (
        <div className = {styles.card_image}>
            <img 
                src = {DebitCardImage}
                alt = "design"
            />
        </div>
    );

}


export default CardImage;