import { IClientPackage } from "../../types/models/ClientPackage";
import { formatDateTime } from "../date-time/dateTimeHelpers";

export const getPackageCSVContent = (clientPackageList: IClientPackage[]) => {

    const headers = "Buy Date, Title, Price, Client Email, Status";

    const data = clientPackageList.map(item => {

        const { boughtAt, title, price, clientEmail, status } = item;

        const { day, month, year, hours, minutes } = formatDateTime(new Date(boughtAt * 1000));
        const buyDate = `${day} ${month} ${year} @ ${hours}:${minutes}`

        return `${buyDate}, ${title}, ${price.toFixed(2)}, ${clientEmail}, ${status}`;

    });

    return `${headers}\n${data.join("\n")}`;

}