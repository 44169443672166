import { useState } from "react";
import axios from "axios";
import { config } from "../../Constants";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import styles from "./Login.module.css";
import InputField from "./InputField";
import SubmitButton from "../../components/login-signup/SubmitButton";
import ErrorMessage from "../../components/login-signup/ErrorMessage";


const ForgotPassword: React.FC = () => {

    const [email, setEmail] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [apiErrorMsg, setApiErrorMsg] = useState<string>("");
    const [success, setSuccess] = useState<boolean>(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }

    const sendResetPasswordLink = async(event: SubmitEvent) => {

        event.preventDefault();

        setLoading(true);
        
        const endpoint = config.url.BACKEND_API_URL + "/users/send-reset-password-link";

        try {

            await axios.post(endpoint, { email: email.toLowerCase() });
            setLoading(false);
            setSuccess(true);

        } catch(error: any) {
            const { message } = handleApiError(error);
            setLoading(false);
            setApiErrorMsg(message);
        }
        
    }


    return (
        <div className = {styles.forgot_password_section}>
            {
                success
                ?
                <p>Please follow the link that has been sent to your email address to reset the password.</p>
                :
                <>
                    <h2>Forgot Password?</h2>
                    <form>
                        <InputField 
                            labelText="Email"
                            type="email"
                            name="email"
                            value={email}
                            placeholder="Enter your email"
                            handleChange={handleChange}
                        />
                        {apiErrorMsg ? <ErrorMessage message={apiErrorMsg} /> : null}
                        <SubmitButton 
                            buttonText={loading ? "Sending Link..." : "Send Link"}
                            disabled={!email || loading}
                            handleSubmit={sendResetPasswordLink}
                        />
                    </form>
                </>
            }
        </div>
    
    );
}

export default ForgotPassword