const UserProfile: React.FC = () => {

    return (
        <div className="">
            <h2>User Profile</h2>
        </div>
    );

}

export default UserProfile;