const NavUsersIcon = (): JSX.Element => {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.1532 13.6365C18.453 13.6365 17.8068 13.8001 17.1607 14.0729C16.353 13.4185 15.4912 12.8729 14.5757 12.4364C16.2993 10.9091 17.2147 8.6183 16.9454 6.16383C16.5683 3.00017 13.9833 0.382065 10.8059 0.0547894C10.5365 0 10.2672 0 9.99784 0C8.11269 0 6.38951 0.763622 5.04312 2.07284C3.75048 3.4361 2.9965 5.18176 2.9965 7.09106C2.9965 9.16391 3.912 11.1276 5.41991 12.4365C3.42735 13.3093 1.75792 14.7817 0.51895 16.6366C-0.396548 18.0547 -0.0734814 20.0183 1.27291 21.0547C3.80451 22.9636 6.82043 24 9.99782 24C12.206 24 14.36 23.5092 16.3526 22.5272C17.1603 23.1272 18.1298 23.4544 19.1532 23.4544C21.846 23.4544 24 21.2728 24 18.5455C24.0004 15.8182 21.846 13.6365 19.1532 13.6365ZM19.1532 21.8184C17.376 21.8184 15.9217 20.3455 15.9217 18.5454C15.9217 16.7453 17.3759 15.2725 19.1532 15.2725C20.9306 15.2725 22.3848 16.7453 22.3848 18.5454C22.3848 20.3455 20.9306 21.8184 19.1532 21.8184ZM6.38944 3.00002C7.41279 2.07279 8.65138 1.63638 9.94401 1.63638C10.1593 1.63638 10.3749 1.63638 10.5902 1.69078C13.0136 1.96359 15.0065 3.92726 15.2755 6.38173C15.5986 8.94539 14.1443 11.3455 11.7209 12.2183L9.99775 12.8728L8.22051 12.2183C6.06648 11.4543 4.61218 9.38186 4.61218 7.09093C4.61218 5.56364 5.25836 4.0361 6.38944 3.00002ZM8.11263 13.2547L9.99777 12.8726L11.8829 13.2547C13.4987 13.5819 14.9529 14.2911 16.2451 15.3275C15.3833 16.1455 14.8447 17.2911 14.8447 18.5456C14.8447 19.4728 15.114 20.3456 15.6524 21.0549C13.8754 21.9277 11.9366 22.3638 9.99781 22.3638C7.19722 22.3638 4.50443 21.4366 2.2425 19.7457C1.59634 19.2549 1.43481 18.2729 1.86532 17.5641C3.31952 15.3276 5.58146 13.8003 8.11268 13.2548L8.11263 13.2547ZM21.415 17.782L20.2838 18.8728L20.5532 20.4544L19.153 19.6912L17.7529 20.4548L18.0222 18.8732L16.8911 17.7823L18.4527 17.5643L19.1529 16.1462L19.8532 17.5643L21.415 17.782Z" fill="#99B2C6"/>
        </svg>
    );

}

export default NavUsersIcon;


