import styles from "./Messages.module.css";

interface ActionMessageProps {
    actionMessage: string,
    hideActionMessage: () => void
}

const ActionMessage: React.FC<ActionMessageProps> = ({ actionMessage, hideActionMessage }) => {

    return (
        <div className={styles.action_message}>
           <p>{actionMessage}</p>
           <button onClick={hideActionMessage}>
                X
            </button>
        </div>
    );

}

export default ActionMessage;
