export type AddressCountry = {
    _id: number
    name: string
    isoTwoCharacterCountryCode: string
    isoThreeCharacterCountryCode: string
}

export const countryList: AddressCountry[] = [
    { _id: 1, name: "Aland Islands", isoTwoCharacterCountryCode: "AX", isoThreeCharacterCountryCode: "ALA" },
    { _id: 2, name: "Albania", isoTwoCharacterCountryCode: "AL", isoThreeCharacterCountryCode: "ALB" },
    { _id: 3, name: "Andorra", isoTwoCharacterCountryCode: "AD", isoThreeCharacterCountryCode: "AND" },
    { _id: 4, name: "Armenia", isoTwoCharacterCountryCode: "AM", isoThreeCharacterCountryCode: "ARM" },
    { _id: 5, name: "Austria", isoTwoCharacterCountryCode: "AT", isoThreeCharacterCountryCode: "AUT" },
    { _id: 6, name: "Azerbaijan", isoTwoCharacterCountryCode: "AZ", isoThreeCharacterCountryCode: "AZE" },
    { _id: 7, name: "Belarus", isoTwoCharacterCountryCode: "BY", isoThreeCharacterCountryCode: "BLR" },
    { _id: 8, name: "Belgium", isoTwoCharacterCountryCode: "BE", isoThreeCharacterCountryCode: "BEL" },
    { _id: 9, name: "Bosnia and Herzegovina", isoTwoCharacterCountryCode: "BA", isoThreeCharacterCountryCode: "BIH" },
    { _id: 10, name: "Bulgaria", isoTwoCharacterCountryCode: "BG", isoThreeCharacterCountryCode: "BGR" },
    { _id: 11, name: "Croatia", isoTwoCharacterCountryCode: "HR", isoThreeCharacterCountryCode: "HRV" },
    { _id: 12, name: "Cyprus", isoTwoCharacterCountryCode: "CY", isoThreeCharacterCountryCode: "CYP" },
    { _id: 13, name: "Czechia", isoTwoCharacterCountryCode: "CZ", isoThreeCharacterCountryCode: "CZE" },
    { _id: 14, name: "Denmark", isoTwoCharacterCountryCode: "DK", isoThreeCharacterCountryCode: "DNK" },
    { _id: 15, name: "Estonia", isoTwoCharacterCountryCode: "EE", isoThreeCharacterCountryCode: "EST" },
    { _id: 16, name: "Finland", isoTwoCharacterCountryCode: "FI", isoThreeCharacterCountryCode: "FIN" },
    { _id: 17, name: "France", isoTwoCharacterCountryCode: "FR", isoThreeCharacterCountryCode: "FRA" },
    { _id: 18, name: "Georgia", isoTwoCharacterCountryCode: "GE", isoThreeCharacterCountryCode: "GEO" },
    { _id: 19, name: "Germany", isoTwoCharacterCountryCode: "DE", isoThreeCharacterCountryCode: "DEU" },
    { _id: 20, name: "Greece", isoTwoCharacterCountryCode: "GR", isoThreeCharacterCountryCode: "GRC" },
    { _id: 21, name: "Hungary", isoTwoCharacterCountryCode: "HU", isoThreeCharacterCountryCode: "HUN" },
    { _id: 22, name: "Iceland", isoTwoCharacterCountryCode: "IS", isoThreeCharacterCountryCode: "ISL" },
    { _id: 23, name: "Ireland", isoTwoCharacterCountryCode: "IE", isoThreeCharacterCountryCode: "IRL" },
    { _id: 24, name: "Isle of Man", isoTwoCharacterCountryCode: "IM", isoThreeCharacterCountryCode: "IMN" },
    { _id: 25, name: "Italy", isoTwoCharacterCountryCode: "IT", isoThreeCharacterCountryCode: "ITA" },
    { _id: 26, name: "Kosovo", isoTwoCharacterCountryCode: "XK", isoThreeCharacterCountryCode: "XKX" },
    { _id: 27, name: "Latvia", isoTwoCharacterCountryCode: "LV", isoThreeCharacterCountryCode: "LVA" },
    { _id: 28, name: "Liechtenstein", isoTwoCharacterCountryCode: "LI", isoThreeCharacterCountryCode: "LIE" },
    { _id: 29, name: "Lithuania", isoTwoCharacterCountryCode: "LT", isoThreeCharacterCountryCode: "LTU" },
    { _id: 30, name: "Luxembourg", isoTwoCharacterCountryCode: "LU", isoThreeCharacterCountryCode: "LUX" },
    { _id: 31, name: "Malta", isoTwoCharacterCountryCode: "MT", isoThreeCharacterCountryCode: "MLT" },
    { _id: 32, name: "Moldova", isoTwoCharacterCountryCode: "MD", isoThreeCharacterCountryCode: "MDA" },
    { _id: 33, name: "Monaco", isoTwoCharacterCountryCode: "MC", isoThreeCharacterCountryCode: "MCO" },
    { _id: 34, name: "Montenegro", isoTwoCharacterCountryCode: "ME", isoThreeCharacterCountryCode: "MNE" },
    { _id: 35, name: "Norway", isoTwoCharacterCountryCode: "NO", isoThreeCharacterCountryCode: "NOR" },
    { _id: 36, name: "North Macedonia", isoTwoCharacterCountryCode: "MK", isoThreeCharacterCountryCode: "MKD" },
    { _id: 37, name: "Poland", isoTwoCharacterCountryCode: "PL", isoThreeCharacterCountryCode: "POL" },
    { _id: 38, name: "Portugal", isoTwoCharacterCountryCode: "PT", isoThreeCharacterCountryCode: "PRT" },
    { _id: 39, name: "Romania", isoTwoCharacterCountryCode: "RO", isoThreeCharacterCountryCode: "ROU" },
    { _id: 40, name: "Russia", isoTwoCharacterCountryCode: "RU", isoThreeCharacterCountryCode: "RUS" },
    { _id: 41, name: "San Marino", isoTwoCharacterCountryCode: "SM", isoThreeCharacterCountryCode: "SMR" },
    { _id: 42, name: "Serbia", isoTwoCharacterCountryCode: "RS", isoThreeCharacterCountryCode: "SRB" },
    { _id: 43, name: "Slovakia", isoTwoCharacterCountryCode: "SK", isoThreeCharacterCountryCode: "SVK" },
    { _id: 44, name: "Slovenia", isoTwoCharacterCountryCode: "SI", isoThreeCharacterCountryCode: "SVN" },
    { _id: 45, name: "Spain", isoTwoCharacterCountryCode: "ES", isoThreeCharacterCountryCode: "ESP" },
    { _id: 46, name: "Sweden", isoTwoCharacterCountryCode: "SE", isoThreeCharacterCountryCode: "SWE" },
    { _id: 47, name: "Switzerland", isoTwoCharacterCountryCode: "CH", isoThreeCharacterCountryCode: "CHE" },
    { _id: 48, name: "The Netherlands", isoTwoCharacterCountryCode: "NL", isoThreeCharacterCountryCode: "NLD" },
    { _id: 49, name: "Turkey", isoTwoCharacterCountryCode: "TR", isoThreeCharacterCountryCode: "TUR" },
    { _id: 50, name: "Ukraine", isoTwoCharacterCountryCode: "UA", isoThreeCharacterCountryCode: "UKR" },
    { _id: 51, name: "United Kingdom", isoTwoCharacterCountryCode: "GB", isoThreeCharacterCountryCode: "GBR" }
]