import axios from "axios";
import Cookies from "universal-cookie";
import { config } from "../../Constants";


export interface UploadFileArgsType {
    url: string,
    filePath: string,
    file: File
}

export interface UploadFileReturnType {
    imageUrl: string | null,
    imageFilename: string | null
}

const cookies = new Cookies();

export const uploadFile = async(args: UploadFileArgsType): Promise<UploadFileReturnType> => {

    const { url, filePath, file } = args;

    const endpoint = `${config.url.IMAGE_API_URL}${url}?filePath=${filePath}` ;
    const axiosConfig = {
        headers: { 
            "Content-Type": "multipart/form-data",
            "auth-token": cookies.get("auth_token")
        },
    };

    const formData = new FormData();
    formData.append("file", file);

    try {

        const response = await axios.post(endpoint, formData, axiosConfig );

        const { imageUrl, imageFilename }: UploadFileReturnType = response.data;
        return { imageUrl, imageFilename };

    } catch(error) {
        return { imageUrl: null, imageFilename: null };
    }
    
}