import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { addNewAppointmentInfo } from "../../../store/calendar/calendarReducer";
import styles from "./AddAppointmentModal.module.css";
import InputField from "../common/InputField";


const SelectTime: React.FC = () => {

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { newAppointmentType, newAppointmentInfo, validationError } = calendarState;
    const { startTime, endTime } = newAppointmentInfo;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        dispatch(addNewAppointmentInfo({ name, value }))
    }


    return (
        <div className = {styles.select_time}>
            <InputField 
                labelText = "Select Start Time"
                type = "text"
                name = "startTime"
                value = {startTime}
                validationError = {validationError}
                validationErrorMessage = "start time can't be blank"
                handleChange = {handleChange}
            />
            {
                newAppointmentType === "time_block"
                &&
                <InputField 
                    labelText = "Select End Time"
                    type = "text"
                    name = "endTime"
                    value = {endTime}
                    validationError = {validationError}
                    validationErrorMessage = "end time can't be blank"
                    handleChange = {handleChange}
                />
            }
        </div>
    );

}

export default SelectTime;