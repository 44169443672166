import { MdViewCompact } from "react-icons/md";
import { IClientPackage } from "../../types/models/ClientPackage";
import styles from "./index.module.css";

interface ViewIconButtonProps {
    clientPkg: IClientPackage
    openPackageDetails: (clientPkg: IClientPackage) => void
}


const ViewIconButton: React.FC<ViewIconButtonProps> = ({ clientPkg, openPackageDetails }) => {

    return (
        <button 
            className={styles.view_btn}
            onClick={() => openPackageDetails(clientPkg)}
        >
            <MdViewCompact 
                color="red"
                size={"1.3rem"}
            />
        </button>            
    );

}

export default ViewIconButton;