import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICoupon } from "../../types/models/Coupon";
import { CouponState, FetchedData, SelectedTreatmentWithDiscountRate } from "../../types/pages/Coupon";


const initialState: CouponState = {
    loading: false,
    error: false,
    validationError: false,
    couponList: [],
    treatmentList: [],
    couponItemInAction: null,
    newCouponInfo: {
        code: "",
        maximumUseLimit: "",
        expiresOn: null,
        selectedTreatmentsWithDiscountRate: [],
        areAllTreatmentsSelected: false,
        allTreatmentsDiscountRate: ""
    },
    searchText: "",
    skip: 0, 
    limit: 5,
    totalCouponCount: 0,
    isAdding: false,
    isEditing: false,
    isDeleting: false,
    actionMessage: ""
}


const couponSlice = createSlice({
    name: "coupons",
    initialState,
    reducers: {

        fetchDataLoading: (state) => {
            return { ...state, loading: true }
        },

        fetchDataSuccess: (state, action: PayloadAction<FetchedData>) => {

            const { couponList, totalCouponCount, treatmentList } = action.payload;

            return {
                ...state,
                loading: false,
                couponList,
                treatmentList: treatmentList ?? state.treatmentList,
                totalCouponCount
            }

        },

        fetchDataFail: (state) => {
            return {
                ...state,
                loading: false,
                error: true
            }
        },

        handleChange: (state, action: PayloadAction<{ name: string, value: any }>) => {
            const { name, value } = action.payload;
            return {
                ...state,
                [name]: value
            }
        },

        addNewCouponInfo: 
            (state, action: PayloadAction<{ name: string, value: string | Date | SelectedTreatmentWithDiscountRate[] }>) => {

            const { name, value } = action.payload;
            return {
                ...state,
                newCouponInfo: {
                    ...state.newCouponInfo,
                    [name]: value
                }
            }
        },

        selectAllTreatment: (state, action: PayloadAction<{ areAllTreatmentsSelected: boolean, allTreatmentsDiscountRate: string }>) => {

            const { areAllTreatmentsSelected, allTreatmentsDiscountRate } = action.payload;

            return {
                ...state,
                newCouponInfo: {
                    ...state.newCouponInfo,
                    areAllTreatmentsSelected, 
                    allTreatmentsDiscountRate
                }
            }

        },

        closeAddCouponModal: (state) => {
            return { 
                ...state, 
                isAdding: false,
                newCouponInfo: {
                    code: "",
                    maximumUseLimit: "",
                    expiresOn: null,
                    selectedTreatmentsWithDiscountRate: [],
                    areAllTreatmentsSelected: false,
                    allTreatmentsDiscountRate: ""
                } 
            }
        },

        openEditCouponModal: (state, action: PayloadAction<ICoupon>) => {

            const coupon = action.payload;
            const { code, maximumUseLimit, expiresOn, treatmentsWithDiscountRate } = coupon;

            const newSelectedTreatments = treatmentsWithDiscountRate.map(item => {
                return {
                    ...item,
                    discountRate: item.discountRate.toString()
                }
            });

            const firstTreatmentDiscountRate = treatmentsWithDiscountRate[0].discountRate.toString();

            const areDiscountRatesDifferent = treatmentsWithDiscountRate.some(item => {
                return item.discountRate.toString() !== firstTreatmentDiscountRate;
            });

            const isAllSelected = treatmentsWithDiscountRate.length >= state.treatmentList.length;

            const selectAllCondition = isAllSelected && !areDiscountRatesDifferent;

            const expiresOnDate = new Date(expiresOn * 1000);

            return { 
                ...state, 
                isEditing: true,
                couponItemInAction: coupon,
                newCouponInfo: { 
                    areAllTreatmentsSelected: selectAllCondition,
                    allTreatmentsDiscountRate: selectAllCondition ? firstTreatmentDiscountRate : "",
                    code,
                    maximumUseLimit: maximumUseLimit.toString(),
                    expiresOn: expiresOnDate,
                    selectedTreatmentsWithDiscountRate: selectAllCondition ? [] : newSelectedTreatments
                } 
            }
        },

        closeEditCouponModal: (state) => {
            return { 
                ...state, 
                isEditing: false,
                couponItemInAction: null,
                newCouponInfo: {
                    code: "",
                    maximumUseLimit: "",
                    expiresOn: null,
                    selectedTreatmentsWithDiscountRate: [],
                    areAllTreatmentsSelected: false,
                    allTreatmentsDiscountRate: ""
                } 
            }
        },

        toggleDeletePrompt: (state, action: PayloadAction<null | ICoupon>) => {
            return {
                ...state,
                couponItemInAction: action.payload,
                isDeleting: !state.isDeleting
            }
        }

    }
})

export const {
    fetchDataLoading,
    fetchDataSuccess,
    fetchDataFail,
    handleChange,
    addNewCouponInfo,
    selectAllTreatment,
    closeAddCouponModal,
    openEditCouponModal,
    closeEditCouponModal,
    toggleDeletePrompt
} = couponSlice.actions;

export default couponSlice.reducer;