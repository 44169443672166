import { IClientPackage } from "../../types/models/ClientPackage";
import styles from "./ResponsiveItemDetails.module.css";
import ItemHeader from "./ItemHeader";
import ItemContent from "./ItemContent";
import ViewIconButton from "./ViewIconButton";

interface ResponsivePackageTableProps {
    clientPackageList: IClientPackage[]
    openPackageDetails: (clientPkg: IClientPackage) => void
}


const ResponsivePackageTable: React.FC<ResponsivePackageTableProps> = ({ clientPackageList, openPackageDetails }) => {
   
    return (
        <>
            {clientPackageList.map(clientPkg => {

                const { 
                    _id,
                    title,
                    price,
                    clientName,
                    clientEmail,
                    clientPhone,
                    status
                } = clientPkg;

                const statusText = status === "active" ? "Active" : "Used Up";

                return (
                    <div 
                        key={_id}
                        className={styles.responsive_appointment_item}
                    >
                        <ItemHeader 
                            title={title}
                            content={
                                <ViewIconButton 
                                    clientPkg={clientPkg}
                                    openPackageDetails={openPackageDetails}
                                />
                            }
                        />
                        <ItemContent 
                            title={clientName}
                            content={`€${price.toFixed(2)}`}
                            otherInfo={[clientEmail, clientPhone, statusText]}
                        />
                       
                    </div>
                );
            })}
        </>
    );

}

export default ResponsivePackageTable;