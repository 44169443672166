import { MdKeyboardArrowLeft } from "react-icons/md";
import styles from "./Others.module.css";

interface IProps {
    handleClick: () => void,
    style?: { [key: string]: string }
}


const PrevIconButton: React.FC<IProps> = ({ handleClick, style }) => {

    return (
        <button 
            className = {styles.prev_icon_btn} 
            onClick = {handleClick}
            style = {style}
        >
            <MdKeyboardArrowLeft 
                size = "1.5rem"
                color = "white"
            />
        </button>
    )

}


export default PrevIconButton;
