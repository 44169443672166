import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILocation } from "../../types/models/Location";
import { LocationState, FetchedLocationData } from "../../types/pages/Location";


const initialState: LocationState = {
    loading: false,
    error: false,
    validationError: false,
    locationList: [],
    locationItemInAction: null,
    newLocationInfo: {
        title: "",
        address: "",
        city: "",
        country: "",
        phone: "",
        zipCode: ""
    },
    locationDataFetchInfo: {
        searchText: "",
        skip: 0, 
        limit: 5,
        totalLocationCount: 0
    },
    isAdding: false,
    isEditing: false,
    isDeleting: false,
    apiCallCount: 1,
    actionMessage: ""
}


const locationSlice = createSlice({
    name: "locations",
    initialState,
    reducers: {

        fetchLocationDataLoading: (state) => {
            return { ...state, loading: true }
        },

        fetchLocationDataSuccess: (state, action: PayloadAction<FetchedLocationData>) => {

            const { totalLocationCount, locationList } = action.payload;

            return {
                ...state,
                loading: false,
                locationDataFetchInfo: {
                    ...state.locationDataFetchInfo,
                    totalLocationCount
                },
                locationList
            }

        },

        fetchLocationDataFail: (state) => {
            return {
                ...state,
                loading: false,
                error: true
            }
        },

        updateSearchText: (state, action: PayloadAction<string>) => {

            return {
                ...state,
                locationDataFetchInfo: {
                    ...state.locationDataFetchInfo,
                    searchText: action.payload
                }
            }

        },

        updateViewLimit: (state, action: PayloadAction<string | number>) => {
            return {
                ...state,
                locationDataFetchInfo: {
                    ...state.locationDataFetchInfo,
                    limit: action.payload
                }
            }
        },

        addNewLocationInfo: (state, action: PayloadAction<{ name: string, value: string }>) => {
            const { name, value } = action.payload;
            return {
                ...state,
                newLocationInfo: {
                    ...state.newLocationInfo,
                    [name]: value
                }
            }
        },

        openAddLocationModal: (state) => {
            return { ...state, isAdding: true }
        },

        closeAddLocationModal: (state) => {
            return { 
                ...state, 
                isAdding: false,
                newLocationInfo: {
                    title: "",
                    address: "",
                    city: "",
                    country: "",
                    phone: "",
                    zipCode: ""
                } 
            }
        },

        openEditLocationModal: (state, action: PayloadAction<ILocation>) => {
            const location = action.payload;
            const { title, address, city, country, phone, zipCode } = location;
            return { 
                ...state, 
                isEditing: true,
                locationItemInAction: location,
                newLocationInfo: { title, address, city, country, phone, zipCode } 
            }
        },

        closeEditLocationModal: (state) => {
            return { 
                ...state, 
                isEditing: false,
                locationItemInAction: null,
                newLocationInfo: {
                    title: "",
                    address: "",
                    city: "",
                    country: "",
                    phone: "",
                    zipCode: ""
                } 
            }
        },

        toggleDeletePrompt: (state, action: PayloadAction<null | ILocation>) => {
            return {
                ...state,
                locationItemInAction: action.payload,
                isDeleting: !state.isDeleting
            }
        },

        updateApiCallCount: (state) => {
            return { ...state, apiCallCount: state.apiCallCount + 1 }
        },

        updateActionMessage: (state, action: PayloadAction<string>) => {
            return { ...state, actionMessage: action.payload }
        }

    }
})

export const {
    fetchLocationDataLoading,
    fetchLocationDataSuccess,
    fetchLocationDataFail,
    updateSearchText,
    updateViewLimit,
    addNewLocationInfo,
    openAddLocationModal,
    closeAddLocationModal,
    openEditLocationModal,
    closeEditLocationModal,
    toggleDeletePrompt,
    updateApiCallCount,
    updateActionMessage
} = locationSlice.actions;

export default locationSlice.reducer;