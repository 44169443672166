import { RootState } from "../store";
import { select, call, put, takeLatest } from "redux-saga/effects";
import { 
    FetchedData,
    GiftCardState
} from "../types/pages/GiftCard";
import { fetchGiftCards } from "./api/gift-card/fetchGiftCards";
import { 
    fetchDataLoading,
    fetchDataSuccess, 
    fetchDataFail
} from "../store/gift-card/giftCardReducer";
import { FETCH_GIFT_CARD_DATA } from "../store/gift-card/constants";
import { handleApiError } from "../helpers/error-handlers/handleApiError";

const giftCardState = (state: RootState) => state.giftCardState;

function* handleFetchGiftCards() {

    const { 
        searchText,
        skip,
        limit
    }: GiftCardState = yield select(giftCardState);

    try {
        yield put(fetchDataLoading());  
        const result: FetchedData  = yield call(fetchGiftCards, { searchText, skip, limit });
        yield put(fetchDataSuccess(result));  
    } catch(error: any) {
        const { message } = handleApiError(error)
        yield put(fetchDataFail(message));
    }

}

export default function* giftCardSaga() {
    yield takeLatest(FETCH_GIFT_CARD_DATA, handleFetchGiftCards);
   
}
