import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { ILocation } from "../../types/models/Location";
import useWindowWidth from "../../hooks/useWindowWidth";
import { updateSelectedLocationList } from "../../store/calendar/calendarReducer";
import MultiSelectDropdown from "../../components/common/dropdown/MultiSelectDropdown";

const SelectLocation: React.FC = () => {

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { locationList, selectedLocationList } = calendarState;
    const { devicesWidth } = useWindowWidth();
    const { isSmartphone } = devicesWidth;

    const chooseLocation = async(args: { item: ILocation }) => {

        const { item: selectedLocation } = args;
        
        const foundSelectedLocation = selectedLocationList.find(item => item._id === selectedLocation._id);

        if(foundSelectedLocation) {
            const filteredSelectedLocations = selectedLocationList.filter(item => item._id !== foundSelectedLocation._id);
            dispatch(updateSelectedLocationList(filteredSelectedLocations));
            return;
        }
        
        dispatch(updateSelectedLocationList([...selectedLocationList, selectedLocation]));

    }

    return (
        <MultiSelectDropdown 
            title="Select Location"
            width={isSmartphone ? "355px" : "200px"}
            data={locationList}
            nameKey="title"
            selectedItems={selectedLocationList}
            onSelectItem={chooseLocation} 
        />
    );

}

export default SelectLocation;