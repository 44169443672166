import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { config } from "../../Constants";
import { RootState } from "../../store";
import { IProductPurchaseOrder } from "../../types/models/Product";
import { FETCH_PURCHASE_ORDER_DATA } from "../../store/products/constants";
import { updateState } from "../../store/products/productReducer";
import { getAuthTokenConfig } from "../../helpers/others/getAuthTokenConfig";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import styles from "./DataTable.module.css";
import InputField from "../../components/common/input-fields/InputField";
import ValidationErrorMessage from "../../components/common/messages/ValidationErrorMessage";
import SaveButton from "./SaveButton";

const ReceivePOProducts = () => {

    const dispatch = useDispatch();
    const productState = useSelector((state: RootState) => state.productState);
    const { receivedPOProductList } = productState;
    const purchaseOrderInAction = productState.purchaseOrderInAction as IProductPurchaseOrder

    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>();
    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, productId: string) => {

        // name is the _id of the product
        const { name, value } = event.target;

        const foundProduct = purchaseOrderInAction.productList.find(item => item.productItem._id === name);
        if(!foundProduct) return;

        const newReceivedPOProductList = receivedPOProductList.map(item => {
            if(item.productId === name) {
                return {
                    ...item,
                    receivedQuantity: parseInt(value)
                }
            }

            return item
        });

        dispatch(updateState({
            name: "receivedPOProductList",
            value: newReceivedPOProductList
        }));
        
    }

    const saveReceivedPO = async() => {

        const isEmpty = receivedPOProductList.some(item => {
            return isNaN(item.receivedQuantity) || item.quantity < item.receivedQuantity
        });

        if(isEmpty) {
            setErrorMsg("Received quantity is invalid");
            setLoading(false);
            return;
        }

        setLoading(true);

        const requestBody = {
           purchaseOrderId: purchaseOrderInAction._id,
           receivedPOProductList
        }

        const endpoint = config.url.BACKEND_API_URL + "/products/purchase-order/receive";
        const authConfig = getAuthTokenConfig();

        try {

            await axios.post(endpoint, requestBody, authConfig);
            setLoading(false);
            setIsSuccess(true);
            dispatch({ type: FETCH_PURCHASE_ORDER_DATA })
          
        } catch(error) {
            const { message } = handleApiError(error);
            setErrorMsg(message);
            setLoading(false);
        }

    }

    return (
        <div className={styles.receive_po_products}>
            <h2>Receive PO Products</h2>
            {
                isSuccess
                ?
                <div className={styles.purchase_order_success}>
                    <h3>Records updated successfully</h3>
                </div>
                :
                <>
                    <div className={styles.receive_po_products_table}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Order Quantity</th>
                                    <th>Received Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                {purchaseOrderInAction.productList.map(item => {

                                    const { productItem, quantity } = item;

                                    const matchedReceivedProduct = receivedPOProductList.find(p => p.productId === productItem._id);

                                    return (
                                        <tr key={productItem._id}>
                                            <td>{productItem.name}</td>
                                            <td>{quantity}</td>
                                            <td>
                                                <InputField 
                                                    customClassName="purchase_order_input"
                                                    name={productItem._id}
                                                    type="number"
                                                    value={matchedReceivedProduct?.receivedQuantity.toString() ?? ""}
                                                    disabled={true}
                                                    handleChange={handleChange}
                                                    args={item.productItem._id}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>  
                    </div>
                    {errorMsg ? <ValidationErrorMessage message={errorMsg}/> : null}
                    <SaveButton 
                        disabled={loading}
                        onClick={saveReceivedPO}
                    />
                </>
            } 
        </div>
    );

}

export default ReceivePOProducts;
