import { useDispatch } from "react-redux";
import { closeAddEditDiscount } from "../../store/discounts/discountReducer";
import styles from "./index.module.css";


const CloseDiscountButton: React.FC = () => {

    const dispatch = useDispatch();
    const handleClick = () => dispatch(closeAddEditDiscount());

    return (
        <button className = {styles.button} onClick = {handleClick}>
            Close
        </button>
    );
    
}


export default CloseDiscountButton;