import { convertHexToRgb } from "./convertHexToRgb";

type Params = {
    headers: string[][]
    data: string[][]
    startY: number
    boldRowIndexes?: number[]
}

export const createPDFTable = (params: Params) => {

    const { headers, data, startY, boldRowIndexes = [] } = params;
    const tableHeaderFooterBGColor = convertHexToRgb("#f8efee");

    let content = {
        startY: startY,
        head: headers,
        body: data,
        headStyles: {
            fillColor: tableHeaderFooterBGColor,
            textColor: [0, 0, 0],
            cellPadding: { left: 20, right: 20, top: 10, bottom: 10 }
        },
        bodyStyles: {
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            cellPadding: { left: 20, right: 20, top: 10, bottom: 10 }
        },
        alternateRowStyles: { fillColor: [255, 255, 255] },
        didParseCell: function (tableData: any) {
            if(
                tableData.row.index === data.length - 1 ||
                boldRowIndexes.includes(tableData.row.index)
            ) {
                tableData.cell.styles.fontStyle = "bold";
                tableData.cell.styles.fillColor = tableHeaderFooterBGColor;
            }
        }
    };

    return content;

}