import logoPhoto from "../../assets/images/bookitu_logo.png"
import styles from "./LoginSignup.module.css";

const LogoImage: React.FC = () => {

    return (
        <div className={styles.logo_image}>
            <img 
                src = {logoPhoto} 
                alt = "design" 
            />
        </div>
    );

}

export default LogoImage;