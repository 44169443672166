import styled from "styled-components";

export type IModalContentProps = {
    backgroundColor?: string
    width?: string
    height?: string
    padding?: string
    zIndex?: number
}

export type IModalCloseButtonProps = {
    topPosition?: string
    leftPosition?: string
    rightPosition?: string
    transform?: string
    color?: string
    padding?: string
    fontSize?: string
}


export const ModalBackground = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.842);
    opacity: 0.8;
`

export const ModalCloseButton = styled.button<IModalCloseButtonProps>`
    position: absolute;
    top: ${({ topPosition }) => topPosition ?? "40px"};
    left: ${({ leftPosition }) => leftPosition};
    right: ${({ rightPosition }) => rightPosition ?? "40px"};
    transform: ${({ transform }) => transform};
    background: none;
    cursor: pointer;
    color: ${({ color }) => color ?? "white"};
    padding: ${({ padding }) => padding ?? "0px"};
    font-size: ${({ fontSize }) => fontSize ?? "2rem"};
`

export const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 4;
`

export const ModalContent = styled.div<IModalContentProps>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    overflow: auto;
    padding: ${({ padding }) => padding ?? "30px"};
    background-color: ${({ backgroundColor }) => backgroundColor ?? "#FFFFFF"};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
`