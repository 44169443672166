import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ISubscription } from "../../../types/models/Subscription";
import { RootState } from "../../../store";
import useFetchUpcomingInvoice from "../../../hooks/backend_api/stripe/useFetchUpcomingInvoice";
import { updateState, closeCheckoutForm } from "../../../store/profile-tenant/tenantProfileReducer";
import { TAX_RATE } from "../../../data/subscriptionInfo";
import styles from "./CheckoutForm.module.css";
import InputField from "../../../components/common/input-fields/InputField";
import CheckboxInputField from "../common/CheckboxInputField";
import LoadingError from "../../../components/common/loading-error/LoadingError";
import SelectPrice from "./SelectPrice";
import SelectOptions from "../stripe-elements/SelectOptions";
import CreditCard from "../stripe-elements/CreditCard";
import SepaDebit from "../stripe-elements/SepaDebit";
import PayButton from "./PayButton";
import Button from "../common/Button";
import SubSuccessMsg from "./SubSuccessMsg";

const CheckoutForm: React.FC = () => {

    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;
    const tenantProfileState = useSelector((state: RootState) => state.tenantProfileState);
    const selectedSubscription = tenantProfileState.selectedSubscription as ISubscription;
    const { selectedSubscriptionPrice, selectedPaymentMethod, vatID } = tenantProfileState;

    const { 
        errorMsg: fetchErrorMsg,
        stripeUpcomingInvoice 
    } = useFetchUpcomingInvoice(selectedSubscriptionPrice?.stripePriceId, vatID);

    const [isUpdatingSubscription, setIsUpdatingSubscription] = useState<boolean>(false);
    const [showVatIDInput, setShowVatIDInput] = useState<boolean>(false);
    const [tax, setTax] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    const hasActiveSubscription = user && user.stripeSubscription && user.stripeSubscription.isActive;
    
    useEffect(() => {
        setTax((stripeUpcomingInvoice?.tax ?? 0) / 100)
    }, [stripeUpcomingInvoice?.tax])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const { name, value, checked } = event.target;

        if(name === "showVatIDInput") {
            setShowVatIDInput(checked);
            if(!checked) dispatch(updateState({ name: "vatID", value: "" }));
            return;
        }

        dispatch(updateState({
            name: "vatID",
            value
        }));

    }

    const handleClick = () => {
        dispatch(closeCheckoutForm());
    }

    const subPriceAmount = selectedSubscriptionPrice?.amount ?? 0;
    const totalInvoiceAmount = subPriceAmount + tax;

    return (
        <div className={styles.checkout_form}>
            {
                errorMsg
                ?
                <>
                    <LoadingError 
                        message={errorMsg}
                    />
                    <Button 
                        customClassName="success_message_close_btn"
                        buttonText="OK"
                        handleClick={handleClick}
                    />
                </>
                :
                isSuccess
                ?
                <SubSuccessMsg 
                    isUpdatingSubscription={isUpdatingSubscription}
                />
                :
                <>
                    {
                        hasActiveSubscription
                        ?
                        <p className={styles.checkout_header}>
                            You are about to update your subscription plan 
                            from <span>{user.stripeSubscription?.subscriptionPlan.title}</span> to <span>{selectedSubscription.title}</span>
                        </p>
                        :
                        <p className={styles.checkout_header}>
                            You have choosen <span>{selectedSubscription.title}</span> subscription plan
                        </p>
                    }
                    <div className={styles.select_price_invoice_summary}>
                        <SelectPrice setTax={setTax} />
                        <div className="">
                            <div className={styles.invoice_summary}>
                                <div className={styles.invoice_amount}>
                                    <p>Invoice Amount</p>
                                    <p>€{subPriceAmount.toFixed(2)}</p>
                                </div>
                                <div className={styles.invoice_amount}>
                                    <p>Tax ({tax > 0 ? TAX_RATE : 0}%)</p>
                                    <p>€{tax.toFixed(2)}</p>
                                </div>
                                <div className={styles.invoice_amount}>
                                    <p>Total Invoice Amount</p>
                                    <p>€{totalInvoiceAmount.toFixed(2)}</p>
                                </div>
                            </div>
                            {
                                !hasActiveSubscription
                                ?
                                <div className={styles.vat_id}>
                                    <CheckboxInputField 
                                        name="showVatIDInput"
                                        value="I am purchasing this as a business"
                                        checked={showVatIDInput}
                                        handleChange={handleChange}
                                    />
                                    {
                                        showVatIDInput
                                        ?
                                        <div className={styles.input_container}>
                                            <InputField 
                                                withMarginBottom={false}
                                                labelText="VAT ID"
                                                name="vatID"
                                                value={vatID}
                                                placeholder="Enter a valid VAT ID"
                                                handleChange={handleChange}
                                            />
                                            {
                                                (fetchErrorMsg && fetchErrorMsg.includes("VAT ID"))
                                                ?
                                                <p className={styles.vat_error_msg}>
                                                    {fetchErrorMsg}
                                                </p>
                                                :
                                                null
                                            }
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                    {
                        !hasActiveSubscription
                        &&
                        <>
                            <SelectOptions />
                            {selectedPaymentMethod === "Credit Card" && <CreditCard />}
                            {selectedPaymentMethod === "Sepa Debit" && <SepaDebit />}
                        </>
                    }
                    <PayButton 
                        loading={loading}
                        showVatIDInput={showVatIDInput}
                        vatIDErrorMsg={fetchErrorMsg}
                        setLoading={setLoading}
                        setErrorMsg={setErrorMsg}
                        setIsSuccess={setIsSuccess}
                        setIsUpdatingSubscription={setIsUpdatingSubscription}
                        
                    />
                </>
            }
        </div>
    );

}


export default CheckoutForm;