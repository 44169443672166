import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FaCcStripe } from "react-icons/fa";
import { RootState } from "../../store";
import { UserAuth } from "../../auth/UserAuth";
import useCreateStripeAccount from "../../hooks/backend_api/stripe/useCreateStripeAccount";
import useCreateStripeAccountLink from "../../hooks/backend_api/stripe/useCreateStripeAccountLink";
import useWindowWidth from "../../hooks/useWindowWidth";
import styles from "./index.module.css";
import IntegrationItem from "./IntegrationItem";

const Integrations = () => {

    const { loading: accountIdLoading, createStripeAccount } = useCreateStripeAccount();
    const { loading: accountLinkLoading, createStripeAccountLink } = useCreateStripeAccountLink();
    const { windowWidth } = useWindowWidth();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;

    // We need to fetch subscription and invoice data of the user
    useEffect(() => {

        if(!UserAuth()) {
            navigate("/");
            return;
        }

        if(!user) return;

        // If logged in user is not Tenant then he/she must be redirected to user profile
        if(user.userType !== "Tenant") {
            navigate(`/user/${user.tenantId}/profile-user`);
        }

    }, [user, navigate, dispatch])

    const handleClick = async() => {

        const currentAccountId = user?.stripeConnectedAccountId;

        if(currentAccountId) {
            createStripeAccountLink(currentAccountId);
            return;
        }

        const { errorMsg, accountId } = await createStripeAccount();

        if(errorMsg) {
            alert(errorMsg);
            return;
        }

        createStripeAccountLink(accountId);
        
    }

    const isStripeConnected = (user?.stripeConnectedAccountId && user?.isStripeConnectedAccountActive) ? true : false;

    return (
        <div className={styles.integrations}>
            <div className={styles.integrations_content}>
                <div className={styles.integration_header}>
                    <h1>Integrations</h1>
                    <p>Connect your Workspace with integrations</p>
                </div>
                <div className={styles.integration_item_list}>
                    <IntegrationItem 
                        windowWidth={windowWidth}
                        header="Stripe"
                        description="Connect your Stripe account to receive the payouts directly in your account"
                        logo={
                            <FaCcStripe 
                                size={"2.5rem"}
                            />
                        }
                        buttonText={isStripeConnected ? "Connected" : "Connect"}
                        disabled={isStripeConnected || accountIdLoading || accountLinkLoading}
                        onClick={handleClick}
                    />
                </div>
            </div>
        </div>
    );

}

export default Integrations;