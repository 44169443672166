import { useEffect, useState } from "react";
import { IClientPackage } from "../../types/models/ClientPackage";
import { useFetchClientPackages } from "../../hooks/backend_api/reports/useFetchClientPackages";
import { getPackageCSVContent } from "../../helpers/reports/getPackageCSVContent";
import { downloadCSV } from "../../helpers/reports/downloadCSV";
import styles from "./index.module.css";
import InputField from "./InputField";
import ClientPackageTable from "./ClientPackageTable";
import Modal from "../../components/common/modal/Modal";
import ClientPackageDetails from "./ClientPackageDetails";
import ExportCSVButton from "./ExportCSVButton";


const ClientPackageList: React.FC = () => {

    const [isMounted, setIsMounted] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>();
    const [isSearchTextBackspacedToEmpty, setIsSearchTextBackspacedToEmpty] = useState(false);
    const [clientPkgInAction, setClientPkgInAction] = useState<IClientPackage | null>(null);
    const { clientPackageList, fetchClientPackages } = useFetchClientPackages({ 
        searchText, 
        skip: 0, 
        limit: "all" 
    });

    useEffect(() => {
        if(isMounted) return;
        fetchClientPackages();
        setIsMounted(true);
    }, [fetchClientPackages, isMounted]);

    useEffect(() => {

        if(!isSearchTextBackspacedToEmpty && !searchText) return; 
        
        const delayAPICall = setTimeout(() => {
            fetchClientPackages();
        }, 500);

        return () => clearTimeout(delayAPICall);

    }, [fetchClientPackages, isSearchTextBackspacedToEmpty, searchText])

    const openPackageDetails = (clientPkg: IClientPackage) => {
        setClientPkgInAction(clientPkg);
    }

    const closeModal = () => {
        setClientPkgInAction(null);
    }

    const searchPackage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setSearchText(value);
        if(!value) setIsSearchTextBackspacedToEmpty(true);
    }

    const exportPackagesToCSV = () => {
        const csvContent = getPackageCSVContent(clientPackageList);
        downloadCSV(csvContent);
    }

    return (
        <>
            <div className={styles.package_search_export}>
                <InputField 
                    customClassName="search_input" 
                    type="text"
                    name="searchText"
                    value={searchText ?? ""}
                    placeholder="Search"
                    handleChange={searchPackage}
                />
                <ExportCSVButton 
                    exportToCSV={exportPackagesToCSV}
                />
            </div>
            <ClientPackageTable 
                clientPackageList={clientPackageList}
                openPackageDetails={openPackageDetails}
            />
            {
                clientPkgInAction
                ?
                <Modal 
                    modalContent={
                        <ClientPackageDetails 
                            clientPkgInAction={clientPkgInAction}
                        />
                    }
                    onCloseModal={closeModal}
                />
                :
                null
            }
        </>
    );

}

export default ClientPackageList;