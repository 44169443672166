import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { FETCH_APPOINTMENT_DATA } from "../../../store/calendar/constants";
import { updateCalendarDates, updateState } from "../../../store/calendar/calendarReducer";
import { calculateWeekDates } from "../../../helpers/calendar/calculateWeekDates";
import { calculateMonthDates } from "../../../helpers/calendar/calculateMonthDates";
import styles from "./CalendarHeader.module.css";


const DateNavigation: React.FC = () => {

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { 
        calendarViewType,
        dayViewDate,
        weekViewDates,
        monthViewDates 
    } = calendarState;

    const refetchAppointmentData = () => {
        setTimeout(() => {
            dispatch({ type: FETCH_APPOINTMENT_DATA })
        }, 10)
    }

    const navigateToNextDates = () => {

        const oneDayMS = 86400000;

        if(calendarViewType === "week") {
            const newWeekViewDates = calculateWeekDates("next", weekViewDates);
            dispatch(updateCalendarDates({
                name: "weekViewDates",
                value: newWeekViewDates
            }));
            refetchAppointmentData();
            return;
        }

        if(calendarViewType === "month") {
            const newMonthViewDates = calculateMonthDates("next", monthViewDates);
            dispatch(updateCalendarDates({
                name: "monthViewDates",
                value: newMonthViewDates
            }));
            refetchAppointmentData()
            return;
        }

        const nextDate = new Date(dayViewDate.getTime() + oneDayMS);

        dispatch(updateCalendarDates({
            name: "dayViewDate",
            value: nextDate
        }));

        refetchAppointmentData()

    }

    const navigateToPreviousDates = () => {

        const oneDayMS = 86400000;

        if(calendarViewType === "week") {
            const newWeekViewDates = calculateWeekDates("previous", weekViewDates);
            dispatch(updateCalendarDates({
                name: "weekViewDates",
                value: newWeekViewDates
            }));
            refetchAppointmentData()
            return;
        }

        if(calendarViewType === "month") {
            const newMonthViewDates = calculateMonthDates("previous", monthViewDates);
            dispatch(updateCalendarDates({
                name: "monthViewDates",
                value: newMonthViewDates
            }));
            refetchAppointmentData();
            return;
        }

        const previousDate = new Date(dayViewDate.getTime() - oneDayMS);

        dispatch(updateCalendarDates({
            name: "dayViewDate",
            value: previousDate
        }));

        refetchAppointmentData();

    }

    const navigateToToday = () => {

        dispatch(updateCalendarDates({
            name: "dayViewDate",
            value: new Date()
        }));

        // We also need to update calendar view type to "day"
        dispatch(updateState({
            name: "calendarViewType",
            value: "day"
        }));

        refetchAppointmentData();

    }
  
    return (  
        <div className={styles.date_navigation}>
            <button onClick={navigateToPreviousDates}>
                Prev
            </button>
            <button onClick={navigateToToday}>
                Today
            </button>
            <button onClick={navigateToNextDates}>
                Next
            </button>
        </div>
    );

}

export default DateNavigation;