import axios from "axios";
import Cookies from "universal-cookie";
import { GiftCardState } from "../../../types/pages/GiftCard";
import { config } from "../../../Constants";

type Payload = Pick<GiftCardState, "searchText" | "skip" | "limit">

export const fetchGiftCards = async(payload: Payload) => {

    const { 
        searchText,
        skip, 
        limit
    } = payload;

    const cookies = new Cookies();

    const params = `searchText=${searchText}&skip=${skip}&limit=${limit}`;
    const endpoint = `${config.url.BACKEND_API_URL}/gift-cards?${params}`;

    const axiosConfig = {
        headers: {
            "auth-token": cookies.get("auth_token")
        }
    }
  
    const response = await axios.get(endpoint, axiosConfig);
    return response.data;

}
