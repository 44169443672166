export enum DefaultPaymentMethod {
    CreditCard = "credit_card",
    IDEAL = "ideal",
    Paypal = "paypal",
    Card = "card",
    Cash = "cash"
};

export interface DefaultPaymentMethodObj {
    _id: number
    method: string
    methodText: string
}