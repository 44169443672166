import axios from "axios";
import Cookies from "universal-cookie";
import { FetchAppointmentQueryParams } from "../../../types/pages/Calendar";
import { config } from "../../../Constants";


export const fetchAppointments = async(payload: FetchAppointmentQueryParams) => {

    const { 
        startDateString, 
        endDateString,
        selectedLocationIds = "",
        selectedStylistIds = "" 
    } = payload;

    const cookies = new Cookies();

    const param1 = `startDateString=${startDateString}&endDateString=${endDateString}&`;
    const param2 = `selectedLocationIds=${selectedLocationIds}&selectedStylistIds=${selectedStylistIds}`
    
    const params = param1 + param2
    
    const endpoint = `${config.url.BACKEND_API_URL}/appointments/calendar-appointments?${params}`;
    const axiosConfig = {
        headers: {
            "auth-token": cookies.get("auth_token")
        }
    }
  
    const response = await axios.get(endpoint, axiosConfig);
    return response.data;

}