import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { IWaitList } from "../../types/models/WaitList";
import useWindowWidth from "../../hooks/useWindowWidth";
import { toggleDeletePrompt, updateState } from "../../store/wait-list/waitListReducer";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import styles from "./WaitListTable.module.css";
import ActionButtons from "./ActionButtons";
import ResponsiveWaitListTable from "./ResponsiveWaitListTable";
import LoadingError from "../../components/common/loading-error/LoadingError";

const WaitListTable: React.FC = () => {

    const { devicesWidth: { isLargeDevice } } = useWindowWidth();
    const dispatch = useDispatch();
    const waitListState = useSelector((state: RootState) => state.waitListState);
    const { waitList } = waitListState;
    
    const handleActionButtonClick = (actionType: string, waitListItem: IWaitList) => {
       
        if(actionType === "delete") {
            dispatch(toggleDeletePrompt(waitListItem));
            return;
        }

        dispatch(updateState({
            name: "waitListItemInAction",
            value: waitListItem
        }));

    }

    return (
        <div className={styles.wait_list_table}> 
            {
                waitList.length <= 0
                ?
                <LoadingError 
                    message="No Data To Show"
                />
                :
                isLargeDevice
                ?
                <table>
                    <thead>
                        <tr>
                            <th>Client Email</th>
                            <th>Target Appointment Date</th>
                            <th>Session</th>
                            <th>Treatments And Stylists</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {waitList.map(waitListItem => {

                            const { 
                                _id,
                                clientEmail,
                                targetAppointmentDate,
                                targetDaySession,
                                selectedTreatmentStylistList
                            } = waitListItem;

                            const treatmentStylistNames = selectedTreatmentStylistList.map(item => {
                                return `${item.treatment.title} - ${item.stylist.fullName}`
                            });

                            const { day, year, month } = formatDateTime(new Date(targetAppointmentDate * 1000))

                            return (
                                <tr key={_id}>
                                    <td>{clientEmail}</td>
                                    <td>{`${day} ${month}, ${year}`}</td>
                                    <td>{targetDaySession}</td>
                                    <td>
                                        <ul className={styles.treatment_stylist_list}>
                                            {treatmentStylistNames.map(item => {
                                                return (
                                                    <li key={item}>{item}</li>
                                                );
                                            })}
                                        </ul>
                                    </td>
                                    <td>
                                        <ActionButtons 
                                            waitListItem={waitListItem}
                                            showButtonText={true}
                                            handleActionButtonClick={handleActionButtonClick}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                :
                <ResponsiveWaitListTable 
                    handleActionButtonClick={handleActionButtonClick}
                />
            }
        </div>
    );

}

export default WaitListTable;