import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { UserAuth } from "../../auth/UserAuth";
import { FETCH_DATA } from "../../store/profile-tenant/constants";
import { 
    closeCheckoutForm,
    toggleEditTenantInfoModal 
} from "../../store/profile-tenant/tenantProfileReducer";
import useWindowWidth from "../../hooks/useWindowWidth";
import styles from "./TenantProfile.module.css";
import SubscriptionPlans from "./subscriptions/SubscriptionPlans";
import TenantInfo from "./tenant-info/TenantInfo";
import ChangePlan from "./change-plan/ChangePlan";
import NextPayment from "./update-payment-method/NextPayment";
import InvoiceList from "./invoices/InvoiceList";
import EditTenantInfo from "./tenant-info/EditTenantInfo";
import CheckoutForm from "./checkout-form/CheckoutForm";
import UpdatePaymentMethod from "./update-payment-method/UpdatePaymentMethod";
import BookingSnippet from "./booking-snippet/BookingSnippet";
import Modal from "../../components/common/modal/Modal";


const TenantProfile = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tenantProfileState = useSelector((state: RootState) => state.tenantProfileState);
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;
    const { isTenantInfoEditing, selectedSubscription, isUpdatingPaymentMethod } = tenantProfileState;

    const { devicesWidth: { isLargeDevice } } = useWindowWidth();

    // We need to fetch subscription and invoice data of the user
    useEffect(() => {

        if(!UserAuth()) {
            navigate("/");
            return;
        }

        if(!user) return;

        // If logged in user is not Tenant then he/she must be redirected to user profile
        if(user.userType !== "Tenant") {
            navigate(`/user/${user.tenantId}/profile-user`);
        }

        dispatch({ type: FETCH_DATA });

    }, [user, navigate, dispatch])

    const closeModal = () => {
        if(selectedSubscription) {
            dispatch(closeCheckoutForm());
            return;
        }
        dispatch(toggleEditTenantInfoModal(null));
    }

    const condition = user?.stripeSubscription
  
    return (
        <>
            { 
                user
                ?
                <div className = {styles.tenant_profile}>
                    <div className = {styles.tenant_profile_content}>
                        <TenantInfo />
                        {user.isOnTrialPeriod || user.stripeSubscription ? <ChangePlan /> : null}
                        {user.userType === "Tenant" && <SubscriptionPlans />}
                        {
                            condition
                            ?
                            <div className = {styles.next_payment_invoice}>
                                <NextPayment />
                                <InvoiceList />
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
                :
                null
            }
            {
                isTenantInfoEditing
                ?
                <Modal 
                    minWidth={isLargeDevice ? "60%" : "95%"}
                    modalContent={<EditTenantInfo />}
                    onCloseModal={closeModal}
                />
                :
                null
            }
            {
                selectedSubscription
                ?
                <Modal 
                    minWidth={isLargeDevice ? "60%" : "95%"}
                    modalContent={<CheckoutForm />}
                    onCloseModal={closeModal}
                />
                :
                null
            }
            {isUpdatingPaymentMethod && <UpdatePaymentMethod />}
            <BookingSnippet />
        </>
    );

}


export default TenantProfile;