import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import styles from "./BookingSnippet.module.css";
import SnippetItem from "./SnippetModalItem";


const BookingSnippet = () => {

    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;

    const [showSnippetModal, setShowSnippetModal] = useState(false);

    const toggleSnippetModal = () => {
        setShowSnippetModal(!showSnippetModal)
    }

    const params = `tenantId=${user?.tenantId}&salonName=${user?.companyName}`;
  
    return (
        <>
           <button 
                className={styles.copy_snippet_btn}
                onClick={toggleSnippetModal}
           >
                Copy Booking Snippet
            </button>
            {
                showSnippetModal
                ?
                <div className={styles.snippet_modal}>
                    <button 
                        className={styles.snippet_cls_btn}
                        onClick={toggleSnippetModal}
                    >
                        X
                    </button>
                    <SnippetItem 
                        title="For Booking Appointment And Buying Package Widget"
                        description="Please copy the following html and paste it at the bottom of the footer section of your webpage"
                        code={
                            <>
                                <p>{`<button id="iframeTglBtn">Book Appointment</button>`}</p>
                                <p>{`<iframe id="bktfrm" src="https://client.bookitu.com/services?${params}" />`}</p>
                                <p>{`<script async src="https://media.bookitu.com/frmscrpt.js"></script>`}</p>
                            </>
                        }
                    />
                    <SnippetItem 
                        title="For Iframe"
                        description="Please copy the following iframe html code and paste it in your webpage"
                        code={
                            <>
                                <p>
                                    {`<iframe src="https://client.bookitu.com/book-appointments?${params}" style="height:1000px;"/>`}
                                </p>
                                <p>
                                    {`<iframe src="https://client.bookitu.com/buy-package?${params}" style="height:1000px;"/>`}
                                </p>
                                <p>
                                    {`<iframe src="https://client.bookitu.com/buy-product?${params}" style="height:1000px;"/>`}
                                </p>
                            </>
                        }
                    />
                    <SnippetItem 
                        title="For Link"
                        description="Please copy the following url and paste it as an anchor tag(link) in your webpage"
                        code={
                            <>
                                <p>{`https://client.bookitu.com/book-appointments?${params}`}</p>
                                <p>{`https://client.bookitu.com/buy-package?${params}`}</p>
                                <p>{`https://client.bookitu.com/buy-product?${params}`}</p>
                            </>
                        }
                    />
                </div>
                :
                null
            }
        </>
    );

}


export default BookingSnippet;