import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { getSideNavItems } from "../helpers/layouts/getSideNavItems";
import styles from "./Layouts.module.css";
import NavItem from "./NavItem";


const NavItemList: React.FC<{ currentUrl: string }> = ({ currentUrl }) => {

    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;

    const sideNavItems = useMemo(() => getSideNavItems(user), [user]);

    return (
    
        <div className = {styles.nav_item_list}>
            {sideNavItems.map(item => {
                const { _id, url, text, icon } = item;

                return (
                    <NavItem 
                        key = {_id}
                        icon = {icon}
                        linkText = {text}
                        linkHref = {url}
                        style = {{ color: currentUrl === url ? "red" : "black" }}
                    />
                );
            })}
        </div>
         
    );

}

export default NavItemList;