import styles from "./index.module.css";
import EditIcon from "../../components/icons/EditIcon";
import DeleteIcon from "../../components/icons/DeleteIcon";
import IconButton from "./IconButton";

interface ActionButtonProps {
    itemId: string
    showButtonText?: boolean
    handleClick: (actionType: string, itemId: string) => void
}

const ActionButtons: React.FC<ActionButtonProps> = ({ itemId, showButtonText = true, handleClick}) => {

    return (
        <div className={styles.action_buttons}> 
            <IconButton 
                buttonText={showButtonText ? "Edit" : ""}
                icon={<EditIcon />}
                actionType="edit"
                itemId={itemId}
                handleClick={handleClick}
            />
            <IconButton 
                buttonText={showButtonText ? "Delete" : ""}
                icon={<DeleteIcon />}
                actionType="delete"
                itemId={itemId}
                handleClick={handleClick}
            />
        </div>            
    );

}

export default ActionButtons;