import jsPDF from "jspdf";
import "jspdf-autotable";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { IInvoice } from "../../../types/models/Invoice";
import { getFormattedDateTime } from "../../../helpers/date-time/getFormattedDateTime";
import { convertHexToRgb } from "../../../helpers/others/convertHexToRgb";
import { createPDFTable } from "../../../helpers/others/createPdfTable";
import { formatDateTime } from "../../../helpers/date-time/dateTimeHelpers";
import styles from "./InvoiceList.module.css";
import InvoiceListHeader from "./InvoiceListHeader";
import DownloadIconButton from "../../../components/common/buttons/DownloadIconButton";

const InvoiceList: React.FC = () => {

    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;
    const tenantProfileState = useSelector((state: RootState) => state.tenantProfileState);
    const { invoiceList } = tenantProfileState;

    const downloadSubInvoicePDF = (invoice: IInvoice) => {

        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const doc = new jsPDF(orientation, unit, size);

        // Define header dimensions and padding
        const headerHeight = 180;
        const margin = 40;
        const padding = 20;

        // Define header colors
        const headerBackgroundColor = convertHexToRgb('#475467'); // Light grey background
        const headerTextColor = convertHexToRgb('#FFFFFF'); // Black text

        // Draw header background
        doc.setFillColor(headerBackgroundColor[0], headerBackgroundColor[1], headerBackgroundColor[2]);

        const pageSize = doc.internal.pageSize.getWidth() - (2 * margin);
        doc.rect(margin, margin, pageSize, headerHeight, "F"); // "F" for filled rectangle

        const startDate = user?.stripeSubscription?.currentPaymentPeriodStartDate ?? 0;
        const endDate = user?.stripeSubscription?.currentPaymentPeriodEndDate ?? 0;

        const { day, month, year } = formatDateTime(new Date(startDate * 1000))
        const { day: endDay, month: endMonth, year: endYear } = formatDateTime(new Date(endDate * 1000));

        const startDateString = `${day} ${month}, ${year}`;
        const endDateString = `${endDay} ${endMonth}, ${endYear}`;
    
        let textFontSize = 12;

        // Define the texts for the left and right sides of the header
        const leftTexts = [
            { text: 'Invoiced By:' },
            { text: "Tecuty" },
            { text: "The Hague" },
            { text: "Invoice Number" },
            { text: invoice.invoiceNumber },
        ];

        const rightTexts = [
            { text: 'Invoiced To:' },
            { text: user?.companyName ?? "" },
            { text: `${user?.companyAddress.city}` ?? "" },
            { text: `${user?.companyAddress.country}` ?? "" },
            { text: user?.stripeSubscription?.vatId ?? "" },
            { text: "Invoice Date" },
            { text: startDateString },
        ];

        // Set the initial positions
        let leftYPosition = margin + padding + 40;
        const leftXPosition = margin + padding;
        let rightYPosition = margin + padding + 40;
        const rightXPosition = 100 + doc.internal.pageSize.getWidth() / 2;

        // Set text color
        doc.setTextColor(headerTextColor[0], headerTextColor[1], headerTextColor[2]);
        doc.setFontSize(12)

        // Add left side texts to the header
        leftTexts.forEach((item, index) => {
            if(index === 3) leftYPosition = 190;
            doc.text(item.text, leftXPosition, leftYPosition);
            leftYPosition += textFontSize + 4; // Adjust line height based on font size
        });
    
        // Add right side texts to the header
        rightTexts.forEach((item, index) => {
            if(index === 5) rightYPosition = 190;
            doc.text(item.text, rightXPosition, rightYPosition);
            rightYPosition += textFontSize + 4; // Adjust line height based on font size
        });

        const bookituTextColor = convertHexToRgb("#FFAAA5")

        doc.setTextColor(bookituTextColor[0], bookituTextColor[1], bookituTextColor[2]);
        doc.setFontSize(28);
       
        doc.text("Bookitu", 60, 75);

        doc.setFontSize(textFontSize + 2)
        doc.setTextColor(0, 0, 0);
        doc.text("Invoice Period", leftXPosition, 250);
        doc.text(`${day} ${month}, ${year} - ${endDateString}`, rightXPosition - 100, 250);

        const headers = [["Description", "Price"]];

        const data = [
            [user?.stripeSubscription?.subscriptionPlan?.title ?? "", `€${user?.stripeSubscription?.price.toFixed(2)}`],
            ["VAT", `€${user?.stripeSubscription?.taxAmount.toFixed(2)}`],
            ["Total", `€${user?.stripeSubscription?.priceIncludingTax.toFixed(2)}`]
        ]

        const content = createPDFTable({ headers, data, startY: 270 });

        //@ts-ignore
        doc.autoTable(content);
        doc.save("invoice.pdf")
       
    }
    
    return (
        <div className = {styles.invoice_list}>
            <InvoiceListHeader 
                invoiceCount = {invoiceList.length}
            />
            <div className = {styles.invoice_list_content}>
                {invoiceList.map((invoice: IInvoice) => {

                    const { _id, invoiceNumber, createdAt, amount } = invoice;

                    const invoiceDate = getFormattedDateTime(new Date(createdAt * 1000));
                    const { month, day, year } = invoiceDate;

                    return (
                        <div key = {_id} className = {styles.invoice_info}>
                            <div className = {styles._invoice_date_number}>
                                <h4 className = {styles.invoice_date}>{`${month} ${day}, ${year}`}</h4>
                                <p className = {styles.invoice_number}>#{invoiceNumber}</p>
                            </div>
                            <p>€{amount.toFixed(2)}</p>
                            <DownloadIconButton 
                                onDownload={downloadSubInvoicePDF}
                                args={invoice}
                            />
                        </div>
                    );
                })}
            </div>
            <div className = {styles.download_all_btn}>
                <button>Download All</button>
            </div>

        </div>
    );

}


export default InvoiceList;