import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import { RootState } from "../../store";
import { config } from "../../Constants";
import { toggleDeletePrompt, updateState } from "../../store/packages/packageReducer";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import { FETCH_PACKAGE_DATA } from "../../store/packages/constants";
import DeletePrompt from "../../components/common/prompts/DeletePrompt";


const DeletePackage = () => {

    const cookies = new Cookies();

    const dispatch = useDispatch();
    const packageState = useSelector((state: RootState) => state.packageState);
    const { packageItemInAction, searchText, skip, limit } = packageState;

    const closeDeletePrompt = () => {
        dispatch(toggleDeletePrompt(null));
    }

    const deletePackage = async() => {

        dispatch(toggleDeletePrompt(null));
        dispatch(updateState({
            name: "actionMessage",
            value: "Deleting..."
        }))

        const endpoint = config.url.BACKEND_API_URL + "/packages/delete/" + packageItemInAction?._id;
        const headers = { "auth-token": cookies.get("auth_token") };

        try {
            
            await axios.delete(endpoint, { headers });
            dispatch(updateState({
                name: "actionMessage",
                value: "Successfully Deleted"
            }))

            dispatch({ 
                type: FETCH_PACKAGE_DATA, 
                payload: { 
                    searchText,
                    skip,
                    limit,
                    fetchTreatmentData: false
                } 
            })

        } catch(error) {
            const { message } = handleApiError(error);
            dispatch(updateState({
                name: "actionMessage",
                value: message
            }));
        }

    }
    
  
    return (
        <DeletePrompt 
            warningText={<p>Are you sure want to delete <span>{packageItemInAction?.title}</span></p>}
            closeDeletePrompt={closeDeletePrompt}
            deleteItem={deletePackage}
        />
    );

}


export default DeletePackage;