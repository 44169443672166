import { IUser } from "../../types/models/User";
import styles from "./Button.module.css";


interface IProps {
    customClassName?: string,
    buttonText: string,
    disabled?: boolean,
    icon: React.ReactNode, 
    handleClick: (args: { actionType: string, user: IUser}) => void, 
    args?: any, 
}


const IconButton: React.FC<IProps> = (props) => {

    const { 
        customClassName = "", 
        buttonText,
        disabled = false,
        icon, 
        handleClick, 
        args 
    } = props;

    return (
        <button
            className = {`${styles.icon_button} ${styles[customClassName]}`}
            disabled = {disabled}
            onClick = {() => handleClick(args)}
        >
            {icon}
            <span>{buttonText}</span>
        </button>
    );

}


export default IconButton;