import { ClientItemExtended } from "../../types/pages/Report";
import { formatDateTime } from "../date-time/dateTimeHelpers";

export const getClientCSVContent = (clientList: ClientItemExtended[]) => {

    const headers = "Full Name, Email, Phone Number, Last Visit Date, First Visit Date, Bookings, Cancelled";
 
    const data = clientList.map(client => {

        const { 
            firstVisitDate,
            lastVisitDate,
            name,
            email,
            phone,
            totalAppointmentCount,
            cancelledAppointmentCount
        } = client;

        const { 
            day: firstVisitDay, 
            month: firstVisitMonth, 
            year: firstVisitYear 
        } = formatDateTime(new Date(firstVisitDate * 1000));

        const { 
            day: lastVisitDay, 
            month: lastVisitMonth, 
            year: lastVisitYear 
        } = formatDateTime(new Date(lastVisitDate * 1000));
        
        const lastVisit = `${lastVisitDay} ${lastVisitMonth} ${lastVisitYear}`;
        const firstVisit = `${firstVisitDay} ${firstVisitMonth} ${firstVisitYear}`;

        return `${name}, ${email}, ${phone}, ${lastVisit}, ${firstVisit}, ${totalAppointmentCount}, ${cancelledAppointmentCount}`;

    });

    return `${headers}\n${data.join("\n")}`;

}