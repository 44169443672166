import styles from "./index.module.css";

interface ClientPackageItemInfoProps {
    title: string
    content: string
}

const ClientPackageItemInfo: React.FC<ClientPackageItemInfoProps> = ({ title, content }) => {

    return (
        <div className={styles.pkg_item_info}>
            <p>{title}</p>
            <p>{content}</p>
        </div>
    );

}

export default ClientPackageItemInfo;