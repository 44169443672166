import { useSelector, useDispatch } from "react-redux";
import { selectSubPaymentMethod } from "../../../store/profile-tenant/tenantProfileReducer";
import { RootState } from "../../../store";
import styles from "./StripeElements.module.css";


const SelectOptions: React.FC = () => {

    const dispatch = useDispatch();
    const tenantProfileState = useSelector((state: RootState) => state.tenantProfileState);
    const { selectedPaymentMethod } = tenantProfileState;

    const handleClick = (method: string): void => {
        dispatch(selectSubPaymentMethod(method));
    }

    return (
        <div className = {styles.select_options}>
            <button
                onClick = {() => handleClick("Credit Card")}
                style = {{
                    backgroundColor: selectedPaymentMethod === "Credit Card" ? "#A8DDE6" : "white"
                }}
            >
                Credit Card
            </button>
            <button
                onClick = {() => handleClick("Sepa Debit")}
                style = {{
                    backgroundColor: selectedPaymentMethod === "Sepa Debit" ? "#A8DDE6" : "white"
                }}
            >
                Sepa Debit
            </button>
        </div>
    );

}


export default SelectOptions;