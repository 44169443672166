import axios from "axios";
import Cookies from "universal-cookie";
import { FetchDataQueryParams, FetchedData } from "../../../types/pages/Package";
import { config } from "../../../Constants";


export const fetchPackageApiData = async(payload: FetchDataQueryParams) => {

    const { 
        searchText,
        skip, 
        limit,
        fetchTreatmentData
    } = payload;

    const params1 = `searchText=${searchText}&skip=${skip}`;
    const params2 = `&limit=${limit}&fetchTreatmentData=${fetchTreatmentData}`;
    const queryParams = params1 + params2;

    const cookies = new Cookies();

    const headers = { "auth-token": cookies.get("auth_token") };

    const endpoint = config.url.BACKEND_API_URL + `/packages?${queryParams}`;
  
    const response = await axios.get(endpoint, { headers });

    const data: FetchedData = response.data;

    return data;

}