import styles from "./AddEditUser.module.css";

interface SelectButtonsProps {
    isTenant: boolean
    isDefiningAccess: boolean,
    setIsDefiningAccess: React.Dispatch<React.SetStateAction<boolean>>
}

const SelectButtons: React.FC<SelectButtonsProps> = ({ isTenant, isDefiningAccess, setIsDefiningAccess }) => {

    const handleClick = () => {
        if(isTenant) return;
        setIsDefiningAccess(!isDefiningAccess)
    }

    return (
        <div className = {styles.select_buttons}>
            <button 
                onClick={handleClick}
                style = {{
                    borderBottom: !isDefiningAccess ? "4px solid #FFAAA5" : "none",
                    color: !isDefiningAccess ? "#FFAAA5" : "#A8DDE6"
                }}
            >
                Personal Info
            </button>
            <button 
                disabled={isTenant}
                onClick={handleClick}
                style = {{
                    borderBottom: isDefiningAccess ? "4px solid #FFAAA5" : "none",
                    color: isTenant ? "lightgray" : isDefiningAccess ? "#FFAAA5" : "#A8DDE6"
                }}
            >
                Personal Access
            </button>
        </div>
    );

}

export default SelectButtons;