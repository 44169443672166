import NavMyProfileIcon from "../components/icons/NavMyProfileIcon";
import NavItem from "./NavItem";

interface IProps {
    myProfileUrl: string
    currentUrl: string
}


const MyProfileNavItem: React.FC<IProps> = ({ myProfileUrl, currentUrl }) => {

    return (
        <>
            <NavItem 
                icon = {<NavMyProfileIcon />}
                linkText = "My Profile"
                linkHref = {myProfileUrl}
                style = {{ 
                    color: currentUrl === myProfileUrl ? "red" : "black" 
                }}
            />
        </>
    );

}

export default MyProfileNavItem;