import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { addNewAppointmentInfo } from "../../../store/calendar/calendarReducer";
import styles from "./AddAppointmentModal.module.css";
import Dropdown from "../../../components/common/dropdown/Dropdown";


const SelectItem: React.FC = () => {
    
    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const {
        locationList, 
        categoryList,
        treatmentList,
        stylistList,
        newAppointmentInfo,
        validationError,
        isBookingAppointmentForSearchedClient
    } = calendarState;
    const { 
        selectedLocation,
        selectedCategory,
        selectedTreatment,
        selectedStylist 
    } = newAppointmentInfo;


    const handleDropdownClick = (args: any) => {
        const { item, dropdownListFor } = args;
        dispatch(addNewAppointmentInfo({
            name: dropdownListFor,
            value: item
        }));

        if(dropdownListFor === "selectedCategory" && item._id !== selectedCategory?._id && selectedTreatment) {
            dispatch(addNewAppointmentInfo({
                name: "selectedTreatment",
                value: null
            }));
        }
    }

    const filteredTreatmentList = treatmentList.filter(item => item.category.title === selectedCategory?.title)

   

    return (
        <>
            <div className = {styles.select_location}>
                <Dropdown 
                    dropdownLabel="Select Location"
                    dropdownListFor="selectedLocation"
                    data = {locationList} 
                    nameKey = "title" 
                    selectedValue = {selectedLocation ? selectedLocation.title : ""} 
                    clickHandler = {handleDropdownClick}
                    validationErrorMessage = {(validationError && !selectedLocation) ? "please select a location" : ""}
                />
            </div>
            <div className = {styles.select_category}>
                <Dropdown 
                    dropdownLabel="Select Category"
                    dropdownListFor="selectedCategory"
                    data = {categoryList} 
                    nameKey = "title" 
                    selectedValue = {selectedCategory ? selectedCategory.title : ""} 
                    clickHandler = {handleDropdownClick}
                    validationErrorMessage = {(validationError && !selectedCategory) ? "please select a category" : ""}
                />
            </div>
            <div className = {styles.select_treatment}>
                <Dropdown 
                    dropdownLabel="Select Treatment"
                    dropdownListFor="selectedTreatment"
                    data = {filteredTreatmentList} 
                    nameKey = "title" 
                    selectedValue = {selectedTreatment ? selectedTreatment.title : ""} 
                    clickHandler = {handleDropdownClick}
                    validationErrorMessage = {(validationError && !selectedTreatment) ? "please select a treatment" : ""}
                />
            </div>
            {
                (isBookingAppointmentForSearchedClient && selectedTreatment) 
                ?
                <div className = {styles.select_treatment}>
                    <Dropdown 
                        dropdownLabel="Select Stylist"
                        dropdownListFor="selectedStylist"
                        data = {stylistList} 
                        nameKey = "fullName" 
                        selectedValue = {selectedStylist ? selectedStylist.fullName : ""} 
                        clickHandler = {handleDropdownClick}
                        validationErrorMessage = {(validationError && !selectedStylist) ? "please select a stylist" : ""}
                    />
                </div>
                :
                null
            }
        </>
    );

}

export default SelectItem;