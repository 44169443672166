import { IUser } from "../../types/models/User";
import { userAccessTypeValues } from "../../data/users";
import CalendarIcon from "../../components/icons/CalendarIcon";
import NavLocationIcon from "../../components/icons/NavLocationIcon";
import NavUsersIcon from "../../components/icons/NavUsersIcon";
import NavTreatmentsIcon from "../../components/icons/NavTreatmentsIcon";
import CouponIcon from "../../components/icons/CouponIcon";
import DiscountIcon from "../../components/icons/DiscountIcon";
import PackageIcon from "../../components/icons/PackageIcon";
import ReportIcon from "../../components/icons/ReportIcon";
import ProductsIcon from "../../components/icons/ProductsIcon";
import GiftCardIcon from "../../components/icons/GiftCardIcon";
import WaitListIcon from "../../components/icons/WaitListIcon";
import EmailIcon from "../../components/icons/EmailIcon";
import DashboardIcon from "../../components/icons/DashboardIcon";

export interface NavBarItem {
    _id: number,
    url: string,
    text: string,
    icon: JSX.Element,
    allowedUserTypes: string[],
    allowedSubscriptionPlanIds: number[]
}

export const getSideNavItems = (user: IUser | null): NavBarItem[] => {

    if(user) {

        const { userType, isOnTrialPeriod, stripeSubscription } = user;

        const defaulSideNavItems = [
            {
                _id: 1,
                url: `/user/${user.tenantId}/calendar`,
                text: "Calendar",
                icon: CalendarIcon(),
                allowedUserTypes: ["Tenant", "Admin", "Stylist"],
                allowedSubscriptionPlanIds: [100, 101, 102, 103]
            },
            {
                _id: 2,
                url: `/user/${user.tenantId}/user-list`,
                text: "Users",
                icon: NavUsersIcon(),
                allowedUserTypes: ["Tenant"],
                allowedSubscriptionPlanIds: [100, 101, 102, 103]
            },
            {
                _id: 3,
                url: `/user/${user.tenantId}/appointment-client-list`,
                text: "Clients",
                icon: NavUsersIcon(),
                allowedUserTypes: ["Tenant", "Admin", "Stylist"],
                allowedSubscriptionPlanIds: [100, 101, 102, 103]
            },
            {
                _id: 4,
                url: `/user/${user.tenantId}/location-list`,
                text: "Locations",
                icon: NavLocationIcon(),
                allowedUserTypes: ["Tenant"],
                allowedSubscriptionPlanIds: [100, 101, 102, 103]
            },
            {
                _id: 5,
                url: `/user/${user.tenantId}/integrations`,
                text: "Integrations",
                icon: DashboardIcon(),
                allowedUserTypes: ["Tenant"],
                allowedSubscriptionPlanIds: [100, 101, 102, 103]
            }
        ];

        if(user.accessTypeList.includes(userAccessTypeValues.maintainEmails)) {
            defaulSideNavItems.push({
                _id: defaulSideNavItems.length + 1,
                url: `/user/${user.tenantId}/emails`,
                text: "Emails",
                icon: EmailIcon(),
                allowedUserTypes: ["Tenant", "Admin", "Stylist"],
                allowedSubscriptionPlanIds: [100, 101, 102, 103]
            })
        }

        if(user.accessTypeList.includes(userAccessTypeValues.maintainProducts)) {
            defaulSideNavItems.push({
                _id: defaulSideNavItems.length + 1,
                url: `/user/${user.tenantId}/product-list`,
                text: "Products",
                icon: ProductsIcon(),
                allowedUserTypes: ["Tenant", "Admin", "Stylist"],
                allowedSubscriptionPlanIds: [100, 101, 102, 103]
            })
        }

        if(user.accessTypeList.includes(userAccessTypeValues.maintainTreatments)) {
            defaulSideNavItems.push({
                _id: defaulSideNavItems.length + 1,
                url: `/user/${user.tenantId}/treatment-list`,
                text: "Treatments",
                icon: NavTreatmentsIcon(),
                allowedUserTypes: ["Tenant", "Admin", "Stylist"],
                allowedSubscriptionPlanIds: [100, 101, 102, 103]
            })
        }

        if(user.accessTypeList.includes(userAccessTypeValues.maintainCoupons)) {
            defaulSideNavItems.push({
                _id: defaulSideNavItems.length + 1,
                url: `/user/${user.tenantId}/coupon-list`,
                text: "Coupons",
                icon: CouponIcon(),
                allowedUserTypes: ["Tenant", "Admin", "Stylist"],
                allowedSubscriptionPlanIds: [100, 101, 102, 103]
            })
        }

        if(user.accessTypeList.includes(userAccessTypeValues.maintainDiscounts)) {
            defaulSideNavItems.push({
                _id: defaulSideNavItems.length + 1,
                url: `/user/${user.tenantId}/discount-list`,
                text: "Discounts",
                icon: DiscountIcon(),
                allowedUserTypes: ["Tenant", "Admin", "Stylist"],
                allowedSubscriptionPlanIds: [100, 101, 102, 103]
            })
        }

        if(user.accessTypeList.includes(userAccessTypeValues.maintainPackages)) {
            defaulSideNavItems.push({
                _id: defaulSideNavItems.length + 1,
                url: `/user/${user.tenantId}/package-list`,
                text: "Packages",
                icon: PackageIcon(),
                allowedUserTypes: ["Tenant", "Admin", "Stylist"],
                allowedSubscriptionPlanIds: [100, 101, 102, 103]
            })
        }


        if(user.accessTypeList.includes(userAccessTypeValues.maintainReports)) {
            defaulSideNavItems.push({
                _id: defaulSideNavItems.length + 1,
                url: `/user/${user.tenantId}/reports`,
                text: "Reports",
                icon: ReportIcon(),
                allowedUserTypes: ["Tenant", "Admin", "Stylist"],
                allowedSubscriptionPlanIds: [100, 101, 102, 103]
            })
        }

        if(user.accessTypeList.includes(userAccessTypeValues.maintainGiftCards)) {
            defaulSideNavItems.push({
                _id: defaulSideNavItems.length + 1,
                url: `/user/${user.tenantId}/gift-card-list`,
                text: "Gift Cards",
                icon: GiftCardIcon(),
                allowedUserTypes: ["Tenant", "Admin", "Stylist"],
                allowedSubscriptionPlanIds: [100, 101, 102, 103]
            })
        }

        if(user.accessTypeList.includes(userAccessTypeValues.maintainWaitingList)) {
            defaulSideNavItems.push({
                _id: defaulSideNavItems.length + 1,
                url: `/user/${user.tenantId}/wait-list`,
                text: "Waiting List",
                icon: WaitListIcon(),
                allowedUserTypes: ["Tenant", "Admin", "Stylist"],
                allowedSubscriptionPlanIds: [100, 101, 102, 103]
            })
        }



        // Trial period will give user access to the most advanced plan. So, only the userType checks should be implemented.
        if(isOnTrialPeriod) {
            const filteredSideNavItems = defaulSideNavItems.filter(item => item.allowedUserTypes.includes(userType));
            return filteredSideNavItems;
        }

        const userSubPlan = stripeSubscription?.subscriptionPlan;

        // Some features(follow up emails for example) are not available to all subscription plan. So need to check that.
        if(userSubPlan) {

            const { subscriptionId } = userSubPlan;

            const filteredSideNavItems = defaulSideNavItems.filter(item => {

                const { allowedUserTypes, allowedSubscriptionPlanIds } = item;

                const isUserTypeIncluded = allowedUserTypes.includes(userType);
                const isSubscriptionIdIncluded = allowedSubscriptionPlanIds.includes(subscriptionId);

                return isUserTypeIncluded && isSubscriptionIdIncluded;

            });

            return filteredSideNavItems;

        }

        return [];

    }

    return [];
    
}