import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import { config } from "../../Constants";
import { RootState } from "../../store";
import { FETCH_LOCATION_DATA } from "../../store/locations/constants";
import { 
    addNewLocationInfo, 
    closeAddLocationModal, 
    closeEditLocationModal,
    updateActionMessage 
} from "../../store/locations/locationReducer";
import useWindowWidth from "../../hooks/useWindowWidth";
import { Modal, ModalBackground, ModalContent } from "../../components/common/Modal";
import { trimUserInputObject } from "../../helpers/others/trimUserInputObject";
import styles from "./index.module.css";
import InputField from "./InputField";
import Button from "./Button";


const AddEditLocation = () => {

    const cookies = new Cookies();

    const dispatch = useDispatch();
    const locationState = useSelector((state: RootState) => state.locationState);
    const userState = useSelector((state: RootState) => state.userState);
    const { devicesWidth: { isLargeDevice } } = useWindowWidth();
    const { 
        newLocationInfo, 
        validationError, 
        isAdding, 
        isEditing, 
        locationItemInAction,
        locationDataFetchInfo 
    } = locationState;
    const { title, address, city, country, phone, zipCode } = newLocationInfo;
    const { searchText, skip, limit } = locationDataFetchInfo;

    const closeModal = () => {
        if(isAdding) {
            dispatch(closeAddLocationModal());
            return;
        }

        dispatch(closeEditLocationModal());
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        dispatch(addNewLocationInfo({ name, value }));
    }

    const saveLocation = async() => {

        dispatch(closeAddLocationModal());

        let endpoint = config.url.BACKEND_API_URL + "/locations/create";
        let actionMessage = "Creating...";

        if(isEditing) {
            endpoint = config.url.BACKEND_API_URL + "/locations/edit";
            actionMessage = "Updating...";
            dispatch(closeEditLocationModal());
        } 

        dispatch(updateActionMessage(actionMessage));

        const locationInfo = trimUserInputObject(newLocationInfo);

        const headers = { "auth-token": cookies.get("auth_token") };
        const requestBody = {
            locationId: locationItemInAction?._id,
            ...locationInfo
        }

        try {

            const response = await axios.post(endpoint, requestBody, { headers });

            dispatch(updateActionMessage(response.data));
            
            dispatch({ 
                type: FETCH_LOCATION_DATA, 
                payload: { 
                    tenantId: userState.user?.tenantId,
                    searchText,
                    skip,
                    limit,
                } 
            })

        } catch(error) {
            dispatch(updateActionMessage("Something went wrong"));
        }
    
    }

  
    return (
        <Modal>
            <ModalBackground />
            <ModalContent 
                width = {isLargeDevice ? "45%" : "95%"}
                height="90%"
            >
                <Button 
                    customClassName="modal_cls_btn"
                    buttonText="X"
                    handleClick={closeModal}
                />
                <div className = {styles.add_edit_location}>
                    <h1>{isAdding ? "Add Location" : "Edit Location"}</h1>
                    <InputField 
                        labelText="Title"
                        type = "text"
                        name = "title"
                        value = {title}
                        handleChange = {handleChange}
                        validationError = {validationError}
                        validationErrorMessage="title can't be blank"
                    />
                    <InputField 
                        labelText="Address"
                        type = "text"
                        name = "address"
                        value = {address}
                        handleChange = {handleChange}
                        validationError = {validationError}
                        validationErrorMessage="address can't be blank"
                    />
                    <InputField 
                        labelText="Phone"
                        type = "text"
                        name = "phone"
                        value = {phone}
                        handleChange = {handleChange}
                        validationError = {validationError}
                        validationErrorMessage="phone can't be blank"
                    />
                    <InputField 
                        labelText="City"
                        type = "text"
                        name = "city"
                        value = {city}
                        handleChange = {handleChange}
                        validationError = {validationError}
                        validationErrorMessage="city can't be blank"
                    />
                    <InputField 
                        labelText="Country"
                        type = "text"
                        name = "country"
                        value = {country}
                        handleChange = {handleChange}
                        validationError = {validationError}
                        validationErrorMessage="country can't be blank"
                    />
                    <InputField 
                        labelText="Zip Code"
                        type = "text"
                        name = "zipCode"
                        value = {zipCode}
                        handleChange = {handleChange}
                        validationError = {validationError}
                        validationErrorMessage="zip code can't be blank"
                    />
                    <div className={styles.save_btn_wrapper}>
                        <Button 
                            customClassName="save_btn"
                            buttonText="Save"
                            handleClick={saveLocation}
                        />
                    </div>
                </div>
            </ModalContent>
        </Modal>
    );

}


export default AddEditLocation;