import axios from "axios";
import Cookies from "universal-cookie";
import { FetchedUserData, FetchDataQueryParams } from "../../../types/pages/UserList";
import { config } from "../../../Constants";
const cookies = new Cookies();


export const fetchUserListData = async(payload: FetchDataQueryParams) => {

    const { tenantId, searchText, skip, limit, fetchLocationData } = payload;

    const params1 = `tenantId=${tenantId}&searchText=${searchText}&skip=${skip}&`;
    const params2 = `limit=${limit}&fetchLocationData=${fetchLocationData}`

    const queryParams = params1 + params2;

    const headers = { "auth-token": cookies.get("auth_token") };

    const endpoint = config.url.BACKEND_API_URL + `/users?${queryParams}`;
  
    const response = await axios.get(endpoint, { headers });

    const data: FetchedUserData = response.data;

    return data;

}