import styles from "./InputFields.module.css";


interface InputFieldProps {
    customClassName?: string,
    labelText?: string,
    type: string, 
    name: string, 
    placeholder?: string,
    autoComplete?: string
    disabled?: boolean
    value: string, 
    validationError?: boolean,
    validationErrorMessage?: string,
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}


const InputField: React.FC<InputFieldProps> = (props) => {

    const {
        customClassName = "",
        labelText,
        type, 
        name, 
        placeholder, 
        autoComplete,
        disabled = false,
        value, 
        validationError,
        validationErrorMessage,
        handleChange
    } = props;


    return (
        <div className = {`${styles.input_field} ${styles[customClassName]}`}>
            {labelText && <label>{labelText}</label>}
            <div className = {styles.input}>
                <input
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    autoComplete={autoComplete}
                    disabled={disabled}
                    value={value}
                    onChange={handleChange}
                />
            </div>
            {(validationError && !value) && <p>{validationErrorMessage}</p>}
        </div>
    );

}


export default InputField;