import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { FETCH_APPOINTMENT_DATA } from "../../../store/calendar/constants";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { updateState } from "../../../store/calendar/calendarReducer";
import styles from "./CalendarHeader.module.css";

const ViewNavigation: React.FC = () => {

    const { windowWidth } = useWindowWidth();
    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { calendarViewType } = calendarState;

    const handleClick = (newViewType: string) => {

        dispatch(updateState({
            name: "calendarViewType",
            value: newViewType
        }));

        setTimeout(() => {
            dispatch({ type: FETCH_APPOINTMENT_DATA })
        }, 10)
        
    }

    const isMonthView = calendarViewType === "month";
    const isWeekView = calendarViewType === "week";
    const isDayView = calendarViewType === "day";

    return (  
        <div className={styles.view_navigation}>
            {
                windowWidth >= 1000
                ?
                <button
                    onClick= {() => handleClick("month")}
                    style={{
                        backgroundColor: isMonthView ? "darkblue" : "white",
                        color: isMonthView ? "white" : "black"
                    }}
                >
                    Month View
                </button>
                :
                null
            }
            <button
                onClick={() => handleClick("week")}
                style={{
                    backgroundColor: isWeekView ? "darkblue" : "white",
                    color: isWeekView ? "white" : "black"
                }}
            >
                Week View
            </button>
            <button
                onClick={() => handleClick("day")}
                style={{
                    backgroundColor: isDayView ? "darkblue" : "white",
                    color: isDayView ? "white" : "black"
                }}
            >
                Day View
            </button>
        </div>
    );

}

export default ViewNavigation;