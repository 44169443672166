import { call, put, takeLatest } from "redux-saga/effects";
import { FETCH_COUPON_DATA } from "../store/coupons/constants";
import { FetchDataQueryParams, FetchedData } from "../types/pages/Coupon";
import { 
    fetchDataFail,
    fetchDataLoading,
    fetchDataSuccess
} from "../store/coupons/couponReducer";
import { fetchData } from "./api/coupons/fetchData";


function* handleFetchData(action: { type: string, payload: FetchDataQueryParams }) {

    try {

        yield put(fetchDataLoading())
        const result: FetchedData = yield call(fetchData, action.payload);
        yield put(fetchDataSuccess(result))

    } catch(error) {
        yield put(fetchDataFail())
    }

}


export default function* couponSaga() {
    yield takeLatest(FETCH_COUPON_DATA, handleFetchData);
}
