import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import { RootState } from "../../store";
import { config } from "../../Constants";
import { handleChange, toggleDeletePrompt } from "../../store/discounts/discountReducer";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import DeletePrompt from "../../components/common/prompts/DeletePrompt";


const DeleteDiscountPrompt: React.FC = () => {

    const cookies = new Cookies();

    const dispatch = useDispatch();
    const discountState = useSelector((state: RootState) => state.discountState);
    const { discountList, discountId } = discountState;

    const togglePrompt = () => dispatch(toggleDeletePrompt(null));

    const deleteDiscount = async () => {

        const newDiscountId = discountId;

        dispatch(toggleDeletePrompt(null));

        dispatch(handleChange({
            name: "actionMessage",
            value: "Deleting...."
        }));

        const endpoint = config.url.BACKEND_API_URL + "/discounts/delete/" + newDiscountId;
        const axiosConfig = {
            headers: {
                "auth-token": cookies.get("auth_token")
            }
        };

        try {

            await axios.delete(endpoint, axiosConfig);

            const newDiscountList = discountList.filter(item => item._id !== newDiscountId);
            dispatch(handleChange({
                name: "discountList",
                value: newDiscountList
            }));

            dispatch(handleChange({
                name: "actionMessage",
                value: "Deleted Successfully"
            }));

        } catch(error) {
            const { message } = handleApiError(error);
            dispatch(handleChange({
                name: "actionMessage",
                value: message
            }));
        }

    }

    return (
        <DeletePrompt
            warningText = {<p>Are you sure want to delete?</p>}
            closeDeletePrompt={togglePrompt}
            deleteItem = {deleteDiscount}
        />
    );

}

export default DeleteDiscountPrompt;