import { useSelector } from "react-redux";
import { RootState } from "../../store";
import styles from "./index.module.css";
import InputField from "./InputField";
import TextAreaInputField from "./TextAreaInputField";

interface ReceiverInfoProps {
    handleChange: (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void
}

const ReceiverInfo: React.FC<ReceiverInfoProps> = ({ handleChange }) => {

    const giftCardState = useSelector((state: RootState) => state.giftCardState);
    const { giftCardReceiver } = giftCardState;
    const { fullName, email, phone, address, specialNote } = giftCardReceiver;

    return (
        <div className={styles.receiver_info}>
            <div className={styles.receiver_info_header}>
                <h3>Receiver Info <span>(please only fill if you want to send this gift card to somebody else)</span></h3>
            </div>
            <InputField 
                labelText="Full Name"
                datatype="receiver"
                type="text"
                name="fullName"
                value={fullName}
                handleChange={handleChange}
            />
            <div className={styles.email_phone}>
                <InputField 
                    customClassName="email_phone_input"
                    labelText="Email Address"
                    datatype="receiver"
                    type="email"
                    name="email"
                    value={email}
                    handleChange={handleChange}
                />
                <InputField 
                    customClassName="email_phone_input"
                    labelText="Phone"
                    datatype="receiver"
                    type="text"
                    name="phone"
                    value={phone}
                    handleChange={handleChange}
                />
            </div>
            <InputField 
                labelText="Address"
                datatype="receiver"
                type="text"
                name="address"
                value={address}
                handleChange={handleChange}
            />
            <TextAreaInputField 
                datatype="receiver"
                rows={5}
                columns={20}
                labelText="Special Note"
                name="specialNote"
                value={specialNote}
                handleChange={handleChange}
            />
        </div>
    );
}


export default ReceiverInfo;
