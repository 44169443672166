import { IUser } from "../types/models/User";
import UserAvatarImage from "../assets/images/stylist_avatar.png";
import styles from "./TopNav.module.css";

interface ResponsiveProfileShortcutProps {
    user: IUser | null
    toggleProfileMenu?: () => void
}

const ResponsiveProfileShortcut: React.FC<ResponsiveProfileShortcutProps> = ({ user, toggleProfileMenu }) => {

    const imageSrc = user?.imageUrl ?? UserAvatarImage;
    
    return (
        <div className = {styles.user_image} onClick={toggleProfileMenu}>
            <img 
                src={imageSrc}
                alt="user name"
            />
        </div>
    );

}

export default ResponsiveProfileShortcut;