import styles from "./index.module.css";
import IntegrationItemInfo from "./IntegrationItemInfo";
import IntegrationItemLogo from "./IntegrationItemLogo";
import IntegrationItemButton from "./IntegrationItemButton";

interface IntegrationItemProps {
    windowWidth: number
    header: string
    description: string
    logo: React.ReactNode
    buttonText: string
    args?: any
    disabled?: boolean
    onClick: (args?: any) => void
}

const IntegrationItem: React.FC<IntegrationItemProps> = (props) => {

    const {
        windowWidth,
        header,
        description,
        logo,
        buttonText,
        args,
        disabled = false,
        onClick
     } = props;

    return (
        <div className={styles.integration_item}>
            {
                windowWidth > 1000
                ?
                <>
                    <div className={styles.integration_item_info}>
                        <IntegrationItemLogo 
                            logo={logo}
                        />
                        <IntegrationItemInfo 
                            header={header}
                            description={description}
                        />
                    </div>
                    <IntegrationItemButton 
                        buttonText={buttonText}
                        args={args}
                        disabled={disabled}
                        onClick={onClick}
                    />
                </>
                :
                <div className={styles.responsive_integration_item}>
                    <div className={styles.responsive_logo_button}>
                        <IntegrationItemLogo 
                            logo={logo}
                        />
                        <IntegrationItemButton 
                            buttonText={buttonText}
                            args={args}
                            disabled={disabled}
                            onClick={onClick}
                        />
                    </div>
                    <IntegrationItemInfo 
                        header={header}
                        description={description}
                    />
                </div>
            }
        </div>
    );

}

export default IntegrationItem;