import axios from "axios";
import Cookies from "universal-cookie";
import { FetchDataQueryParams, FetchedData } from "../../../types/pages/Treatment";
import { config } from "../../../Constants";


export const fetchData = async(payload: FetchDataQueryParams) => {

    const { 
        tenantId,
        searchText,
        skip, 
        limit,
        fetchCategoryAndStylistData
    } = payload;

    const params1 = `tenantId=${tenantId}&searchText=${searchText}&skip=${skip}`;
    const params2 = `&limit=${limit}&fetchCategoryAndStylistData=${fetchCategoryAndStylistData}`;
    const queryParams = params1 + params2

    const cookies = new Cookies();

    const headers = { "auth-token": cookies.get("auth_token") };

    const endpoint = config.url.BACKEND_API_URL + `/treatments?${queryParams}`;
  
    const response = await axios.get(endpoint, { headers });

    const data: FetchedData = response.data;

    return data;

}