import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import useWindowWidth from "../../hooks/useWindowWidth";
import { RootState } from "../../store";
import { config } from "../../Constants";
import { 
    updateState, 
    openEditFollowUpEmailModal,
    addNewFollowUpEmailInfo,
    toggleFollowUpEmailDeletePrompt
} from "../../store/emails/emailReducer";
import { FETCH_FOLLOW_UP_EMAIL_DATA } from "../../store/emails/constants";
import { getAuthTokenConfig } from "../../helpers/others/getAuthTokenConfig";
import styles from "./index.module.css";
import InputField from "../../components/common/input-fields/InputField";
import DropdownList from "./DropdownList";
import TableMessageTR from "./TableMessageTR";
import ResponsiveDataTable from "./ResponsiveDataTable";
import ActionButtons from "./ActionButtons";
import NextPrevView from "./NextPrevView";

const DataTable = () => {

    const { devicesWidth: { isLargeDevice } } = useWindowWidth();

    const dispatch = useDispatch();
    const emailState = useSelector((state: RootState) => state.emailState);
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;
    const { 
        loading,
        locationList,
        followUpEmailList,
        totalFollowUpEmailCount,
        searchText,
        skip, 
        limit 
    } = emailState;

    const redirectToMailchimp = async() => {

        const endpoint = config.url.BACKEND_API_URL + "/emails/credentials";
        const authConfig = getAuthTokenConfig();

        try {

            const response = await axios.get(endpoint, authConfig);
            const { clientId, clientSecret } = response.data;

            if(!clientId || !clientSecret) return;

            const url = "https://login.mailchimp.com/oauth2/authorize?";
            const params = `response_type=code&client_id=${clientId}&client_secret=${clientSecret}`;
            window.location.replace(url + params);

        } catch(error) {
            alert("Something went wrong");
            console.log(error);
        }

    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        dispatch(updateState({ name, value }));
    }

    const handleDropdownChange = (viewItem: { _id: number, limit: number }) => {

        if(totalFollowUpEmailCount <= 10 && searchText === "") return;

        dispatch(updateState({
            name: "limit",
            value: viewItem.limit
        }));
        dispatch(updateState({
            name: "skip",
            value: 0
        }));
        dispatch(updateState({
            name: "searchText",
            value: ""
        }));

        fetchData();

    }

    const handleClick = (actionType: string, followUpEmailId: string ) => {

        if(actionType === "add") {

            dispatch(updateState({
                name: "isAdding",
                value: true
            }));

            // If there is just one location then SelectLocation component will be hidden, so
            // need to do auto select the only location
            if(locationList.length === 1) {
                dispatch(addNewFollowUpEmailInfo({
                    name: "locationList",
                    value: [locationList[0]._id]
                }))
            }

            return;

        }

        const followUpEmail = followUpEmailList.find(item => item._id === followUpEmailId);
        if(!followUpEmail) return;

        if(actionType === "edit") {
            dispatch(openEditFollowUpEmailModal(followUpEmail));
            return;
        }

        dispatch(toggleFollowUpEmailDeletePrompt(followUpEmail));
      
    }

    const fetchData = () => {
        setTimeout(() => {
            dispatch({ type: FETCH_FOLLOW_UP_EMAIL_DATA })
        }, 100)
    }

    const navigateToNextPrevProducts = (buttonClickType: string) => {

        if(buttonClickType === "next") {

            if(totalFollowUpEmailCount <= (skip + limit)) return;

            dispatch(updateState({
                name: "skip",
                value: skip + limit
            }));

            fetchData();

            return;

        }
        
        if(!skip) return;

        dispatch(updateState({
            name: "skip",
            value: skip - limit
        }));

        fetchData();
       
    }

    return (
        <div className={styles.data_table_container}>
            <div className={styles.data_table_header}>
                <div className={styles.search_add}>
                    <InputField
                        customClassName="search_input" 
                        name="searchText"
                        value={searchText}
                        placeholder="Search"
                        handleChange={handleChange}
                    />
                    <div className={styles.search_add_buttons}>
                        {
                            !(user?.mailchimpAccessToken && user?.mailchimpServerPrefix)
                            ?
                            <button onClick={redirectToMailchimp}>
                                Integrate Mailchimp
                            </button>
                            :
                            null
                        }
                        <button onClick={() => handleClick("add", "add")}>
                            Add New Email
                        </button>
                    </div>
                </div>
                <div className={styles.data_table_dropdown_container}>
                    <DropdownList 
                        customClassName="view_dropdown_list"
                        dropdownListFor="view_item"
                        data={[
                            { _id: 1, limit: 10 },
                            { _id: 2, limit: 20 },
                            { _id: 3, limit: 50 }
                        ]}
                        nameKey="limit"
                        selectedValue={limit}
                        clickHandler={handleDropdownChange}
                    />
                </div>
            </div>
            <div className={styles.data_table}> 
                {
                isLargeDevice
                ?
                <table>
                    <thead>
                        <tr className={styles.heading_tr}>
                            <th>Title</th>
                            <th>Follow Up Hours</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading
                            ?
                            <TableMessageTR 
                                colSpan={6}
                                message="Loading..."
                            />
                            :
                            followUpEmailList.length < 1
                            ?
                            <TableMessageTR 
                                colSpan={6}
                                message="No Emails To Show"
                            />
                            :
                            followUpEmailList.map(followUpEmail => {

                                const { _id, title, followUpHours } = followUpEmail;

                                return (
                                    <tr key={_id} className={styles.data_tr}>
                                        <td>{title}</td>
                                        <td>{followUpHours}</td>
                                        <td>
                                            <ActionButtons 
                                                itemId={_id}
                                                showButtonText={true}
                                                handleClick={handleClick}
                                            />
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
                :
                <ResponsiveDataTable
                    handleClick={handleClick}
                />
            }
            </div>
            <NextPrevView 
                skip={skip}
                limit={limit}
                totalItemCount={totalFollowUpEmailCount}
                onNextPrevButtonClick={navigateToNextPrevProducts}
            />
        </div>
    );

}

export default DataTable;
