import styles from "./StripeElements.module.css";


const Label: React.FC<{ labelText: string }> = ({ labelText }) => {

    return (
        <label className = {styles.element_label}>
            {labelText}
        </label>
    );

}


export default Label;