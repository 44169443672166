import { IbanElement } from "@stripe/react-stripe-js";


export const validateIBanElement = (elements: any) => {

    let message: string | null = null;

    const ibanPayElement: any = elements.getElement(IbanElement);

    // If account number hasn't been entered then ibanPayElement's _parent property will
    // contain a class named "StripeElement--empty". If this happens user must be alerted.
    const hasNotEnteredAccountNumber = ibanPayElement._parent.classList.contains("StripeElement--empty");

    if(hasNotEnteredAccountNumber) {
        message = "Please enter iban account number";
    } 

    return message;

}