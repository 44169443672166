import { useSelector, useDispatch } from "react-redux";
import { IUser } from "../../../types/models/User";
import { RootState } from "../../../store";
import { addNewAppointmentInfo, updateState } from "../../../store/calendar/calendarReducer";
import styles from "./SelectStylist.module.css";
import Dropdown from "../../../components/common/dropdown/Dropdown";
import StylistAvatar from "../../../assets/images/stylist_avatar.png"


const SelectStylist: React.FC = () => {

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { newAppointmentInfo, appointmentInAction } = calendarState;
    const { selectedStylist, selectedTreatment} = newAppointmentInfo;

    const selectStylist = (args: { item: IUser, dropdownListFor: string }) => {
        dispatch(addNewAppointmentInfo({
            name: "selectedStylist",
            value: args.item
        }));
        dispatch(updateState({
            name: "availableDateTimes",
            value: []
        }))
    }

    let newPrice: string | number = selectedTreatment?.price ?? "0.00";
    let priceSuperText = ".00";
    const priceStr = selectedTreatment?.price.toString() ?? "0.00";

    if(priceStr.includes(".")) {
        priceSuperText = priceStr.substring(priceStr.indexOf("."), priceStr.length);
        newPrice = priceStr.substring(0, priceStr.indexOf("."));
    }

    return (
        <div className = {styles.select_stylist}>
            
            <div className = {styles.select_stylist_card_wrapper}>
                <div className = {styles.select_stylist_card}>
                    <div className = {styles.select_stylist_card_content}>
                        <Dropdown
                            data = {selectedTreatment?.stylists ?? []}
                            nameKey = "fullName"
                            selectedValue = {selectedStylist?.fullName ?? ""}
                            clickHandler = {selectStylist}
                        />
                        <div className = {styles.stylist_image_wrapper}>
                            <img 
                                className = {styles.stylist_image}
                                src = {selectedStylist?.imageUrl ?? StylistAvatar}
                                alt = {selectedStylist?.fullName ?? ""}
                            />
                        </div>
                        <div className = {styles.appointment_info}>
                            <p>{appointmentInAction?.locationItem?.title}</p>
                            <p>{selectedTreatment?.title}</p>
                            <p className = {styles.price_text}>
                                €{newPrice}<sup>{priceSuperText}</sup>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        
        </div> 
    );

}


export default SelectStylist;