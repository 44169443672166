import { useSelector } from "react-redux";
import { RootState } from "../../store";
import styles from "./index.module.css";

const Confirmation: React.FC = () => {

    const giftCardState = useSelector((state: RootState) => state.giftCardState);
    const { giftCardInAction } = giftCardState;

    return (
        <div className={styles.confirmation}>
            <div className={styles.confirmation_content}>
                <h4>Your order is confirmed and the confirmation email is on it&lsquo;s way.</h4>
                <h4>Your gift card code is <span>{giftCardInAction?.code}</span>.</h4>
            </div>
        </div>
    );
}


export default Confirmation;