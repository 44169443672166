import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { config } from "../../Constants";
import { validateEmailAddress } from "../../helpers/validators/validateEmailAddress";
import { validatePassword } from "../../helpers/validators/validatePassword";
import { trimUserInputObject } from "../../helpers/others/trimUserInputObject";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import styles from "./Signup.module.css";
import LogoImage from "../../components/login-signup/LogoImage";
import DesignImage from "../../components/login-signup/DesignImage";
import ErrorMessage from "../../components/login-signup/ErrorMessage";
import SignupForm from "./SignupForm";


const Signup = () => {

    const [userInfo, setUserInfo] = useState<{ [key: string]: string }>({
        firstName: "",
        lastName: "",
        companyName: "",
        email: "",
        phone: "",
        password: "",
        password2: ""
    })
    const [validationError, setValidationError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [signupErrorMsg, setSignupErrorMsg] = useState<string>();
    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUserInfo(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        });
    }

    const handleUserSignup = async(event: SubmitEvent): Promise<void> => {

        event.preventDefault();

        const { firstName, lastName, companyName, email, phone, password, password2 } = userInfo;

        if(
            !firstName || 
            !lastName || 
            !companyName ||
            !email || 
            !phone || 
            !password || 
            !password2
        ) {
            setValidationError(true);
            return;
        } 

        // Email Validation
        const { isEmailValid, emailValMessage } = validateEmailAddress(email);
        if(!isEmailValid) {
            setSignupErrorMsg(emailValMessage);
            return;
        }

        // Password Validation
        const { isPassValid, passValMessage } = validatePassword(password, password2);
        if(!isPassValid) {
            setSignupErrorMsg(passValMessage);
            return;
        }

        setLoading(true);

        const endpoint = `${config.url.BACKEND_API_URL}/users/signup`;

        const requestBody = trimUserInputObject(userInfo);

        try {

            await axios.post(endpoint, requestBody);
            setLoading(false);
            setIsSuccess(true);

        } catch(error: any) {
            const { message } = handleApiError(error);
            setSignupErrorMsg(message);
            setLoading(false);
        }

    }

    return (
        <div className={styles.signup}>
            <div className={styles.user_credentials}>
                <LogoImage />
                <h2>Sign Up</h2>
                {
                    isSuccess
                    ?
                    <div className={styles.signup_success_msg}>
                        <p>Your account has been created successfully, you can sign in now.</p>
                        <Link to="/">
                            Sign in
                        </Link>
                    </div>
                    :
                    <>
                        <ErrorMessage 
                            message={signupErrorMsg ?? ""} 
                        />
                        <SignupForm
                            loading={loading}
                            userInfo={userInfo}
                            validationError={validationError}
                            handleChange={handleChange} 
                            handleUserSignup={handleUserSignup}
                        />
                        <div className={styles.have_account}>
                            <p>Already have an account?</p>
                            <Link to="/">
                                Sign in
                            </Link>
                        </div>
                    </>
                }
            </div>
            <DesignImage />
        </div>
    );

}

export default Signup;