import styles from "./TenantInfo.module.css";

type IProps = {
    header: string,
    content: string
}

const Info: React.FC<IProps> = ({ header, content }) => {

    return (
        <div className={styles.info_item}>
           <p className = {styles.info_header}>{header}:</p>
           <p className = {styles.info_content}>{content}</p>
        </div>
    );

}


export default Info;