import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import { RootState } from "../../../store";
import { config } from "../../../Constants";
import { updateState } from "../../../store/calendar/calendarReducer";
import { handleApiError } from "../../../helpers/error-handlers/handleApiError";
import styles from "./AppointmentModal.module.css";


const ResendEmailButton: React.FC = () => {

    const cookies = new Cookies();

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { appointmentInAction } = calendarState;


    const resendConfirmationEmailToClient = async() => {

        const endpoint = config.url.BACKEND_API_URL + "/appointments/resend-email";

        const requestBody = { appointmentId: appointmentInAction?._id };
        const headers = { "auth-token": cookies.get("auth_token") };

        dispatch(updateState({
            name: "appointmentInAction",
            value: null
        }));

        dispatch(updateState({
            name: "serverResponseMessage",
            value: "Resending email..."
        }));

        try {

            await axios.post(endpoint, requestBody, { headers });
            dispatch(updateState({
                name: "serverResponseMessage",
                value: "Resent email successfully"
            }));
            
        } catch(error) {
            const { message } = handleApiError(error);
            dispatch(updateState({
                name: "serverResponseMessage",
                value: message
            }));
        }

    }

    
    return (
        <button className = {styles.resend_email_button} onClick = {resendConfirmationEmailToClient}>
            Resend Email
        </button>
    );

}

export default ResendEmailButton;