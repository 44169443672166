import axios from "axios";
import Cookies from "universal-cookie";
import { IUser } from "../../../types/models/User";
import { useDispatch, useSelector } from "react-redux";
import { useStripe, useElements, CardNumberElement, IbanElement } from "@stripe/react-stripe-js";
import { Stripe, StripeElements, StripeCardNumberElement, StripeIbanElement } from "@stripe/stripe-js";
import { RootState } from "../../../store";
import { config } from "../../../Constants";
import { closeUpdatePaymentMethodModal } from "../../../store/profile-tenant/tenantProfileReducer";
import { validateCreditCard } from "../../../helpers/validators/validateCreditCard";
import { validateIBanElement } from "../../../helpers/validators/validateIBanElement";
import { handleApiError } from "../../../helpers/error-handlers/handleApiError";
import styles from "./UpdatePaymentMethod.module.css";
import Button from "../../../components/profile-tenant/Button";

interface ButtonDivProps {
    loading?: boolean
    setValidationErrorMsg: React.Dispatch<React.SetStateAction<string | undefined>>
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    setErrorMsg: React.Dispatch<React.SetStateAction<string>>
    setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>
}


const ButtonDiv: React.FC<ButtonDivProps> = (props) => {

    const { loading, setLoading, setValidationErrorMsg, setErrorMsg, setIsSuccess } = props;

    const stripe = useStripe() as Stripe;
    const elements = useElements() as StripeElements;
    const cookies = new Cookies();

    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.userState);
    const user = userState.user as IUser;
    const tenantProfileState = useSelector((state: RootState) => state.tenantProfileState);
    const { selectedPaymentMethod } = tenantProfileState;

    const closeModal = () => dispatch(closeUpdatePaymentMethodModal());

    const updateSubPaymentMethod = async(stripePaymentMethodId: string) => {

        const endpoint = config.url.BACKEND_API_URL + "/subscriptions/update-payment-method";
        const headers = { "auth-token": cookies.get("auth_token") };
        const requestBody = {
            tenantId: user.tenantId, 
            stripeCustomerId: user.stripeSubscription?.stripeCustomerId, 
            stripePaymentMethodId,
            selectedPaymentMethod
        }

        try {

            await axios.post(endpoint, requestBody, { headers });
            setLoading(false);
            setIsSuccess(true);

        } catch(error: any) {
            const { message } = handleApiError(error);
            setLoading(false);
            setErrorMsg(message);
        }

    }
    
    const handleUpdateSubPaymentMethod = async() => {

        setLoading(true);
        setValidationErrorMsg(undefined);

        if(selectedPaymentMethod === "Sepa Debit") {

            const message = validateIBanElement(elements);
            if(message) {
                setValidationErrorMsg(message);
                setLoading(false);
                return;
            }

            const ibanPaymentElement = elements.getElement(IbanElement) as StripeIbanElement;

            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: "sepa_debit",
                sepa_debit: ibanPaymentElement,
                billing_details: {
                    name: `${user.firstName} ${user.lastName}`,
                    email: user.email
                },
            });

            if(error) {
                setLoading(false);
                setErrorMsg(error.message ?? "Something went wrong, please try again later");
                return;
            } 

            updateSubPaymentMethod(paymentMethod.id);
            
        }

        if(selectedPaymentMethod === "Credit Card") {

            const message = validateCreditCard(elements);
            if(message) {
                setValidationErrorMsg(message);
                setLoading(false);
                return;
            }

            const creditCardNumberElement = elements.getElement(CardNumberElement) as StripeCardNumberElement;

            // confirmCardPayment api call will return either error or paymentIntent. error will be returned 
            // if user puts in invalid card details, for example - invalid card number, expiry date which is 
            // in the past or two digits CVC number.
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: "card",
                card: creditCardNumberElement,
                billing_details: {
                    name: user?.fullName,
                    email: user?.email
                }
            });

            if(error) {
                setLoading(false);
                setErrorMsg(error.message ?? "Something went wrong, please try again later");
                return;
            } 

            updateSubPaymentMethod(paymentMethod.id);

        }
      
    }

    return (
        <div className = {styles.button_div}>
            <Button
                border="1px solid #FFAAA5"
                color="#FFAAA5"
                marginRight = "20px"
                onClick = {closeModal}
            >
                Cancel
            </Button>
            <Button
                backgroundColor={loading ? "#ffa9a58c" : "#FFAAA5"}
                disabled={loading}
                onClick={handleUpdateSubPaymentMethod}
            >
                Update
            </Button>
        </div>
    );

}


export default ButtonDiv;