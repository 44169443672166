import { useDispatch } from "react-redux";
import { handleChange } from "../../store/discounts/discountReducer";
import { getOffPeakDiscountList } from "../../helpers/discounts/getOffPeakDiscountList";
import styles from "./index.module.css";


const AddDiscountButton: React.FC = () => {

    const dispatch = useDispatch();

    const handleClick = () => {
        const defaultOffPeakDiscountList = getOffPeakDiscountList();
        dispatch(handleChange({
            name: "offPeakDiscountList",
            value: defaultOffPeakDiscountList
        }));
        dispatch(handleChange({
            name: "isAddingUpdatingDiscount",
            value: true
        }));
    }
   
    return (
        <div className = {styles.add_discount_button}>
            <button className = {styles.button} onClick = {handleClick}>
                Add New Discount
            </button>
        </div>
    );
    
}


export default AddDiscountButton;