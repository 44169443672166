import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { UserAuth } from "../../auth/UserAuth";
import { FETCH_WAIT_LIST_DATA } from "../../store/wait-list/constants";
import { updateState } from "../../store/wait-list/waitListReducer";
import styles from "./index.module.css";
import ActionMessage from "../../components/common/messages/ActionMessage";
import WaitListTable from "./WaitListTable";
import DeleteWaitList from "./DeleteWaitList";
import WaitListDetails from "./WaitListDetails";
import Modal from "../../components/common/modal/Modal";


const WaitList = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const waitListState = useSelector((state: RootState) => state.waitListState);
    const { isDeleting, waitListItemInAction, actionMessage } = waitListState;
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;

    useEffect(() => {

        if(!UserAuth()) {
            navigate("/");
            return;
        }

        if(!user) return;

        // User must be on trial period or must have bought a sub plan to access the wait list
        if(!user.stripeSubscription && !user.isOnTrialPeriod) {
            navigate("/");
            return;
        }

        dispatch({ type: FETCH_WAIT_LIST_DATA });
        
    }, [user, navigate, dispatch]);

    const hideActionMessage = () => {
        dispatch(updateState({
            name: "actionMessage",
            value: null
        }));
    }

    const closeModal = () => {
        dispatch(updateState({
            name: "waitListItemInAction",
            value: null
        }))
    }

    return (
        <div className = {styles.wait_list}>
           <h2>Waiting List</h2>
           {
                actionMessage
                &&
                <ActionMessage 
                    actionMessage={actionMessage}
                    hideActionMessage={hideActionMessage}
                />
            }
           <WaitListTable />
           {(isDeleting && waitListItemInAction) ? <DeleteWaitList /> : null}
           {(waitListItemInAction) ? <DeleteWaitList /> : null}
           {
                (waitListItemInAction && !isDeleting)
                ?
                <Modal 
                    modalContent={<WaitListDetails />}
                    onCloseModal={closeModal}
                />
                :
                null
            }
        </div>
    );

}


export default WaitList;