import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { handleChange } from "../../store/discounts/discountReducer";
import styles from "./index.module.css";
import Checkbox from "./Checkbox";


const OffPeakDiscount: React.FC = () => {

    const dispatch = useDispatch();
    const discountState = useSelector((state: RootState) => state.discountState);
    const { offPeakDiscountList } = discountState;

    // State for toggling time based discount section
    const [showDiscountList, setShowDiscountList] = useState<boolean>(false);

    const handleCheckboxInputChange = () => setShowDiscountList(!showDiscountList);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, _id: string) => {

        const { name, value } = event.target;

        // Convert the value into number
        const newValue = Number(value);

        // As input field type is text so user can put anything there. But, we will only accept numbers
        if(newValue && isNaN(newValue)) return alert("Discount rate must be a number");

        const newOffPeakDiscountList = offPeakDiscountList.map(discountItem => {
            if(discountItem._id === _id) return { ...discountItem, [name]: newValue };
            return discountItem;
        });

        dispatch(handleChange({
            name: "offPeakDiscountList",
            value: newOffPeakDiscountList
        }));

    }

    return (
        <div className = {styles.offpeak_discounts}>
            <Checkbox 
                name = "showDiscountList"
                value = "Enable time based discounts"
                checked = {showDiscountList}
                handleChange = {handleCheckboxInputChange}
            />
            {
                showDiscountList
                &&
                <div className = {styles.offpeak_discount_table}>
                    <table>
                        <thead>
                            <tr>
                                <th>Time</th>
                                <th>Monday</th>
                                <th>Tuesday</th>
                                <th>Wednesday</th>
                                <th>Thursday</th>
                                <th>Friday</th>
                                <th>Saturday</th>
                                <th>Sunday</th>
                            </tr>
                        </thead>
                        <tbody>
                            {offPeakDiscountList.map(discountItem => {

                                const { _id } = discountItem;

                                return (
                                    <tr key = {_id}>
                                        {Object.entries(discountItem).map(([key, value]) => {

                                            if(key === "_id" || key === "time") return null;

                                            if(key === "timeText") return (<td key = {key}>{value}</td>); 
                                            
                                            return (
                                                <td key = {key}>
                                                    <input 
                                                        type = "text"
                                                        name = {key}
                                                        value = {value}
                                                        autoComplete = "off"
                                                        onChange = {(event) => handleInputChange(event, _id!)}
                                                    />
                                                </td>
                                            );

                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            }
        </div>
    );

}


export default OffPeakDiscount;