import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { addNewFollowUpEmailInfo } from "../../store/emails/emailReducer";
import CheckboxInputField from "../../components/common/input-fields/CheckboxInputField";
import styles from "./SelectLocationTreatment.module.css";

const SelectTreatment: React.FC = () => {

    const dispatch = useDispatch();
    const emailState = useSelector((state: RootState) => state.emailState);
    const { newFollowUpEmailInfo, treatmentList } = emailState;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, treatmentId: string) => {

        const { name, checked } = event.target;

        if(name === "selectAlltreatment") {
            const treatmentIdList = treatmentList.map(item => item._id);
            dispatch(addNewFollowUpEmailInfo({
                name: "treatmentList",
                value: checked ? treatmentIdList : []
            }));
            return;
        }

        if(checked) {
            dispatch(addNewFollowUpEmailInfo({
                name: "treatmentList",
                value: [...newFollowUpEmailInfo.treatmentList, treatmentId]
            }));
            return;
        }

        const filteredTreatmentList = newFollowUpEmailInfo.treatmentList.filter(item => item !== treatmentId);
        dispatch(addNewFollowUpEmailInfo({
            name: "treatmentList",
            value: filteredTreatmentList
        }));

    }

    return (
        <div className={styles.select_treatment}>
            <h2 className={styles.select_location_treatment_header}>Select treatment</h2>
            <div className={styles.treatment_item}>
                <CheckboxInputField 
                    name="selectAlltreatment"
                    value="Select All"
                    checked={treatmentList.length === newFollowUpEmailInfo.treatmentList.length}
                    handleChange={handleChange}
                />
            </div>
            {treatmentList.map(treatment => {
                const { _id, title } = treatment;
                const isTreatmentAdded = newFollowUpEmailInfo.treatmentList.some(item => item === _id);
                return (
                    <div key= {_id} className={styles.treatment_item}>
                        <CheckboxInputField 
                            name="treatment"
                            value={title}
                            checked={isTreatmentAdded}
                            handleChange={handleChange}
                            handleChangeArgs={treatment._id}
                        />
                    </div>
                );
            })}
        </div>
    );

}


export default SelectTreatment;