import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RootState } from "../../../store";
import { 
    chooseRepeatingAppointmentDateType,
    addNewAppointmentInfo, 
    updateState, 
    closeAddAppointmentModal 
} from "../../../store/calendar/calendarReducer";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { formatDateTime } from "../../../helpers/date-time/dateTimeHelpers";
import styles from "./AddAppointmentModal.module.css";
import { IoMdClose } from "react-icons/io";


const AppointmentDateList: React.FC = () => {

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { devicesWidth: { isLargeDevice } } = useWindowWidth();
    const { 
        appointmentDateList,
        repeatingAppointmentDateType,
        repeatingAppointmentStartDate,
        repeatingAppointmentEndDate
    } = calendarState;

    const handleClick = (dateType: string) => {
        dispatch(chooseRepeatingAppointmentDateType(dateType))
    }

    const handleDateInputChange = (date: Date | null, name?: string) => {

        if(!date) return;

        if(name) {
            dispatch(updateState({
                name,
                value: date
            }));
            return;
        }

        const { dateString } = formatDateTime(date);
        const isDateAddedAlready = appointmentDateList.some(item => {
            const { dateString: itemDateString } = formatDateTime(item);
            return dateString === itemDateString
        });

        if(isDateAddedAlready) return alert("Date already added")

        dispatch(updateState({
            name: "appointmentDateList",
            value: [...appointmentDateList, date]
        }))
       
    }


    const removeDate = (dateString: string) => {
        const filteredAppointmentDateList = appointmentDateList.filter(date => {
            const { dateString: itemDateString } = formatDateTime(date);
            return dateString !== itemDateString
        })
        dispatch(updateState({
            name: "appointmentDateList",
            value: filteredAppointmentDateList
        }))
    }

    const everydayButtonClass = repeatingAppointmentDateType === "everyday" ? "date_type_btn_selected": "";
    const selectedDatesButtonClass = repeatingAppointmentDateType === "selected_dates" ? "date_type_btn_selected": "";

    return (
        <div className={styles.appointment_date_list}>
            <div className={styles.date_type_buttons}>
                <button
                    className={`${styles.date_type_btn} ${styles[everydayButtonClass]}`} 
                    onClick={() => handleClick("everyday")}
                >
                    For Everyday
                </button>
                <button 
                    className={`${styles.date_type_btn} ${styles[selectedDatesButtonClass]}`} 
                    onClick={() => handleClick("selected_dates")}
                >
                    For Selected Dates
                </button>
            </div>
            {
                repeatingAppointmentDateType === "everyday"
                ?
                <div className={styles.select_start_end_date}>
                    <div className = {styles.select_date}>
                        <p className = {styles.select_date_label}>Select Start Date</p>
                        <div className={styles.select_date_datepicker}>
                            <DatePicker 
                                selected={repeatingAppointmentStartDate}
                                onChange={(date) => handleDateInputChange(date, "repeatingAppointmentStartDate")} 
                            />
                        </div>
                    </div>
                    <div className = {styles.select_date}>
                        <p className = {styles.select_date_label}>Select End Date</p>
                        <div className={styles.select_date_datepicker}>
                            <DatePicker 
                                selected={repeatingAppointmentEndDate}
                                onChange={(date) => handleDateInputChange(date, "repeatingAppointmentEndDate")} 
                            />
                        </div>
                    </div>
                </div>
                :
                null
            }
            {
                repeatingAppointmentDateType === "selected_dates"
                ?
                <>
                    <div 
                        className={styles.select_date}
                        style={{ margin: "0px" }}
                    >
                        <div className={styles.select_date_datepicker}>
                            <DatePicker 
                                selected={null}
                                placeholderText="Select Date"
                                onChange={(date) => handleDateInputChange(date)} 
                            />
                        </div>
                    </div>
                </>
                :
                null
            }
            {
                appointmentDateList.length > 0
                ?
                <div className={styles.selected_date_list}>
                    {appointmentDateList.map(date => {
                        const { dateString } = formatDateTime(date);
                        return (
                            <div key={dateString} className={styles.selected_date}>
                                <p>{dateString}</p>
                                <button onClick={() => removeDate(dateString)}>
                                    <IoMdClose />
                                </button>
                            </div>
                        );
                    })}
                </div>
                :
                null
            }
        </div>
    );

}

export default AppointmentDateList;