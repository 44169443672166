import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import { config } from "../../Constants";
import { validatePassword } from "../../helpers/validators/validatePassword";
import styles from "./ResetPassword.module.css";
import LogoImage from "../../components/login-signup/LogoImage";
import DesignImage from "../../components/login-signup/DesignImage";
import ErrorMessage from "../../components/login-signup/ErrorMessage";
import InputField from "./InputField";
import SubmitButton from "../../components/login-signup/SubmitButton";


const ResetPassword = () => {

    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");

    const [loading, setLoading] = useState<boolean>(false);
    const [apiErrorMsg, setApiErrorMsg] = useState<string>("");
    const [success, setSuccess] = useState<boolean>(false);
    const [passwordInfo, setPasswordInfo] = useState({ password: "", password2: "" })

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setPasswordInfo(currentValue => {
            return {
                ...currentValue,
                [name]: value
            }
        });
    }

    const saveNewPassword = async(event: SubmitEvent) => {

        event.preventDefault();

        // Password Validation
        const { isPassValid, passValMessage } = validatePassword(passwordInfo.password, passwordInfo.password2);
        if(!isPassValid) {
            setApiErrorMsg(passValMessage);
            return;
        }

        setLoading(true);
        
        const endpoint = config.url.BACKEND_API_URL + "/users/reset-password";
        const axiosConfig = {
            headers: {
                "emailToken": token
            }
        };
        const requestBody = { password: passwordInfo.password };

        try {

            await axios.post(endpoint, requestBody, axiosConfig);
            setLoading(false);
            setSuccess(true);

        } catch(error: any) {
            const { message } = handleApiError(error);
            setLoading(false);
            setApiErrorMsg(message);
        }
      
    }

    return (
        <div className = {styles.reset_password}>
            <div className = {styles.password_info}>
                <LogoImage />
                {
                    success
                    ?
                    <p>Password has been reset successfully</p>
                    :
                    <>
                        <h2>Set a password</h2>
                        {apiErrorMsg ? <ErrorMessage message={apiErrorMsg} /> : null}
                        <div className={styles.password_form}>
                            <form>
                                <InputField 
                                    labelText="Password"
                                    type="password"
                                    name="password"
                                    value={passwordInfo.password}
                                    placeholder="Enter your password"
                                    handleChange={handleChange}
                                />
                                <InputField 
                                    labelText="Re-enter Password"
                                    type="password"
                                    name="password2"
                                    value={passwordInfo.password2}
                                    placeholder="Re-enter your password"
                                    handleChange={handleChange}
                                />
                                <SubmitButton 
                                    buttonText={loading ? "Resetting Password..." : "Reset Password"}
                                    disabled={!passwordInfo.password || !passwordInfo.password2 || loading}
                                    handleSubmit={saveNewPassword}
                                />
                            </form>
                        </div>
                    </>
                }
            </div>
            <DesignImage />
        </div>
    );

}


export default ResetPassword;