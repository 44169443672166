import styles from "./BookingSnippet.module.css";

interface SnippetItemProps {
    title: string
    description: string
    code: React.ReactNode
}


const SnippetItem: React.FC<SnippetItemProps> = ({ title, description, code }) => {
  
    return (
        <div className={styles.snippet_modal_item}>
            <h3>{title}</h3>
            <p>{description}</p>
            <div className={styles.code_container}>
                {code}
            </div>
        </div>     
    );

}


export default SnippetItem;