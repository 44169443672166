import { IWaitList } from "../../types/models/WaitList";
import styles from "./Button.module.css";

interface IconButtonProps {
    customClassName?: string
    buttonText?: string
    icon: React.ReactNode 
    handleActionButtonClick: (actionType: string, waitListItem: IWaitList) => void
    actionType: string
    waitListItem: IWaitList
}

const IconButton: React.FC<IconButtonProps> = (props) => {

    const { 
        customClassName = "", 
        buttonText, 
        icon,
        actionType,
        waitListItem, 
        handleActionButtonClick
    } = props;

    return (
        <button
            className={`${styles.icon_button} ${styles[customClassName]}`}
            onClick={() => handleActionButtonClick(actionType, waitListItem)}
        >
            {icon}
            {buttonText ? <span>{buttonText}</span> : null}
        </button>
    );

}

export default IconButton;