import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import { RootState } from "../../../store";
import { config } from "../../../Constants";
import { ClientItem } from "../../../types/pages/Calendar";
import { updateState } from "../../../store/calendar/calendarReducer";
import { getAuthTokenConfig } from "../../../helpers/others/getAuthTokenConfig";
import { handleApiError } from "../../../helpers/error-handlers/handleApiError";
import styles from "./ClientSearch.module.css";
import ClientInfo from "./ClientInfo";
import AppointmentTable from "./AppointmentTable";
import Buttons from "./Buttons";

function ClientDetailsModal() {

    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.userState);
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const clientInAction = calendarState.clientInAction as ClientItem;

    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>();

    useEffect(() => {

        const delayAPICall = setTimeout(async() => {

            const queryParams = `clientEmail=${clientInAction.email}`;
            const endpoint = config.url.BACKEND_API_URL + "/appointments/search?" + queryParams;
            const authConfig = getAuthTokenConfig();

            setLoading(true);

            try {

                const response = await axios.get(endpoint, authConfig);
                const clientAppointments = response.data.appointmentList;
                dispatch(updateState({
                    name: "clientAppointments",
                    value: clientAppointments
                }));
                setLoading(false)

            } catch(error) {
                const { message } = handleApiError(error)
                setLoading(false);
                setErrorMsg(message)
            }

        }, 100);

        return () => clearTimeout(delayAPICall);

    }, [clientInAction.email, dispatch])

    return (
        <div className={styles.client_details_modal}>
            <Buttons />
            <div className={styles.client_details}>
                <div className={styles.client_main_info}>
                    <h3>
                        <Link 
                            to={`/user/${userState.user?.tenantId}/appointment-client-list?clientEmail=${clientInAction.email}`}
                        >
                            {clientInAction.name}
                        </Link>
                    </h3>
                    <ClientInfo 
                        infoTitle="Email"
                        info={clientInAction.email}
                    />
                    <ClientInfo 
                        infoTitle="Phone"
                        info={clientInAction.phone}
                    />
                </div>
            </div>
            {
                loading
                ?
                <div>Loading...</div>
                :
                errorMsg
                ?
                <div>{errorMsg}</div>
                :
                <AppointmentTable />
            }
        </div>
    );

}

export default ClientDetailsModal;