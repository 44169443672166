import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import useClickOutside from "../../../hooks/useClickOutside";
import { RootState } from "../../../store";
import { config } from "../../../Constants";
import { updateState, addNewAppointmentInfo } from "../../../store/calendar/calendarReducer";
import { getAuthTokenConfig } from "../../../helpers/others/getAuthTokenConfig";
import { handleApiError } from "../../../helpers/error-handlers/handleApiError";
import styles from "./AddAppointmentModal.module.css";
import InputField from "../common/InputField";
import CheckboxInputField from "../../../components/common/input-fields/CheckboxInputField";
import ClientList from "./ClientList";


const ClientInfo: React.FC = () => {

    const { elementRef, isVisible, setIsVisible } = useClickOutside({ exceptions: [] });

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;
    const { newAppointmentInfo, clientList, validationError } = calendarState;
    const { clientName, clientPhone, clientEmail } = newAppointmentInfo;

    // searchText should be the value of the field user's filling up right now. And when user backspaces
    // on an input field to empty it then we must not make the api call. For these two reasons we need 
    // clientInputFieldBeingUpdated state, which is further explained below.
    const [clientInputFieldBeingUpdated, setClientInputFieldBeingUpdated] = useState<string>("");

    useEffect(() => {

        // If none of the clientInfo field is filled then we must not make the api call.
        // This is for the first time when users opens up AddAppointmentModal. Because
        // we definitely don't want to make an api call with empty searchText
        if(!clientName && !clientEmail && !clientPhone) return;

        // If all three fields of clientInfo are filled then we must not make the api call.
        if(clientName && clientEmail && clientPhone) return;

        // If user is backspacing on a clientInfo field to empty it then we must not make the api call.
        // @ts-ignore
        if(!newAppointmentInfo[clientInputFieldBeingUpdated]) return;

        // Calling setTimeout directly will make api call for each character typed. But assigning
        // this function to a variable re-initializes the timer. So, just one api call will be made
        // when user stops typing.
        const delayAPICall = setTimeout(async () => {

            // When user searchs for phone numbers he/she might want to start with + icon. But searchText containing some
            // special characters like + will cause the server crash. So we need to remove the special characters to do
            // the search. But actual input field value will contain the special characters, if there is any.
            // @ts-ignore
            const specialCharacterFreeSearchText = newAppointmentInfo[clientInputFieldBeingUpdated].replace(/[^a-zA-Z0-9.@ ]/g, "");

            // After removing special characters searchText could be empty string. Like if user is searching by phone 
            // number and he/she starts with + icon then after removing it searchText would be empty string. In such
            // cases we should not make the api call. Because empty string will fetch all the appointments which is
            // going to take a lot of time and will have a serious hit on performance. So we will only make api call
            // if searchText length is at least 2 characters. It will further improve the performance.
            if(specialCharacterFreeSearchText.length < 2) return;

            setIsVisible(true);

            const queryParams = `searchText=${specialCharacterFreeSearchText}`
            const endpoint = config.url.BACKEND_API_URL + "/appointment-clients/search?" + queryParams;
            const authConfig = getAuthTokenConfig();

            try {

                const response = await axios.get(endpoint, authConfig);
                const clientList = response.data.clientList;
                dispatch(updateState({
                    name: "clientList",
                    value: clientList
                }));

            } catch(error) {
                const { message } = handleApiError(error);
                alert(message);
            }

        }, 500);

        return () => clearTimeout(delayAPICall);

    }, [
        clientName, 
        clientPhone, 
        clientEmail, 
        newAppointmentInfo, 
        dispatch, 
        user?.tenantId, 
        clientInputFieldBeingUpdated, 
        setIsVisible
    ]);


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const { name, value, checked } = event.target;

        if(name === "subscribeToMailchimp") {
            dispatch(addNewAppointmentInfo({ name, value: checked }));
            return;
        }

        dispatch(addNewAppointmentInfo({ name, value }));

        setClientInputFieldBeingUpdated(name);

        // Whenever user empties an input field we must update the clientList to empty array to
        // prevent api calls. Because when an input field is being emptied then it makes no sense
        // to make further api calls. 
        if(!value) {
            dispatch(updateState({
                name: "clientList",
                value: []
            }))
        }

    }

    return (
        <div className = {styles.client_info}>
            <h3>Client Details</h3>
            <div className = {styles.client_info_content}>
                <div className = {styles.client_input_container}>
                    <InputField 
                        labelText = "Full Name"
                        type = "text" 
                        name = "clientName" 
                        placeholder = "Enter full name"
                        autoComplete="off"
                        value = {clientName} 
                        handleChange = {handleChange} 
                        validationError = {validationError}
                        validationErrorMessage = "name can't be blank" 
                    />
                    {
                        (clientInputFieldBeingUpdated === "clientName" && isVisible && clientList.length > 0)
                        &&
                        <ClientList 
                            elementRef={elementRef}
                            setIsVisible={setIsVisible}
                        />
                    }
                </div>
                <div className = {styles.client_input_container}>
                    <InputField 
                        labelText = "Email"
                        type = "text" 
                        name = "clientEmail" 
                        placeholder = "Enter email address"
                        autoComplete="off"
                        value = {clientEmail} 
                        handleChange = {handleChange}
                        validationError = {validationError}
                        validationErrorMessage = "email can't be blank"  
                    />
                    {
                        (clientInputFieldBeingUpdated === "clientEmail" && isVisible && clientList.length > 0)
                        &&
                        <ClientList 
                            elementRef={elementRef}
                            setIsVisible={setIsVisible}
                        />
                    }
                </div>
                <div className = {styles.client_input_container}>
                    <InputField
                        customClassName="client_phone_input" 
                        labelText = "Phone"
                        type = "text" 
                        name = "clientPhone" 
                        placeholder = "Enter phone number"
                        autoComplete="off"
                        value = {clientPhone} 
                        handleChange = {handleChange} 
                        validationError = {validationError}
                        validationErrorMessage = "phone can't be blank" 
                    />
                    {
                        (clientInputFieldBeingUpdated === "clientPhone" && isVisible && clientList.length > 0)
                        &&
                        <ClientList 
                            elementRef={elementRef}
                            setIsVisible={setIsVisible}
                        />
                    }
                </div>
                <CheckboxInputField 
                    name="subscribeToMailchimp"
                    value="I would like to receive offers in my email"
                    checked={newAppointmentInfo.subscribeToMailchimp}
                    handleChange={handleChange}
                />
            </div>
        </div>
    );

}

export default ClientInfo;