import { MdViewCompact } from "react-icons/md";
import { IWaitList } from "../../types/models/WaitList";
import styles from "./WaitListTable.module.css";
import DeleteIcon from "../../components/icons/DeleteIcon";
import IconButton from "./IconButton";

interface ActionButtonProps {
    waitListItem: IWaitList
    showButtonText: boolean
    handleActionButtonClick: (actionType: string, waitListItem: IWaitList) => void
}

const ActionButtons: React.FC<ActionButtonProps> = ({ waitListItem, showButtonText,handleActionButtonClick }) => {

    return (
        <div className={styles.action_buttons}> 
            <IconButton 
                buttonText={showButtonText ? "View" : ""}
                icon={
                    <MdViewCompact 
                        color="red"
                        size={"1.5rem"}
                    />
                }
                handleActionButtonClick={handleActionButtonClick}
                actionType="view"
                waitListItem={waitListItem}
            />
            <IconButton 
                buttonText={showButtonText ? "Delete" : ""}
                icon={<DeleteIcon />}
                handleActionButtonClick={handleActionButtonClick}
                actionType="delete"
                waitListItem={waitListItem}
            />
        </div>            
    );

}

export default ActionButtons;