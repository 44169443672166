import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import styles from "./TenantInfo.module.css";
import CompanyName from "./CompanyName";
import Info from "./Info";


const TenantInfo: React.FC = () => {

    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;

  
    return (
        <div className={styles.user_info}>
            <div className = {styles.info}>
                {
                    user
                    &&
                    <>
                        <CompanyName />
                        <Info 
                            header="Full Name" 
                            content={user.fullName} 
                        />
                        <Info 
                            header="Mobile" 
                            content={user.phone} 
                        />
                        <Info 
                            header="Email" 
                            content={user.email}
                        />
                         <Info 
                            header="Location" 
                            content={user.companyAddress.city ?? ""}
                        />
                    </>
                }
            </div>
        </div>
    );

}

export default TenantInfo;
