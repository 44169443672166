import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { handleChange } from "../../store/discounts/discountReducer";
import styles from "./index.module.css";
import Header from "./Header";
import Checkbox from "./Checkbox";
import CheckboxInputField from "./CheckboxInputField";


const SelectTreatment: React.FC = () => {

    const dispatch = useDispatch();
    const discountState = useSelector((state: RootState) => state.discountState);
    const { filteredTreatments, selectedTreatments, selectedStylists } = discountState;
    const [showTreatments, setShowTreatments] = useState(false);

    const handleCheckboxInputChange = () => setShowTreatments(!showTreatments);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        // User must select stylist first.
        if(!selectedStylists.length) return alert("Please select stylist first");

        const { value, checked } = event.target;

        // Selecting treatments
        if(checked) {

            // If user is selecting all treatments then payload would be fetched treatments api data
            if(value === "Select All") {
                dispatch(handleChange({
                    name: "selectedTreatments",
                    value: filteredTreatments
                }));
                return;
            }
            
            // If user is not selecting all then we need to find the treatment and insert it into 
            // selectedTreatments array.
            const foundTreatment = filteredTreatments.find(treatment => treatment.title === value);
            if(!foundTreatment) return;
            dispatch(handleChange({
                name: "selectedTreatments",
                value: [...selectedTreatments, foundTreatment]
            }));
            return;

        }

        // Unselecting treatments

        // if user is unselecting all then payload would be empty array
        if(value === "Select All") {
            dispatch(handleChange({
                name: "selectedTreatments",
                value: []
            }));
            return;
        }

        // If user is not unselecting all then need to filter the selectedTreatments
        const filteredSelectedTreatments = selectedTreatments.filter(treatment => treatment.title !== value);
        dispatch(handleChange({
            name: "selectedTreatments",
            value: filteredSelectedTreatments
        }));

    }


    return (
        <div className = {styles.select_treatment}>
            <Checkbox 
                name = "showTreatments"
                value = "Show Treatments"
                checked = {showTreatments}
                handleChange = {handleCheckboxInputChange}
            />
            {
                showTreatments
                &&
                <>
                    <Header text = "Select Treatment" />
                    <CheckboxInputField 
                        name = "selectTreatment"
                        value = "Select All"
                        checked = {filteredTreatments.length === selectedTreatments.length}
                        handleChange = {handleInputChange} 
                    />
                    {filteredTreatments.map(treatment => {
                        const isChecked = selectedTreatments.some(item => item._id === treatment._id);
                        return (
                            <CheckboxInputField 
                                key = {treatment._id.toString()}
                                name = "selectTreatment"
                                value = {treatment.title}
                                checked = {isChecked}
                                handleChange = {handleInputChange} 
                            />
                        );
                    })}
                </>
            }
        </div>
    );

}

export default SelectTreatment;