export const calculateWeekDates = (navigationType?: string, weekViewDates?: Date[]) => {

    const oneDayMS = 86400000;
    const newWeekViewDates: Date[] = [];

    // It means calendar is open for the first time and we need to find initial week dates based on today date
    if(!weekViewDates) {

        let sundayDate = new Date();
        let sundayDateInMS = sundayDate.getTime();
        const sundayDateWeekDay = sundayDate.toLocaleString("en-us", {  weekday: "long" });

        if(sundayDateWeekDay.toLowerCase() !== "sunday") {

            for(let x = 6; x >= 1; x--) {

                const date = new Date(sundayDateInMS - (oneDayMS * x));
    
                const dateWeekDay = date.toLocaleString("en-us", {  weekday: "long" });
                if(dateWeekDay.toLowerCase() === "sunday") {
                    sundayDate = date;
                    break;
                }
                
            }

        }

        sundayDateInMS = sundayDate.getTime();

        newWeekViewDates.push(sundayDate);

        for(let x = 1; x < 7; x++) {
            const date = new Date(sundayDateInMS + (oneDayMS * x));
            newWeekViewDates.push(date);
        }


        return newWeekViewDates;

    }

    if(!navigationType) return newWeekViewDates;

    if(navigationType === "next") {

        // if argument weekViewDates is there it means we need to find week dates based on current ones
        const lastDateOfTheWeek = weekViewDates[weekViewDates.length - 1];
        const loopStartDate = new Date(lastDateOfTheWeek.getTime() + oneDayMS);

        for(let x = 0; x < 7; x++) {
            const date = new Date(loopStartDate.getTime() + (oneDayMS * x));
            newWeekViewDates.push(date);
        }

        return newWeekViewDates;
    }

    // if argument weekViewDates is there it means we need to find week dates based on current ones
    const firstDateOfTheWeek = weekViewDates[0];
    const loopStartDate = new Date(firstDateOfTheWeek.getTime() - (oneDayMS * 7));

    for(let x = 0; x < 7; x++) {
        const date = new Date(loopStartDate.getTime() + (oneDayMS * x));
        newWeekViewDates.push(date);
    }

    return newWeekViewDates;

    
}