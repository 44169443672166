import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFollowUpEmail } from "../../types/models/FollowUpEmail";
import { EmailState, FetchedEmailAPIData } from "../../types/pages/Email";

const initialState: EmailState = {
    loading: true,
    apiErrorMsg: null,
    validationError: false,
    fetchLocationAndTreatmentData: "yes",
    followUpEmailList: [],
    locationList: [],
    treatmentList: [],
    followUpEmailItemInAction: null,
    newFollowUpEmailInfo: {
        title: "",
        followUpHours: "",
        emailText: "",
        locationList: [],
        treatmentList: []
    },
    searchText: "",
    skip: 0, 
    limit: 10,
    totalFollowUpEmailCount: 0,
    isAdding: false,
    isEditing: false,
    isDeleting: false,
    actionMessage: null
}


const emailSlice = createSlice({
    name: "emails",
    initialState,
    reducers: {

        fetchDataLoading: (state) => {
            return { ...state, loading: true }
        },

        fetchDataSuccess: (state, action: PayloadAction<FetchedEmailAPIData>) => {

            const { 
                followUpEmailList,
                locationList,
                treatmentList,
                totalFollowUpEmailCount 
            } = action.payload;

            return {
                ...state,
                loading: false,
                followUpEmailList,
                totalFollowUpEmailCount,
                locationList: locationList ?? state.locationList,
                treatmentList: treatmentList ?? state.treatmentList,
                fetchLocationAndTreatmentData: "no"
            }

        },

        fetchDataFail: (state, action:PayloadAction<string | null>) => {
            return {
                ...state,
                loading: false,
                apiErrorMsg: action.payload
            }
        },

        updateState: (state, action: PayloadAction<{ name: string, value: any }>) => {
            const { name, value } = action.payload;
            return {
                ...state,
                [name]: value
            }
        },

        addNewFollowUpEmailInfo: (state, action: PayloadAction<{ name: string, value: string | string[] }>) => {
            const { name, value } = action.payload;
            return {
                ...state,
                newFollowUpEmailInfo: {
                    ...state.newFollowUpEmailInfo,
                    [name]: value
                }
            }
        },

        closeAddEditFollowUpEmailModal: (state) => {
            return { 
                ...state, 
                validationError: false,
                isAdding: false,
                isEditing: false,
                followUpEmailItemInAction: null,
                newFollowUpEmailInfo: {
                    title: "",
                    followUpHours: "",
                    emailText: "",
                    locationList: [],
                    treatmentList: []
                } 
            }
        },

        openEditFollowUpEmailModal: (state, action: PayloadAction<IFollowUpEmail>) => {

            const followUpEmail = action.payload;

            return { 
                ...state, 
                isEditing: true,
                followUpEmailItemInAction: followUpEmail,
                newFollowUpEmailInfo: { 
                    title: followUpEmail.title,
                    followUpHours: followUpEmail.followUpHours.toString(),
                    emailText: followUpEmail.emailText,
                    locationList: followUpEmail.locationList.map(item => item._id),
                    treatmentList: followUpEmail.treatmentList.map(item => item._id)
                } 
            }
        },

        toggleFollowUpEmailDeletePrompt: (state, action: PayloadAction<null | IFollowUpEmail>) => {
            return {
                ...state,
                followUpEmailItemInAction: action.payload,
                isDeleting: !state.isDeleting
            }
        }

    }
})

export const {
    fetchDataLoading,
    fetchDataSuccess,
    fetchDataFail,
    updateState,
    addNewFollowUpEmailInfo,
    closeAddEditFollowUpEmailModal,
    openEditFollowUpEmailModal,
    toggleFollowUpEmailDeletePrompt
} = emailSlice.actions;

export default emailSlice.reducer;