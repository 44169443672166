import { useParams } from "react-router-dom";
import useGetStripeAccountStatus from "../../hooks/backend_api/stripe/useGetStripeAccountStatus";
import useCreateStripeAccountLink from "../../hooks/backend_api/stripe/useCreateStripeAccountLink";
import { decodeJWTToken } from "../../helpers/others/decodeJWTToken";
import styles from "./index.module.css";

const StripeReturnUrl = () => {

    const { accountId } = useParams();
    const { loading, errorMsg, isStripeConnectedAccountActive } = useGetStripeAccountStatus(accountId);
    const { loading: createStripeLinkLoading, createStripeAccountLink } = useCreateStripeAccountLink();

    const backToProfile = () => {
        const user = decodeJWTToken("auth_token");
        if(!user.hasOwnProperty("tenantId")) return;
        window.location.href = `/user/${user?.tenantId}/profile-tenant`;
    }

    return (
        <div className={styles.stripe_return_url}>
            {
                loading
                ?
                <p>Connecting Stripe.... </p>
                :
                errorMsg
                ?
                <p>{errorMsg}</p>
                :
                isStripeConnectedAccountActive
                ?
                <div className={styles.success_try_again_message}>
                    <p>Stripe connection successful</p>
                    <button onClick={backToProfile}>
                        Back To Profile
                    </button>
                </div>
                :
                <div className={styles.success_try_again_message}>
                    <p>You haven't provided all the details required for successful stripe connection.</p>
                    <button 
                        disabled={createStripeLinkLoading}
                        onClick={() => createStripeAccountLink(accountId)}
                    >
                        Complete my stripe profile
                    </button> 
                </div>
              
            }
        </div>
    );

}

export default StripeReturnUrl;