import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import { IProduct } from "../../types/models/Product";
import { IGiftCard } from "../../types/models/GiftCard";
import { RootState } from "../../store";
import { config } from "../../Constants";
import useWindowWidth from "../../hooks/useWindowWidth";
import { updateState, addProductSaleInfo, closeSaleModal } from "../../store/products/productReducer";
import { getAuthTokenConfig } from "../../helpers/others/getAuthTokenConfig";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import styles from "./SellProduct.module.css";
import InputField from "../../components/common/input-fields/InputField";
import ValidationErrorMessage from "../../components/common/messages/ValidationErrorMessage";
import SaveButton from "./SaveButton";

const SellProduct: React.FC = () => {

    const { tenantId } = useParams();
    const { windowWidth } = useWindowWidth();

    const dispatch = useDispatch();
    const productState = useSelector((state: RootState) => state.productState);
    const { productList, productSaleInfo, validationError } = productState;

    const productInAction = productState.productInAction as IProduct;
    const { stockAvailable, discountedPrice, sellingPrice } = productInAction;

    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        dispatch(addProductSaleInfo({ name, value }));
    }

    const getGiftCardInfo = async(totalPrice: number) => {

        const params = `tenantId=${tenantId}`;
        const endpoint = config.url.BACKEND_API_URL + `/gift-cards/${productSaleInfo.giftCard}?${params}`;

        try {

            const response = await axios.get(endpoint);
            const giftCard: IGiftCard = response.data.giftCard;

            const giftCardUseableAmount = giftCard.amount - giftCard.usedAmount;

            if(giftCardUseableAmount <= 0) return 0;
            if(giftCardUseableAmount >= totalPrice) return totalPrice;
            return giftCardUseableAmount;

        } catch(error) {
            return 0;
        }

    }

    const sellProduct = async(event: React.MouseEvent<HTMLButtonElement> | SubmitEvent) => {
        
        event.preventDefault();

        if(errorMsg) setErrorMsg(undefined);

        const { 
            quantity, 
            saleAdvisor, 
            buyerName,
            buyerEmail,
            buyerPhone,
            giftCard, 
            paymentMethod 
        } = productSaleInfo;

        if(!quantity || !saleAdvisor) {
            dispatch(updateState({
                name: "validationError",
                value: true
            }));
            return;
        }

        const newQuantity = parseInt(quantity);

        if(newQuantity > stockAvailable) {
            setErrorMsg("Available stock is less than quantity");
            return;
        } 

        const totalPrice = newQuantity * productInAction.discountedPrice;

        setLoading(true);

        let giftCardAmount = 0;

        if(giftCard !== "") {
            giftCardAmount = await getGiftCardInfo(totalPrice);
            if(giftCardAmount <= 0) return alert("gift card is invalid");
        }

        const selectedProductList = [
            {
                _id: productInAction._id,
                name: productInAction.name,
                discountedPrice: productInAction.discountedPrice,
                quantity: newQuantity,
                totalPrice,
                newAvailableStock: stockAvailable - newQuantity,
                giftCard: giftCard === "" ? null : giftCard,
                giftCardAmount,
            }
        ];

        const requestBody = {
            selectedProductList,
            saleAdvisor,
            deliveryCharge: 0,
            buyerInfo: {
                name: buyerName === "" ? null : buyerName,
                email: buyerEmail === "" ? null : buyerEmail,
                phone: buyerPhone === "" ? null : buyerPhone
            },
            paymentMethod: paymentMethod === "" ? null : paymentMethod
        };

        const endpoint = config.url.BACKEND_API_URL + "/products/sell/" + tenantId;
        const authConfig = getAuthTokenConfig();

        try {

            const response = await axios.post(endpoint, requestBody, authConfig);
            setLoading(false);
            dispatch(closeSaleModal());
            dispatch(updateState({
                name: "actionMessage",
                value: response.data.message
            }));

            const updatedProduct: IProduct = response.data.updatedProduct;

            const newProductList = productList.map(item => {
                if(item._id === updatedProduct._id) return updatedProduct;
                return item;
            });

            dispatch(updateState({
                name: "productList",
                value: newProductList
            }));
          
        } catch(error) {
            const { message } = handleApiError(error);
            setErrorMsg(message);
            setLoading(false);
        }

    }

    const newQuantity = productSaleInfo.quantity ? parseInt(productSaleInfo.quantity) : 0;

    const regularTotalPrice = sellingPrice * newQuantity;
    const discountedTotalPrice = discountedPrice * newQuantity;
    const isDiscountedPriceApplicable = discountedPrice < sellingPrice;

    const savePercentage = ((sellingPrice - discountedPrice) * 100) / sellingPrice;

    return (
        <div className={styles.sell_product}>
            {
                productInAction.imageList[0]?.imageUrl
                ?
                <div className={styles.product_image}>
                    <img 
                        src={productInAction.imageList[0]?.imageUrl ?? ""}
                        alt={"No photos to show"}
                    />
                </div>
                :
                null
            }
            <div 
                className={styles.sale_info} 
                style={{
                    width: windowWidth <= 1000 ? "100%" : productInAction.imageList[0]?.imageUrl ? "50%" : "100%"
                }}
            >
                <h2>{productInAction.name}</h2>
                <p className={styles.product_description}>
                    {productInAction.description}
                </p>
                <div className={styles.price}>
                    <p>Price</p>
                    <div className={styles.price_details}>
                        <p style={{ textDecoration: isDiscountedPriceApplicable  ? "line-through" : undefined }}>
                            €{regularTotalPrice}
                        </p>
                        {
                            isDiscountedPriceApplicable
                            ?
                            <>
                                <p className={styles.discounted_price}>
                                    €{discountedTotalPrice}
                                </p>
                                <p className={styles.save_text}>
                                    You save {Math.round(savePercentage)}%
                                </p>
                            </>
                            :
                            null
                        }
                    </div>
                </div>
                <div className={styles.quantity}>
                    <p>Quantity</p>
                    <InputField 
                        customClassName="sale_product_quantity"
                        name="quantity"
                        value={productSaleInfo.quantity}
                        handleChange={handleChange}
                    />
                </div>
                <form>
                    <InputField 
                        labelText="Sale Advisor"
                        name="saleAdvisor"
                        value={productSaleInfo.saleAdvisor}
                        required={true}
                        handleChange={handleChange}
                        validationError={validationError}
                        validationErrorMessage="seller name can't be blank"
                    />
                    <InputField 
                        labelText="Buyer Name"
                        name="buyerName"
                        value={productSaleInfo.buyerName}
                        handleChange={handleChange}
                    />
                    <InputField 
                        labelText="Buyer Email"
                        name="buyerEmail"
                        value={productSaleInfo.buyerEmail}
                        handleChange={handleChange}
                    />
                    <InputField 
                        labelText="Buyer Phone"
                        name="buyerPhone"
                        value={productSaleInfo.buyerPhone}
                        handleChange={handleChange}
                    />
                    <InputField 
                        labelText="Gift Card"
                        name="giftCard"
                        value={productSaleInfo.giftCard}
                        handleChange={handleChange}
                    />
                    <InputField 
                        labelText="Payment Method"
                        name="paymentMethod"
                        value={productSaleInfo.paymentMethod}
                        handleChange={handleChange}
                    />
                    {errorMsg ? <ValidationErrorMessage message={errorMsg}/> : null}
                    <SaveButton 
                        className="full_width_save_btn"
                        buttonText="Sell"
                        disabled={loading}
                        onClick={sellProduct}
                    />
                </form>
            </div>
        </div>
    );

}

export default SellProduct;