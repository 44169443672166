import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { DefaultPaymentMethodObj } from "../../types/common/PaymentMethod";
import { updateState } from "../../store/gift-card/giftCardReducer";
import { defaultPaymentMethodItems } from "../../data/paymentMethod";
import styles from "./index.module.css";
import DropdownList from "./DropdownList";
import ValidationErrorMessage from "../../components/common/messages/ValidationErrorMessage";
import InputField from "./InputField";
import BuyerInfo from "./BuyerInfo";
import ReceiverInfo from "./ReceiverInfo";
import SaveButton from "./SaveButton";
import Confirmation from "./Confirmation";

interface BuyGiftCardProps {
    handleChange: (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void
}

const BuyGiftCard: React.FC<BuyGiftCardProps> = ({ handleChange }) => {

    const dispatch = useDispatch();
    const giftCardState = useSelector((state: RootState) => state.giftCardState);
    const { 
        validationError, 
        validationErrorMessage, 
        paymentMethod, 
        giftCardAmount,
        giftCardInAction
    } = giftCardState;

    const choosePaymentMethod = (paymentMethodItem: DefaultPaymentMethodObj) => {
        dispatch(updateState({
            name: "paymentMethod",
            value: paymentMethodItem
        }));        
    }

    return (
        <div className={styles.card_modal}>
            <h2 className={styles.card_modal_header}>Gift Card Details</h2>
            {
                giftCardInAction
                ?
                <Confirmation />
                :
                <>
                    <div className={styles.payment_method_dropdown}>
                        <DropdownList 
                            data={defaultPaymentMethodItems}
                            nameKey="methodText"
                            selectedValue={paymentMethod ? paymentMethod.methodText : "Select payment method"}
                            clickHandler={choosePaymentMethod}
                        />
                    </div>
                    <InputField 
                        labelText="Amount"
                        type="text"
                        name="giftCardAmount"
                        value={giftCardAmount}
                        handleChange={handleChange}
                        validationError={validationError}
                        validationErrorMessage="amount can't be empty"
                    />
                    <BuyerInfo 
                        handleChange={handleChange}
                    />
                    <ReceiverInfo 
                        handleChange={handleChange}
                    /> 
                    {
                        validationErrorMessage 
                        ? 
                        <ValidationErrorMessage 
                            message={validationErrorMessage!} 
                        /> 
                        : 
                        null
                    }
                    <SaveButton />
                </>
            }
        </div>
    );

}

export default BuyGiftCard;