import styles from "./Dropdown.module.css";

interface IProps {
    isVisible: boolean,
    nameKey: string,
    data: any[], 
    dropdownListFor: string,
    handleClick: (args: any) => void,
}


const DropdownData: React.FC<IProps> = ({ isVisible, data, nameKey, dropdownListFor, handleClick }) => {

    return (
        <div className={styles.dropdown_item_list} style={{ display: isVisible ? "block" : "none" }}>
            <ul>
                {data.map(item => {
                    return (
                        <li 
                            key = {item._id} 
                            onClick = {() => handleClick({ item, dropdownListFor })}
                        >
                            {item[nameKey]}
                        </li>
                    );
                })}
            </ul>
        </div>
    );

}


export default DropdownData;