export const validateEmailAddress = (email: string) => {

    let isEmailValid: boolean = true;
    let emailValMessage: string = "";

    if(email.indexOf("@") === -1) emailValMessage = "Email must contain an @ symbol";
    if(email.indexOf(".") === -1) emailValMessage = "Email must contain at least one dot(.) symbol";
    if(email.indexOf(" ") !== -1) emailValMessage = "Email must not have a space";

    if(emailValMessage) isEmailValid = false;

    return { isEmailValid, emailValMessage };

}