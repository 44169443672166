import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { updateState } from "../../store/products/productReducer";
import { 
    FETCH_PRODUCT_DATA,
    FETCH_PRODUCT_SALE_DATA,
    FETCH_PURCHASE_ORDER_DATA 
} from "../../store/products/constants";
import styles from "./DataTable.module.css";
import DropdownList from "./DropdownList";
import DataTableButtons from "./DataTableButtons";
import NextPrevView from "./NextPrevView";
import ProductTable from "./ProductTable";
import ProductSalesTable from "./ProductSalesTable";
import POTable from "./POTable";

const DataTable = () => {

    const dispatch = useDispatch();
    const productState = useSelector((state: RootState) => state.productState);
    const { 
        showTableFor, 
        totalProductCount, 
        totalProductSalesCount,
        totalPurchaseOrderCount,
        skip, 
        limit 
    } = productState;

    let totalItemCount = totalProductCount;
    if(showTableFor === "product_sales") totalItemCount = totalProductSalesCount;
    if(showTableFor === "purchase_order") totalItemCount = totalPurchaseOrderCount;

    const handleDropdownChange = (viewItem: { _id: number, limit: number }) => {

        if(totalItemCount <= 10) return;

        dispatch(updateState({
            name: "limit",
            value: viewItem.limit
        }));

        fetchData();

    }

    const fetchData = () => {

        setTimeout(() => {

            if(showTableFor === "products") {
                dispatch({ type: FETCH_PRODUCT_DATA })
            }

            if(showTableFor === "product_sales") {
                dispatch({ type: FETCH_PRODUCT_SALE_DATA })
            }

            if(showTableFor === "purchase_order") {
                dispatch({ type: FETCH_PURCHASE_ORDER_DATA })
            }

        }, 100)

    }

    const navigateToNextPrevProducts = (buttonClickType: string) => {

        if(buttonClickType === "next") {

            if(totalItemCount <= (skip + limit)) return;

            dispatch(updateState({
                name: "skip",
                value: skip + limit
            }));

            fetchData();

            return;

        }
        
        if(!skip) return;

        dispatch(updateState({
            name: "skip",
            value: skip - limit
        }));

        fetchData();
       
    }

    return (
        <div className={styles.data_table_container}>
            <div className={styles.data_table_header}>
                <DataTableButtons />
                <DropdownList 
                    customClassName="view_dropdown_list"
                    dropdownListFor="view_item"
                    data={[
                        { _id: 1, limit: 10 },
                        { _id: 2, limit: 20 },
                        { _id: 3, limit: 50 }
                    ]}
                    nameKey="limit"
                    selectedValue={limit}
                    clickHandler={handleDropdownChange}
                />
            </div>
            <div className={styles.data_table}> 
                {showTableFor === "products" ? <ProductTable /> : null}
                {showTableFor === "product_sales" ? <ProductSalesTable /> : null}
                {showTableFor === "purchase_order" ? <POTable /> : null}
            </div>
            <NextPrevView 
                skip={skip}
                limit={limit}
                totalItemCount={totalItemCount}
                onNextPrevButtonClick={navigateToNextPrevProducts}
            />
        </div>
    );

}

export default DataTable;
