import { ILocation } from "../../types/models/Location";
import { IUser } from "../../types/models/User";

type ArgsType = {
    locationList: ILocation[]
    stylistList: IUser[]
}

export const filterStylistList = ({ locationList, stylistList }: ArgsType) => {

    // If no location has been selected then return all stylistList.
    if(!locationList.length) return stylistList;

    const locationTitles = locationList.map(location => location._id);

    const newFilteredStylists: IUser[] = [];

    stylistList.forEach(stylist => {

        for(let x = 0; x < stylist.workSchedule.length; x++) {

            const schedule = stylist.workSchedule[x];

            if(locationTitles.includes(schedule.location._id)) {
                newFilteredStylists.push(stylist);
                break;
            }

        }

    });

    return newFilteredStylists;

}