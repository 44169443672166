import styles from "./Button.module.css";

interface IconButtonProps extends React.ComponentProps<"button"> {
    customClassName?: string
    buttonText?: string
    icon: React.ReactNode
    actionType: string
    itemId: string
    handleClick: (actionType: string, itemId: string) => void 
}

const IconButton: React.FC<IconButtonProps> = (props) => {

    const { 
        customClassName = "", 
        buttonText, 
        icon, 
        actionType,
        itemId,
        handleClick
    } = props;

    return (
        <button
            className={`${styles.icon_button} ${styles[customClassName]}`}
            disabled={props.disabled}
            onClick={() => handleClick(actionType, itemId)}
        >
            {icon}
            {buttonText ? <span>{buttonText}</span> : null}
        </button>
    );

}

export default IconButton;
