import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { RootState } from "../../../store";
import { config } from "../../../Constants";
import { ITreatment } from "../../../types/models/Treatment";
import { AvailableDateTimes } from "../../../types/pages/Calendar";
import { updateState } from "../../../store/calendar/calendarReducer";
import styles from "../common/Others.module.css";


const FindTimesButton: React.FC = () => {
  
    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;
    const { newAppointmentInfo } = calendarState;
    const { selectedLocation, selectedStylist } = newAppointmentInfo;
    const selectedTreatment = newAppointmentInfo.selectedTreatment as ITreatment;
    const { _id, category, title, price, duration } = selectedTreatment;

    const findAvailableStartTimes = async() => {

        const treatment = {
            categoryTitle: category.title,
            treatmentId: _id,
            treatmentTitle: title,
            treatmentPrice: price,
            treatmentDuration: duration,
            choosenStylist: selectedStylist?.fullName,
            choosenStylistId: selectedStylist?._id,
            choosenStylistImageUrl: selectedStylist?.imageUrl,
            partialPaymentRate: null
        }

        const endpoint = config.url.BACKEND_API_URL + "/appointments/find-times";
        const requestBody = {
            tenantId: user?.tenantId,
            selectedLocation,
            selectedTreatments: [treatment]
        }

        try {

            const response = await axios.post(endpoint, requestBody);
            const newAvailableDateTimes: AvailableDateTimes[] = response.data;
            dispatch(updateState({
                name: "availableDateTimes",
                value: newAvailableDateTimes
            }))

        } catch(error) {
            alert("Something went wrong");
        }


    }


    return (
        <div className = {styles.find_times_button}>
            <button onClick = {findAvailableStartTimes}>
                Find Available Start Times
            </button>
        </div>
    );

}

export default FindTimesButton;