import { useSelector, useDispatch } from "react-redux";
import { ISubscription } from "../../../types/models/Subscription";
import { IUser } from "../../../types/models/User";
import { RootState } from "../../../store";
import { selectSubPrice, selectSubPlan } from "../../../store/profile-tenant/tenantProfileReducer";
import styles from "./SubscriptionPlans.module.css";
import PriceList from "./PriceList";
import FeatureList from "./FeatureList";

const SubscriptionPlans: React.FC = () => {

    const dispatch = useDispatch();
    const tenantProfileState = useSelector((state: RootState) => state.tenantProfileState);
    const userState = useSelector((state: RootState) => state.userState);
    const user = userState.user as IUser;
    const { subscriptionList } = tenantProfileState;

    const subscribe = (subItem:ISubscription) => {

        const { companyAddress: { postalCode, isoCountryCode } } = user;

        if(!postalCode || !isoCountryCode) {
            alert("Please update address on your profile");
            return;
        }

        dispatch(selectSubPlan(subItem));
        if(user?.stripeSubscription?.subscriptionPlan?.subscriptionId === subItem.subscriptionId) {
            const filteredPrices = subItem.prices.filter(item => item.stripePriceId !== user?.stripeSubscription?.stripePriceId);
            dispatch(selectSubPrice(filteredPrices[0]) ?? null);
            return;
        }
        dispatch(selectSubPrice(subItem.prices[0]));
    }

    return (
        <div className = {styles.sub_plans}>
            <h2>Plans and prices</h2>
            <div className = {styles.sub_plan_list}>
                {subscriptionList.map((subItem: ISubscription) => {
                    return (
                        <div key = {subItem._id} className = {styles.sub_item_container}>
                            <div className = {styles.sub_item}>
                                <h3>{subItem.title}</h3>
                                <PriceList 
                                    prices = {subItem.prices}
                                />
                                <p>{subItem?.description}</p>
                                <FeatureList 
                                    features={subItem.features}
                                />
                                <button
                                    style={{
                                        backgroundColor: user.isOnTrialPeriod ? "#ffa9a58c" : "#FFAAA5" 
                                    }}
                                    disabled={user.isOnTrialPeriod ? true : false}  
                                    onClick={() => subscribe(subItem)}
                                >
                                    Update Your Plan
                                </button>
                            </div>
                        </div>
                    );
                    
                })}
            </div>
        </div>
    );
}

export default SubscriptionPlans;
