import { MdViewCompact } from "react-icons/md";
import { IGiftCard } from "../../types/models/GiftCard";
import styles from "./GiftCardTable.module.css";
import IconButton from "./IconButton";

interface ActionButtonsProps {
    handleClick: (giftCard: IGiftCard) => void
    giftCard: IGiftCard
    showButtonText?: boolean
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ giftCard, showButtonText, handleClick }) => {

    return (
        <div className={styles.action_buttons}> 
            <IconButton 
                customClassName="edit_icon_button"
                buttonText={showButtonText ? "View" : ""}
                icon={
                    <MdViewCompact 
                        size="1.2rem" 
                        color="#FFAAA5"
                    />
                }
                handleClick={handleClick}
                giftCard={giftCard}
            />
        </div>            
    );

}

export default ActionButtons;
