import LoginSignupDesignImage from "../../assets/images/login_signup_image.jpg"
import styles from "./LoginSignup.module.css";


const DesignImage: React.FC= () => {

    return (
        <div className = {styles.design_image}>
            <img 
                src = {LoginSignupDesignImage} 
                alt = "design" 
            />
        </div>
    );

}

export default DesignImage;