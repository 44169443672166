import axios from "axios";
import Cookies from "universal-cookie";
import { IUser } from "../../../types/models/User";
import { ISubscription, SubscriptionPrice } from "../../../types/models/Subscription";
import { useDispatch, useSelector } from "react-redux";
import { useStripe, useElements, CardNumberElement, IbanElement } from "@stripe/react-stripe-js";
import { Stripe, StripeElements, StripeCardNumberElement, StripeIbanElement } from "@stripe/stripe-js";
import { RootState } from "../../../store";
import { config } from "../../../Constants";
import { selectSubPlan } from "../../../store/profile-tenant/tenantProfileReducer";
import { ACTIVATE_SUBSCRIPTION } from "../../../store/profile-tenant/constants";
import { validateCreditCard } from "../../../helpers/validators/validateCreditCard";
import { validateIBanElement } from "../../../helpers/validators/validateIBanElement";
import { handleApiError } from "../../../helpers/error-handlers/handleApiError";
import styles from "./CheckoutForm.module.css";
import Button from "../../../components/profile-tenant/Button";

interface PayButtonProps {
    loading: boolean
    showVatIDInput: boolean
    vatIDErrorMsg: string | null
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    setErrorMsg: React.Dispatch<React.SetStateAction<string>>
    setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>
    setIsUpdatingSubscription: React.Dispatch<React.SetStateAction<boolean>>
}

const PayButton: React.FC<PayButtonProps> = ({ 
    loading, 
    showVatIDInput, 
    vatIDErrorMsg,
    setLoading, 
    setErrorMsg, 
    setIsSuccess, 
    setIsUpdatingSubscription 
}) => {

    const stripe = useStripe() as Stripe;
    const elements = useElements() as StripeElements;
    const cookies = new Cookies();

    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.userState);
    const user = userState.user as IUser;
    const tenantProfileState = useSelector((state: RootState) => state.tenantProfileState);
    const selectedSubscription = tenantProfileState.selectedSubscription as ISubscription;
    const selectedSubscriptionPrice = tenantProfileState.selectedSubscriptionPrice as SubscriptionPrice;
    const { vatID, selectedPaymentMethod } = tenantProfileState;

    const closeModal = () => dispatch(selectSubPlan(null));

    const handleCreateUpdateSubscription = () => {

        const condition = (user.stripeSubscription && user.stripeSubscription?.isActive);

        if(condition) {
            updateSubscription();
            return;
        }

        subscribe();

    }

    const updateSubscription = async() => {

        setLoading(true);
        setIsUpdatingSubscription(true);

        const endpoint = config.url.BACKEND_API_URL + "/subscriptions/update-subscription";
        const headers = { "auth-token": cookies.get("auth_token") };
        const requestBody = {
            subscriptionId: selectedSubscription.subscriptionId, 
            stripePriceId: selectedSubscriptionPrice.stripePriceId, 
            userId: user._id
        }

        try {

            await axios.post(endpoint, requestBody, { headers });
            setLoading(false);
            setIsSuccess(true);

        } catch(error: any) {
            const { message } = handleApiError(error);
            setErrorMsg(message);
            setLoading(false);
        }

    }
    
    const subscribe = async() => {

        if((showVatIDInput && vatID === "") || vatIDErrorMsg) {
            alert("Please enter a valid VAT ID");
            return;
        }

        if(selectedPaymentMethod === "Credit Card") {
            const message = validateCreditCard(elements);
            if(message) return alert(message);
        }

        if(selectedPaymentMethod === "Sepa Debit") {
            const message = validateIBanElement(elements);
            if(message) return alert(message);
        }

        setLoading(true);
        
        // Once validations are done, need to create the subscription now.
        const headers = { "auth-token": cookies.get("auth_token") };
        const requestBody = {
            subscriptionId: selectedSubscription.subscriptionId,
            stripePriceId: selectedSubscriptionPrice.stripePriceId,
            userId: user._id,
            vatID: vatID === "" ? null : vatID,
            selectedPaymentMethod
        }

        const endpoint = config.url.BACKEND_API_URL + "/subscriptions/create-subscription";

        try {

            const response = await axios.post(endpoint, requestBody, { headers });
            const clientSecret: string = response.data.clientSecret;

            if(selectedPaymentMethod === "Sepa Debit") {

                const ibanPaymentElement = elements.getElement(IbanElement) as StripeIbanElement;

                const { error } = await stripe.confirmSepaDebitPayment(clientSecret, {
                    payment_method: {
                        sepa_debit: ibanPaymentElement,
                        billing_details: {
                            name: `${user.firstName} ${user.lastName}`,
                            email: user.email
                        },
                    },
                    setup_future_usage: "off_session",
                });

                if(error) {
                    setErrorMsg(error.message ?? "Something went wrong, please try again later");
                    return;
                } 

                // Need to show a confirmation message to the user. The PaymentIntent is in the "processing" state.
                // SEPA Direct Debit payments are asynchronous, so funds are not immediately available.
                setIsSuccess(true);
                
            }

            if(selectedPaymentMethod === "Credit Card") {

                const creditCardNumberElement = elements.getElement(CardNumberElement) as StripeCardNumberElement;

                // confirmCardPayment api call will return either error or paymentIntent. error will be returned 
                // if user puts in invalid card details, for example - invalid card number, expiry date which is 
                // in the past or two digits CVC number.
                const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
                    payment_method: { 
                        card: creditCardNumberElement 
                    }
                });

                if(error) {
                    setLoading(false);
                    setErrorMsg(error.message ?? "Something went wrong");
                    return;
                } 
                
                if(paymentIntent.status !== "succeeded") {
                    setLoading(false);
                    setErrorMsg(paymentIntent.status ?? "Something went wrong");
                    return;
                } 

                setIsSuccess(true);
                setLoading(false);

                dispatch({ 
                    type: ACTIVATE_SUBSCRIPTION, 
                    payload: user._id 
                });

            }

        } catch(error: any) {
            const { message } = handleApiError(error);
            setErrorMsg(message);
        }
      
    }


    return (
        <div className = {styles.pay_cancel_btn}>
            <Button
                border="1px solid #FFAAA5"
                color="#FFAAA5"
                marginRight = "20px"
                onClick = {closeModal}
            >
                Cancel
            </Button>
            <Button
                backgroundColor = {loading ? "#ccc" : "#FFAAA5"}
                disabled = {loading}
                onClick = {handleCreateUpdateSubscription}
            >
                Subscribe
            </Button>
        </div>
    );

}


export default PayButton;