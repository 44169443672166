import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { config } from "../../Constants";
import { IGiftCard } from "../../types/models/GiftCard";
import { RootState } from "../../store";
import { FETCH_GIFT_CARD_DATA } from "../../store/gift-card/constants";
import { updateState, confirmGiftCardBuy } from "../../store/gift-card/giftCardReducer";
import { validateEmailAddress } from "../../helpers/validators/validateEmailAddress";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import styles from "./index.module.css";

const SaveButton: React.FC = () => {

    const dispatch = useDispatch();
    const giftCardState = useSelector((state: RootState) => state.giftCardState);
    const userState = useSelector((state: RootState) => state.userState);
    const { 
        validationError,
        validationErrorMessage,
        giftCardBuyer,
        giftCardReceiver,
        giftCardAmount, 
        paymentMethod 
    } = giftCardState; 

    const [loading, setLoading] = useState(false);

    const saveGiftCard = async() => {

        const { fullName, email, phone } = giftCardBuyer;

        // If previous attempt had any input or other validation error messages hide them now.
        if(validationError) {
            dispatch(updateState({
                name: "validationError",
                value: false
            }));
        } 
        if(validationErrorMessage) {
            dispatch(updateState({
                name: "validationErrorMessage",
                value: null
            }));
        }

        if(!giftCardAmount || !fullName || !email || !phone) {
            dispatch(updateState({
                name: "validationError",
                value: true
            }));
            return;
        }

        if(!paymentMethod) {
            dispatch(updateState({
                name: "validationErrorMessage",
                value: "Please select a payment method"
            }));
            return;
        }

        // Amount must be a number and it must be more than 0
        const newAmount = Number(giftCardAmount);
        if(isNaN(newAmount) || newAmount < 10) {
            dispatch(updateState({
                name: "validationErrorMessage",
                value: "Gift card amount must be a number and at least 10 euro"
            }));
            return;
        } 

        // Validate Buyer Email Address
        const { isEmailValid, emailValMessage } = validateEmailAddress(giftCardBuyer.email);
        if(!isEmailValid) {
            dispatch(updateState({
                name: "validationErrorMessage",
                value: emailValMessage
            }));
            return;
        }

        const isReceiver = Object.values(giftCardReceiver).some(item => !!item);

        // If any of the fields of receiver info is filled then we would assume there will be a receiver.
        if(isReceiver) {

            const { 
                fullName: receiverFullName, 
                email: receiverEmail, 
                phone: receiverPhone,
                specialNote 
            } = giftCardReceiver;

            if(!receiverFullName || !receiverEmail || !receiverPhone || !specialNote) {

                const message = `Send this gift card to somebody else? Then please fill all the required 
                fields of reciever info (address is optional). Otherwise leave them all blank.`;    

                dispatch(updateState({
                    name: "validationErrorMessage",
                    value: message
                }));

                return;

            }

            const { 
                isEmailValid: isReceiverEmailValid, 
                emailValMessage: receiverEmailValidationMessage 
            } = validateEmailAddress(receiverEmail);
            
            if(!isReceiverEmailValid) {
                dispatch(updateState({
                    name: "validationErrorMessage",
                    value: `Receiver ${receiverEmailValidationMessage}`
                }));
                return;
            }

        }

        let receiverInfo = null;
        if(isReceiver) receiverInfo = {
            ...giftCardReceiver,
            address: giftCardReceiver.address === "" ? null : giftCardReceiver.address
        }

        const endpoint = config.url.BACKEND_API_URL + "/gift-cards/buy";
        const requestBody = {
            tenantId: userState.user?.tenantId,
            stripePaymentIntentId: null,
            paymentMethod: paymentMethod.method,
            amount: newAmount,
            buyerInfo: {
                ...giftCardBuyer,
                address: giftCardBuyer.address === "" ? null : giftCardBuyer.address
            },
            receiverInfo
        };

        setLoading(true);

        try {

            const response = await axios.post(endpoint, requestBody);
            const giftCard: IGiftCard = response.data.savedGiftCard;

            dispatch(confirmGiftCardBuy(giftCard));

            dispatch({ type: FETCH_GIFT_CARD_DATA });

            setLoading(false);
    
        } catch(error) {
            setLoading(false);
            const { message } = handleApiError(error);
            dispatch(updateState({
                name: "validationErrorMessage",
                value: message
            }));
        }

    }
    
    return (
        <div className={styles.save_button}>
            <button onClick={saveGiftCard} disabled={loading}>
                Save
            </button>
        </div>
    );
}

export default SaveButton;
