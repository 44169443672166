import styles from "./index.module.css";

interface IntegrationItemButtonProps {
    buttonText: string
    args?: any
    disabled?: boolean
    onClick: (args?: any) => void
}

const IntegrationItemButton: React.FC<IntegrationItemButtonProps> = (props) => {

    const {
        buttonText,
        args,
        disabled = false,
        onClick
     } = props;

    return (
        <div className={styles.connect_button}>
            <button 
                disabled={disabled}
                onClick={() => onClick(args)}
            >
                {buttonText}
            </button>
        </div>
    );

}

export default IntegrationItemButton;