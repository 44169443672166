import { BsCheckLg } from "react-icons/bs";
import styles from "./Others.module.css";


const CheckIcon: React.FC = () => {

    return (
        <div className = {styles.check_icon} style = {{ visibility: "hidden" }}>
            <BsCheckLg 
                color="red"
            />
        </div>
    );

}

export default CheckIcon;