import styles from "./Dropdown.module.css";

interface IProps {
    elementRef: React.RefObject<HTMLDivElement>,
    handleClick: (args?: any) => void,
}


const DropdownHiddenDiv: React.FC<IProps> = ({ elementRef, handleClick }) => {

    return (
        <div 
            className = {styles.hidden_div}
            ref = {elementRef}
            onClick = {handleClick}
        >
        </div>    
    );

}


export default DropdownHiddenDiv;