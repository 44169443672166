import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { PackageTreatment } from "../../types/models/Package";
import { addNewPackageInfo, selectAllTreatment } from "../../store/packages/packageReducer";
import styles from "./SelectTreatment.module.css";
import InputField from "./InputField";
import CheckboxInputField from "./CheckboxInputField";


const SelectTreatment: React.FC = () => {

    const dispatch = useDispatch();
    const packageState = useSelector((state: RootState) => state.packageState);
    const { newPackageInfo, treatmentList } = packageState;
    const { 
        selectedTreatmentList, 
        areAllTreatmentsSelected,
        allTreatmentsSessionCount 
    } = newPackageInfo;


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, treatment: PackageTreatment) => {

        const { name, checked, value } = event.target;

        switch(name) {

            // It means user is checking/unchecking "Select All" treatments checkbox input field.
            case "selectAlltreatment":

                // Don't let user check "Select All" if he/she has checked one or more individual treatments
                if(selectedTreatmentList.length) return;

                const payload = { areAllTreatmentsSelected: checked, allTreatmentsSessionCount: "" };
                dispatch(selectAllTreatment(payload))
                return;


            // It means user is adding all treatments sessions
            case "allTreatmentsSessionCount":

                // Don't let user add sessions if he/she hasn't checked "Select All" or has added individual treatments
                if(!areAllTreatmentsSelected || selectedTreatmentList.length) return;

                const newPayload = { areAllTreatmentsSelected, allTreatmentsSessionCount: value };
                dispatch(selectAllTreatment(newPayload));
                return;



            // It means user is checking/unchecking individual treatment checkbox input field.
            case "treatment":

                // Don't let user check individual treatments if he/she has already checked "Select All"
                if(areAllTreatmentsSelected) return;

                if(checked) {
                    const newTreatment = { treatment, sessions: "" };
                    const newSelectedTreatments = [...selectedTreatmentList, newTreatment];
                    dispatch(addNewPackageInfo({
                        name: "selectedTreatmentList",
                        value: newSelectedTreatments
                    }))
                    return;
                }
    
                const filteredSelectedTreatments = selectedTreatmentList.filter(item => item.treatment._id !== treatment._id);
                dispatch(addNewPackageInfo({
                    name: "selectedTreatmentList",
                    value: filteredSelectedTreatments
                }))
                return;


            // It means user is adding discount rate to a selected treatment
            default:

                // Don't let user check individual treatments if he/she has already checked "Select All"
                if(areAllTreatmentsSelected) return;

                const newSelectedTreatments = selectedTreatmentList.map(item => {
                    if(item.treatment._id === treatment._id) {
                       return { ...item, sessions: value };
                    }
                    return item;
                });

                dispatch(addNewPackageInfo({
                    name: "selectedTreatmentList",
                    value: newSelectedTreatments
                }));
                
        }

    }

    return (
        <div className={styles.select_treatment}>
            <h2>Choose treatment and sessions</h2>
            <div className={styles.treatment_item}>
                <CheckboxInputField 
                    name="selectAlltreatment"
                    value="Select All"
                    checked={areAllTreatmentsSelected}
                    handleChange={handleChange}
                />
                <InputField 
                    customClassName="select_treatment_input"
                    type="text"
                    name="allTreatmentsSessionCount"
                    value={allTreatmentsSessionCount}
                    handleChange={handleChange}
                />
            </div>
            {treatmentList.map(treatment => {
                const { _id, title } = treatment;
                const foundSelectedTreatment = selectedTreatmentList.find(item => {
                    return item.treatment._id === _id
                });
                return (
                    <div key={_id} className={styles.treatment_item}>
                        <CheckboxInputField 
                            name="treatment"
                            value={title}
                            checked={!!foundSelectedTreatment}
                            handleChange={handleChange}
                            handleChangeArgs={treatment}
                        />
                        <InputField 
                            customClassName="select_treatment_input"
                            type="text"
                            name="discountRate"
                            value={foundSelectedTreatment ? foundSelectedTreatment.sessions.toString() : ""}
                            handleChange={handleChange}
                            handleChangeArgs={treatment}
                        />
                    </div>
                );
            })}
        </div>
    );

}


export default SelectTreatment;