import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICategory } from "../../types/models/Category";
import { ITreatment } from "../../types/models/Treatment";
import { IUser } from "../../types/models/User";
import { TreatmentState, FetchedData } from "../../types/pages/Treatment";


const initialState: TreatmentState = {
    loading: false,
    error: false,
    validationError: false,
    categoryList: [],
    treatmentList: [],
    stylistList: [],
    treatmentItemInAction: null,
    newTreatmentInfo: {
        category: null,
        title: "",
        description: "",
        duration: "",
        price: "",
        prePaymentRate: "",
        stylists: []
    },
    treatmentDataFetchInfo: {
        searchText: "",
        skip: 0, 
        limit: 5,
        totalTreatmentCount: 0
    },
    isAdding: false,
    isEditing: false,
    isDeleting: false,
    actionMessage: ""
}


const treatmentSlice = createSlice({
    name: "treatments",
    initialState,
    reducers: {

        fetchDataLoading: (state) => {
            return { ...state, loading: true }
        },

        fetchDataSuccess: (state, action: PayloadAction<FetchedData>) => {

            const { categoryList, stylistList, totalTreatmentCount, treatmentList } = action.payload;

            return {
                ...state,
                loading: false,
                categoryList: categoryList ?? state.categoryList,
                stylistList: stylistList ?? state.stylistList,
                treatmentList,
                treatmentDataFetchInfo: {
                    ...state.treatmentDataFetchInfo,
                    totalTreatmentCount
                },
                
            }

        },

        fetchDataFail: (state) => {
            return {
                ...state,
                loading: false,
                error: true
            }
        },

        updateState: (state, action: PayloadAction<{ name: string, value: any }>) => {
            const { name, value } = action.payload;
            return {
                ...state,
                [name]: value
            };
        },

        updateSearchText: (state, action: PayloadAction<string>) => {

            return {
                ...state,
                treatmentDataFetchInfo: {
                    ...state.treatmentDataFetchInfo,
                    searchText: action.payload
                }
            }

        },

        updateViewLimit: (state, action: PayloadAction<string | number>) => {
            return {
                ...state,
                treatmentDataFetchInfo: {
                    ...state.treatmentDataFetchInfo,
                    limit: action.payload
                }
            }
        },

        addNewTreatmentInfo: 
            (state, action: PayloadAction<{ name: string, value: string | IUser[] | ICategory }>) => {

            const { name, value } = action.payload;
            return {
                ...state,
                newTreatmentInfo: {
                    ...state.newTreatmentInfo,
                    [name]: value
                }
            }
        },

        openAddTreatmentModal: (state) => {
            return { ...state, isAdding: true }
        },

        closeAddTreatmentModal: (state) => {
            return { 
                ...state, 
                isAdding: false,
                newTreatmentInfo: {
                    category: null,
                    title: "",
                    description: "",
                    duration: "",
                    price: "",
                    prePaymentRate: "",
                    stylists: []
                } 
            }
        },

        openEditTreatmentModal: (state, action: PayloadAction<ITreatment>) => {
            const treatment = action.payload;
            const { category, title, description, duration, price, prePaymentRate, stylists } = treatment;
            return { 
                ...state, 
                isEditing: true,
                treatmentItemInAction: treatment,
                newTreatmentInfo: { 
                    category,
                    title, 
                    description: description ?? "", 
                    duration: duration.toString(), 
                    price: !price ? "" : price.toString(), 
                    prePaymentRate: prePaymentRate ? prePaymentRate.toString() : "", 
                    stylists
                } 
            }
        },

        closeEditTreatmentModal: (state) => {
            return { 
                ...state, 
                isEditing: false,
                treatmentItemInAction: null,
                newTreatmentInfo: {
                    category: null,
                    title: "",
                    description: "",
                    duration: "",
                    price: "",
                    prePaymentRate: "",
                    stylists: []
                } 
            }
        },

        toggleDeletePrompt: (state, action: PayloadAction<null | ITreatment>) => {
            return {
                ...state,
                treatmentItemInAction: action.payload,
                isDeleting: !state.isDeleting
            }
        },

        updateActionMessage: (state, action: PayloadAction<string>) => {
            return { ...state, actionMessage: action.payload }
        }

    }
})

export const {
    fetchDataLoading,
    fetchDataSuccess,
    fetchDataFail,
    updateState,
    updateSearchText,
    updateViewLimit,
    addNewTreatmentInfo,
    openAddTreatmentModal,
    closeAddTreatmentModal,
    openEditTreatmentModal,
    closeEditTreatmentModal,
    toggleDeletePrompt,
    updateActionMessage
} = treatmentSlice.actions;

export default treatmentSlice.reducer;