import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import { config } from "../../Constants";
import { RootState } from "../../store";
import { ICategory } from "../../types/models/Category";
import { FETCH_DATA } from "../../store/treatments/constants";
import { 
    addNewTreatmentInfo, 
    closeAddTreatmentModal, 
    closeEditTreatmentModal,
    updateState,
    updateActionMessage 
} from "../../store/treatments/treatmentReducer";
import useWindowWidth from "../../hooks/useWindowWidth";
import { Modal, ModalBackground, ModalContent } from "../../components/common/Modal";
import styles from "./index.module.css";
import InputField from "./InputField";
import TextAreaInputField from "./TextAreaInputField";
import Button from "./Button";
import DropdownList from "./DropdownList";
import AddStylist from "./AddStylist";


const AddEditTreatment = () => {

    const cookies = new Cookies();

    const dispatch = useDispatch();
    const treatmentState = useSelector((state: RootState) => state.treatmentState);
    const userState = useSelector((state: RootState) => state.userState);
    const { devicesWidth: { isLargeDevice } } = useWindowWidth();
    const { 
        newTreatmentInfo, 
        validationError, 
        isAdding, 
        isEditing, 
        treatmentItemInAction,
        treatmentDataFetchInfo,
        categoryList
    } = treatmentState;
    const { category, title, description, duration, price, prePaymentRate, stylists } = newTreatmentInfo;
    const { searchText, skip, limit } = treatmentDataFetchInfo;

    const [valErrorMsg, setValErrorMsg] = useState<string | null>(null);

    const closeModal = () => {
        if(isAdding) {
            dispatch(closeAddTreatmentModal());
            return;
        }

        dispatch(closeEditTreatmentModal());
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        dispatch(addNewTreatmentInfo({ name, value }));
    }

    const handleDropdownClick = (categoryItem: ICategory): void => {
        dispatch(addNewTreatmentInfo({ name: "category", value: categoryItem}))
    }

    const saveTreatment = async() => {

        setValErrorMsg(null);
        if(validationError) {
            dispatch(updateState({
                name: "validationError",
                value: false
            }));
        }

        if(!title || !duration) {
            dispatch(updateState({
                name: "validationError",
                value: true
            }));
            return;
        }

        if(!category || !stylists.length) {
            setValErrorMsg("Please select a category and at least one stylist");
            return;
        }

        const durationInNum = parseInt(duration);

        if(isNaN(durationInNum)) {
            setValErrorMsg("Duration must be a number");
            return;
        }

        if(price !== "" && isNaN(Number(price))) {
            setValErrorMsg("Price must be a number");
            return;
        }

        if(prePaymentRate !== "" && isNaN(Number(prePaymentRate))) {
            setValErrorMsg("Pre-payment rate must be a number");
            return;
        }

        dispatch(closeAddTreatmentModal());

        let endpoint = config.url.BACKEND_API_URL + "/treatments/create";
        let actionMessage = "Creating...";

        if(isEditing) {
            endpoint = config.url.BACKEND_API_URL + "/treatments/edit";
            actionMessage = "Updating...";
            dispatch(closeEditTreatmentModal());
        } 

        dispatch(updateActionMessage(actionMessage));

        const headers = { "auth-token": cookies.get("auth_token") };

        // parseInt() removes digits after decimal point which is needed for duration. Number() keeps
        // the digits after decimal point which is needed for price and prePaymentRate
        const requestBody = {
            tenantId: userState.user?.tenantId,
            treatmentId: treatmentItemInAction?._id,
            category: category._id,
            title: title.trim(),
            description: description === "" ? null : description.trim(),
            duration: durationInNum,
            price: price === "" ? 0 : Number(price),
            prePaymentRate: prePaymentRate ? Number(prePaymentRate) : null,
            stylists: stylists.map(item => item._id)
        }

        try {

            const response = await axios.post(endpoint, requestBody, { headers });

            dispatch(updateActionMessage(response.data));
            
            dispatch({ 
                type: FETCH_DATA, 
                payload: { 
                    tenantId: userState.user?.tenantId,
                    searchText,
                    skip,
                    limit,
                    fetchCategoryAndStylistData: "No"
                } 
            })

        } catch(error) {
            dispatch(updateActionMessage("Something went wrong"));
        }
    
    }

    
    return (
        <Modal>
            <ModalBackground />
            <ModalContent 
                width={isLargeDevice ? "45%" : "95%"} 
                height="90%"
            >
                <Button 
                    customClassName="modal_cls_btn"
                    buttonText="X"
                    handleClick={closeModal}
                />
                <div className={styles.add_edit_treatment}>
                    <h1>{isAdding ? "Add treatment" : "Edit treatment"}</h1>
                    <DropdownList
                        customClassName="category_dropdown_list"
                        data={categoryList}
                        nameKey="title" 
                        selectedValue={category?.title ?? "Select Category"}
                        clickHandler={handleDropdownClick}
                    />
                    <InputField 
                        labelText="Title"
                        type="text"
                        name="title"
                        value={title}
                        handleChange={handleChange}
                        validationError={validationError}
                        validationErrorMessage="title can't be blank"
                    />
                    <TextAreaInputField 
                        labelText="Description"
                        rows={5}
                        columns={5}
                        name="description"
                        value={description ?? ""} 
                        handleChange={handleChange}
                    />
                    <InputField 
                        labelText="Duration"
                        type="text"
                        name="duration"
                        value={duration.toString()}
                        handleChange={handleChange}
                        validationError={validationError}
                        validationErrorMessage="duration can't be blank"
                    />
                    <InputField 
                        labelText="Price"
                        type="text"
                        name="price"
                        value={price.toString()}
                        handleChange={handleChange}
                    />
                    <InputField 
                        labelText="Pre-payment Rate"
                        type="text"
                        name="prePaymentRate"
                        value={prePaymentRate ? prePaymentRate.toString() : ""}
                        handleChange={handleChange}
                    />
                    <AddStylist />
                    {
                        valErrorMsg
                        ?
                        <p className={styles.val_error_msg}>{valErrorMsg}</p>
                        :
                        null
                    }
                    <div className={styles.save_btn_wrapper}>
                        <Button 
                            customClassName="save_btn"
                            buttonText="Save"
                            handleClick={saveTreatment}
                        />
                    </div>
                </div>
            </ModalContent>
        </Modal>
    );

}


export default AddEditTreatment;