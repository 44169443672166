import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { selectStylist } from "../../store/discounts/discountReducer";
import styles from "./index.module.css";
import Header from "./Header";
import CheckboxInputField from "./CheckboxInputField";


const SelectStylist: React.FC = () => {

    const dispatch = useDispatch();
    const discountState = useSelector((state: RootState) => state.discountState);
    const { filteredStylists, selectedLocations, selectedStylists } = discountState;


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        // User must select location first.
        if(!selectedLocations.length) return alert("Please select location first.");

        const { value, checked } = event.target;

        // Selecting stylists
        if(checked) {

            const stylistActionType = "select";

            // If user is selecting all stylists then payload would be fetched stylists api data
            if(value === "Select All") {
                dispatch(selectStylist({ 
                    stylistActionType,
                    selectedStylists: filteredStylists
                }));
                return;
            } 
            
            // If user is not selecting all then we need to find the stylist and insert it into 
            // selectedStylists array.
            const foundStylist = filteredStylists.find(stylist => stylist.fullName === value);
            if(!foundStylist) return;
            dispatch(selectStylist({ 
                stylistActionType,
                selectedStylists: [...selectedStylists, foundStylist]
            }));
            return;

        }

        // Unselecting stylists
        const stylistActionType = "unselect";

        // if user is unselecting all then payload would be empty array
        if(value === "Select All") {
            dispatch(selectStylist({ 
                stylistActionType,
                selectedStylists: []
            }));
            return;
        } 

        // If user is not unselecting all then need to filter the selectedStylists
        const filteredSelectedStylists = selectedStylists.filter(stylist => stylist.fullName !== value);
        dispatch(selectStylist({
            stylistActionType,
            selectedStylists: filteredSelectedStylists
        }));

    }


    return (
        <div className = {styles.select_stylist}>
            <Header text = "Select Stylist" />
            <CheckboxInputField 
                name = "selectStylist"
                value = "Select All"
                checked = {filteredStylists.length === selectedStylists.length}
                handleChange = {handleInputChange} 
            />
            {filteredStylists.map(stylist => {
                const isChecked = selectedStylists.some(item => item._id === stylist._id);
                return (
                    <CheckboxInputField 
                        key = {stylist._id.toString()}
                        name = "selectStylist"
                        value = {stylist.fullName}
                        checked = {isChecked}
                        handleChange = {handleInputChange} 
                    />
                );
            })}
        </div>
    );

}


export default SelectStylist;