import { call, put, takeLatest } from "redux-saga/effects";
import { FETCH_PACKAGE_DATA } from "../store/packages/constants";
import { FetchDataQueryParams, FetchedData } from "../types/pages/Package";
import { 
    fetchDataFail,
    fetchDataLoading,
    fetchDataSuccess
} from "../store/packages/packageReducer";
import { fetchPackageApiData } from "./api/packages/fetchPackageApiData";


function* handleFetchData(action: { type: string, payload: FetchDataQueryParams }) {

    try {

        yield put(fetchDataLoading())
        const result: FetchedData = yield call(fetchPackageApiData, action.payload);
        
        yield put(fetchDataSuccess(result))

    } catch(error) {
        yield put(fetchDataFail())
    }

}


export default function* packageSaga() {
    yield takeLatest(FETCH_PACKAGE_DATA, handleFetchData);
}
