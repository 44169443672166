import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import useClickOutside from "../../../hooks/useClickOutside";
import { RootState } from "../../../store";
import { ClientItem } from "../../../types/pages/Calendar";
import { config } from "../../../Constants";
import { updateState } from "../../../store/calendar/calendarReducer";
import { getAuthTokenConfig } from "../../../helpers/others/getAuthTokenConfig";
import { handleApiError } from "../../../helpers/error-handlers/handleApiError";
import styles from "./ClientSearch.module.css";
import LoadingError from "../../../components/common/loading-error/LoadingError";
import InputField from "../common/InputField";

const ClientSearch: React.FC = () => {

    const { elementRef, isVisible, setIsVisible } = useClickOutside({ exceptions: [] });
    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;
    const { clientSearchText, clientList, clientInAction } = calendarState;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {

        if(!clientSearchText) return;
        setError("");

        // Calling setTimeout directly will make api call for each character typed. But assigning
        // this function to a variable re-initializes the timer. So, just one api call will be made
        // when user stops typing.
        const delayAPICall = setTimeout(async () => {

            // When user searchs for phone numbers he/she might want to start with + icon. But searchText containing some
            // special characters like + will cause the server crash. So we need to remove the special characters.
            const specialCharacterFreeSearchText = clientSearchText.replace(/[^a-zA-Z0-9.@ ]/g, "");

            // After removing special characters searchText could be empty string. Like if user is searching by phone 
            // number and he/she starts with + icon then after removing it searchText would be empty string. In such
            // cases we should not make the api call. Because empty string will fetch all the appointments which is
            // going to take a lot of time and will have a serious hit on performance. So we will only make api call
            // if searchText length is at least 2 characters. It will further improve the performance.
            if(specialCharacterFreeSearchText.length < 2) return;

            const queryParams = `searchText=${specialCharacterFreeSearchText}`;
            const endpoint = config.url.BACKEND_API_URL + "/appointment-clients/search?" + queryParams;
            const authConfig = getAuthTokenConfig();

            setLoading(true);
            setIsVisible(true)

            try {

                const response = await axios.get(endpoint, authConfig);
                const clientList = response.data.clientList;
                dispatch(updateState({
                    name: "clientList",
                    value: clientList
                }));

                setLoading(false);

            } catch(error) {
                const { message } = handleApiError(error)
                setLoading(false);
                setError(message)
            }

        }, 500);

        return () => {
            clearTimeout(delayAPICall);
        } 

    }, [clientSearchText, dispatch, setIsVisible, user?.tenantId]);


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const { value } = event.target;

        dispatch(updateState({
            name: "clientSearchText",
            value
        }))


        if(!value && clientList.length) {
            dispatch(updateState({
                name: "clientList",
                value: []
            }))
        } 

    }

    const openClientDetailsModal = (client: ClientItem) => {
        dispatch(updateState({
            name: "clientInAction",
            value: client
        }));
        dispatch(updateState({
            name: "clientList",
            value: []
        }));
        dispatch(updateState({
            name: "clientSearchText",
            value: ""
        }));
    }

    return (
        <div className={styles.client_search}>
            <InputField
                customClassName="client_search" 
                type="text" 
                name="clientSearch" 
                placeholder="Search"
                value={clientSearchText} 
                handleChange={handleInputChange} 
            />
            {
                (clientSearchText && !clientInAction && isVisible)
                &&
                <div 
                    ref={elementRef}
                    className={styles.client_name_list}
                >
                    {
                        loading
                        ?
                        <LoadingError 
                            message = "Loading...."
                        />
                        :
                        error
                        ?
                        <LoadingError 
                            message = {error} 
                        />
                        :
                        clientList.length < 1
                        ?
                        <LoadingError 
                            message = "No client found" 
                        />
                        :
                        <ul>
                            {clientList.map(client => {
                                return (
                                    <li key={client.email}>
                                        <button onClick={() => openClientDetailsModal(client)}>
                                            {client.name}
                                        </button>
                                    </li>
                                );
                            })}
                        </ul>
                    }
                </div>
            }
        </div>
    );

}

export default ClientSearch;