import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { UserAuth } from "../../auth/UserAuth";
import { FETCH_COUPON_DATA } from "../../store/coupons/constants";
import { handleChange } from "../../store/coupons/couponReducer";
import styles from "./index.module.css";
import SearchAdd from "./SearchAdd";
import AddEditCoupon from "./AddEditCoupon";
import CouponTable from "./CouponTable";
import DeleteCoupon from "./DeleteCoupon";
import ActionMessage from "../../components/common/messages/ActionMessage";


const CouponList = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const couponState = useSelector((state: RootState) => state.couponState);
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;
    const { 
        isAdding, 
        isEditing, 
        isDeleting, 
        couponItemInAction,
        actionMessage,
        searchText, 
        skip, 
        limit
    } = couponState;

    useEffect(() => {

        if(!UserAuth()) {
            navigate("/");
            return;
        }

        if(!user) return;

        // User must be on trial period or must have bought a sub plan to access the coupons
        if(!user.stripeSubscription && !user.isOnTrialPeriod) {
            navigate("/");
            return;
        }

        dispatch({ 
            type: FETCH_COUPON_DATA, 
            payload: { 
                searchText, 
                skip, 
                limit,
                fetchTreatmentData: true 
            } 
        });
        
    }, [user?._id]);

    const hideActionMessage = () => {
        dispatch(handleChange({
            name: "actionMessage",
            value: ""
        }));
    }

  
    return (
        <div className = {styles.coupons_page}>
            {
                actionMessage
                &&
                <ActionMessage 
                    actionMessage = {actionMessage}
                    hideActionMessage={hideActionMessage}
                />
            }
            <SearchAdd />
            <CouponTable />
            {(isAdding || isEditing) && <AddEditCoupon />}
            {(isDeleting && couponItemInAction) && <DeleteCoupon />}
        </div>
    );

}


export default CouponList;