import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../../types/models/User";
import { TenantProfileState, FetchedApiData } from "../../types/pages/TenantProfile";
import { ISubscription, SubscriptionPrice } from "../../types/models/Subscription";


const initialState: TenantProfileState = {
    loading: false,
    error: false,
    subLoading: false,
    subErrorMsg: "",
    isSubActivationASuccess: false,
    validationError: false,
    invoiceList: [],
    subscriptionList: [],
    tenantInfo: {
        firstName: "",
        lastName: "",
        fullName: "",
        email: "",
        phone: "",
        companyName: "",
        companyAddress: {
            addressLine1: "",
            addressLine2: "",
            city: "",
            postalCode: "",
            country: "",
            isoCountryCode: ""
        },
        allowOnlineDepositPaymentOnly: false
    },
    vatID: "",
    isTenantInfoEditing: false,
    selectedSubscriptionPrice: null,
    selectedSubscription: null,
    selectedPaymentMethod: "Credit Card",
    isUpdatingPaymentMethod: false
}


const tenantProfileSlice = createSlice({
    name: "profile_tenant",
    initialState,
    reducers: {

        fetchDataLoading: (state) => { 
            return { ...state, loading: true } 
        },
        
        fetchDataSuccess: (state, action: PayloadAction<FetchedApiData>) => {

            const { invoiceList, subscriptionList } = action.payload;
            
            return {
                ...state,
                loading: false,
                invoiceList,
                subscriptionList
            };
        },

        fetchDataFail: (state) => {
            return { 
                ...state, 
                loading: false,
                error: true 
            };
        },

        updateState: (state, action: PayloadAction<{ name: string, value: any }>) => {
            const { name, value } = action.payload;
            return {
                ...state,
                [name]: value
            }
        },

        activateSubLoading: (state) => {
            return { ...state, subLoading: true };
        },

        activateSubSuccess: (state, action: PayloadAction<IUser>) => {
            return { 
                ...state, 
                subLoading: false,
                user: action.payload,
                isSubActivationASuccess: true

            }
        },

        activateSubFail: (state, action: PayloadAction<string>) => {
            return { 
                ...state, 
                subLoading: false,
                subErrorMsg: action.payload
            }
        },

        toggleEditTenantInfoModal: (state, action: PayloadAction<IUser | null>) => {

            const userItem = action.payload;

            const companyAddress = {
                addressLine1: userItem?.companyAddress.addressLine1 ?? "",
                addressLine2: userItem?.companyAddress.addressLine2 ?? "",
                city: userItem?.companyAddress.city ?? "",
                postalCode: userItem?.companyAddress.postalCode ?? "",
                country: userItem?.companyAddress.country ?? "",
                isoCountryCode: userItem?.companyAddress.isoCountryCode ?? ""
            };

            if(userItem !== null) {
                return {
                    ...state,
                    isTenantInfoEditing: true,
                    tenantInfo: {
                        firstName: typeof userItem.firstName !== "undefined" ? userItem.firstName : "",
                        lastName: typeof userItem.lastName !== "undefined" ? userItem.lastName : "",
                        fullName: typeof userItem.fullName !== "undefined" ? userItem.fullName : "",
                        email: userItem.email,
                        phone: userItem.phone,
                        companyName: userItem.companyName,
                        companyAddress,
                        allowOnlineDepositPaymentOnly: userItem.allowOnlineDepositPaymentOnly
                    }
                    
                }
            } 

            return {
                ...state,
                isTenantInfoEditing: false,
                tenantInfo: {
                    firstName: "",
                    lastName: "",
                    fullName: "",
                    email: "",
                    phone: "",
                    companyName: "",
                    companyAddress: {
                        addressLine1: "",
                        addressLine2: "",
                        city: "",
                        postalCode: "",
                        country: "",
                        isoCountryCode: ""
                    },
                    allowOnlineDepositPaymentOnly: false
                }
            }
    
        },

        addTenantInfo: (state, action: PayloadAction<{ name: string, value: any }>) => {
            const { name, value } = action.payload
            return {
                ...state,
                tenantInfo: {
                    ...state.tenantInfo,
                    [name]: value
                }
            }

        },

        toggleInputValidationError: (state) => {
            return { 
                ...state, 
                validationError: !state.validationError 
            };
        },

        selectSubPrice: (state, action: PayloadAction<SubscriptionPrice>) => {
            return {
                ...state,
                selectedSubscriptionPrice: action.payload
            }
        },

        selectSubPlan: (state, action: PayloadAction<ISubscription | null>) => {
            return {
                ...state,
                selectedSubscription: action.payload
            }
        },

        selectSubPaymentMethod: (state, action: PayloadAction<string>) => {
            return { ...state, selectedPaymentMethod: action.payload };
        },

        closeCheckoutForm: (state) => {
            return {
                ...state,
                selectedSubscription: null,
                selectedSubscriptionPrice: null,
                selectedPaymentMethod: "Credit Card",
                vatID: ""
            }
        },

        openUpdatePaymentMethodModal: (state) => {
            return { 
                ...state, 
                isUpdatingPaymentMethod: true 
            }
        },

        closeUpdatePaymentMethodModal: (state) => {
            return { 
                ...state, 
                isUpdatingPaymentMethod: false,
                selectedPaymentMethod: "Credit Card", 
            }
        }

    }
    
});


export const { 
    fetchDataLoading,
    fetchDataSuccess,
    fetchDataFail,
    updateState,
    activateSubLoading,
    activateSubSuccess,
    activateSubFail, 
    addTenantInfo,
    toggleEditTenantInfoModal,
    toggleInputValidationError,
    selectSubPrice,
    selectSubPlan,
    selectSubPaymentMethod,
    closeCheckoutForm,
    openUpdatePaymentMethodModal,
    closeUpdatePaymentMethodModal
} = tenantProfileSlice.actions;

export default tenantProfileSlice.reducer;

