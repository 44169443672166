import styles from "./InputFields.module.css";

interface TextAreaInputFieldProps {
    customClassName?: string
    labelText?: string
    datatype?: string
    rows: number
    columns: number
    name: string 
    value: string 
    required?: boolean
    validationError?: boolean
    validationErrorMessage?: string
    handleChange: (event: React.ChangeEvent<HTMLTextAreaElement>, args?: any) => void
    args?: any
}


const TextAreaInputField: React.FC<TextAreaInputFieldProps> = (props) => {

    const { 
        customClassName = "",
        rows, 
        columns,
        labelText, 
        datatype,
        name, 
        value, 
        required = false,
        validationError, 
        validationErrorMessage, 
        handleChange, 
        args,
    } = props;

    return (
        <div className={`${styles.textarea_input_field} ${styles[customClassName]}`}>
            {
                labelText 
                ? 
                <label className={styles.label}>
                    {labelText} {required ? <span>*</span> : null}
                </label> 
                : 
                null
            }
            <textarea 
                name={name}
                value={value}
                rows={rows}
                cols={columns}
                data-type={datatype}
                onChange={(event) => handleChange(event, args)}
            />
            <p 
                className={styles.input_val_error_msg}
                style={{ display: validationError && !value ? "block" : "none" }}
            >
                {validationErrorMessage}
            </p>
        </div>
    );

}


export default TextAreaInputField;