import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { UserAuth } from "../../auth/UserAuth";
import { FETCH_REPORT_DATA } from "../../store/reports/constants";
import { getDefaultStartEndDateString } from "../../helpers/reports/getDefaultStartEndDateString";
import { getClientCSVContent } from "../../helpers/reports/getClientCSVContent";
import { getAppointmentCSVContent } from "../../helpers/reports/getAppointmentCSVContent";
import { getOnlinePaymentCSVContent } from "../../helpers/reports/getOnlinePaymentCSVContent";
import { getInvoiceCSVContent } from "../../helpers/reports/getInvoiceCSVContent";
import { getStylistInvoiceCSVContent } from "../../helpers/reports/getStylistInvoiceCSVContent";
import { downloadCSV } from "../../helpers/reports/downloadCSV";
import styles from "./index.module.css";
import ReportOverview from "./ReportOverview";
import Filters from "./Filters";
import ClientTable from "./ClientTable";
import AppointmentTable from "./AppointmentTable";
import OnlinePaymentTable from "./OnlinePaymentTable";
import InvoiceTable from "./InvoiceTable";
import StylistInvoiceTable from "./StylistInvoiceTable";
import ClientPackageList from "./ClientPackageList";
import TableButtons from "./TableButtons";
import ExportCSVButton from "./ExportCSVButton";


const Reports = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const reportState = useSelector((state: RootState) => state.reportState);
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;
    const { 
        appointmentList, 
        clientList, 
        tenantInvoiceList,
        freelanceStylistInvoiceList,
        showingTableFor 
    } = reportState;


    useEffect(() => {

        if(!UserAuth()) {
            navigate("/");
            return;
        }

        if(!user) return;

        // User must be on trial period or must have bought a sub plan to access the treatments
        if(!user.stripeSubscription && !user.isOnTrialPeriod) {
            navigate("/");
            return;
        }

        const { defaultStartDateString, defaultEndDateString } = getDefaultStartEndDateString();

        dispatch({ 
            type: FETCH_REPORT_DATA, 
            payload: { 
                startDateString: defaultStartDateString,
                endDateString: defaultEndDateString,
                fetchOtherData: "true"
            } 
        });
        
    }, [user?._id]);

    const exportToCSV = () => {

        let csvContent = "";

        if(showingTableFor === "client") csvContent = getClientCSVContent(clientList);
        if(showingTableFor === "appointment") csvContent = getAppointmentCSVContent(appointmentList);
        if(showingTableFor === "online_payment") csvContent = getOnlinePaymentCSVContent(appointmentList);
        if(showingTableFor === "invoice") csvContent = getInvoiceCSVContent(tenantInvoiceList);
        if(showingTableFor === "stylist_invoice") csvContent = getStylistInvoiceCSVContent(freelanceStylistInvoiceList);

        downloadCSV(csvContent)

    };
  
    return (
        <div className = {styles.treatments_page}>
            <ReportOverview />
            <Filters />
            <TableButtons />
            <div className={styles.data_table}>
                {
                    showingTableFor !== "client_bought_package"
                    ?
                    <ExportCSVButton 
                        exportToCSV={exportToCSV}
                    />
                    :
                    null
                }
                <div className={styles.table_container}>
                    {showingTableFor === "client" ? <ClientTable /> : null}
                    {showingTableFor === "appointment" ? <AppointmentTable /> : null}
                    {showingTableFor === "online_payment" ? <OnlinePaymentTable /> : null}
                    {showingTableFor === "invoice" ? <InvoiceTable /> : null}
                    {showingTableFor === "stylist_invoice" ? <StylistInvoiceTable /> : null}
                    {showingTableFor === "client_bought_package" ? <ClientPackageList /> : null}
                </div>
            </div>
        </div>
    );

}


export default Reports;