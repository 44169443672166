import styles from "./Button.module.css";

interface ButtonProps {
    customClassName?: string
    buttonText: string
    disabled?: boolean
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const Button: React.FC<ButtonProps> = (props) => {

    const { 
        customClassName = "", 
        buttonText, 
        disabled = false, 
        handleClick 
    } = props;

    return (
        <button 
            className={`${styles[customClassName]}`}
            disabled={disabled}
            onClick={handleClick}
        >
            {buttonText}
        </button>
    );

}


export default Button;