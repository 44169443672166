import { Link } from "react-router-dom";
import LogoImage from "../../assets/images/logo.png";
import styled from "styled-components";

interface MainLogoProps {
    logoWidth?: string
    marginBottom?: string
}


export const MainLogo: React.FC<MainLogoProps> = ({ logoWidth, marginBottom }) => {

    return (
        <Container 
            marginBottom = {marginBottom}
            logoWidth = {logoWidth}
        >
            <Link to = "/" style={{ opacity: "1" }}>
                <img
                    src ={LogoImage}
                    alt = "logo"
                />
            </Link>
        </Container>
    );

}

const Container = styled.div<MainLogoProps>`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: ${({ marginBottom }) => marginBottom ?? "30px" };
    > a {
        padding: 5px;
    }
    > a > img {
        width: ${({ logoWidth }) => logoWidth ?? "50px" };
        height: 100%;
        vertical-align: bottom;
    }
`