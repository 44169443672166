import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GiftCardState, FetchedData } from "../../types/pages/GiftCard";
import { IGiftCard } from "../../types/models/GiftCard";

const initialState: GiftCardState = {
    loading: false,
    errorMsg: null,
    validationError: false,
    validationErrorMessage: null,
    searchText: "",
    skip: 0,
    limit: 5,
    totalGiftCardCount: 0,
    giftCardList: [],
    giftCardInAction: null,
    isBuying: false,
    giftCardAmount: "",
    paymentMethod: null,
    giftCardBuyer: {
        fullName: "",
        email: "",
        phone: "",
        address: ""
    },
    giftCardReceiver: {
        fullName: "",
        email: "",
        phone: "",
        address: "",
        specialNote: ""
    }
}

const giftCardSlice = createSlice({
    name: "gift_card",
    initialState,
    reducers: {

        fetchDataLoading: (state) => {
            return {
                ...state,
                loading: true
            }
        },

        fetchDataSuccess: (state, action: PayloadAction<FetchedData>) => {
            const { giftCardList, totalGiftCardCount } = action.payload;
            return {
                ...state,
                loading: false,
                giftCardList,
                totalGiftCardCount
            }
        },

        fetchDataFail: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                loading: false,
                errorMsg: action.payload
            }
        },

        updateState: (state, action: PayloadAction<{ name: string, value: any }>) => {
            const { name, value } = action.payload;
            return {
                ...state,
                [name]: value
            };
        },

        addBuyerInfo: 
            (state, action: PayloadAction<{ name: string, value: string }>) => {
            const { name, value } = action.payload;
            return {
                ...state,
                giftCardBuyer: {
                    ...state.giftCardBuyer,
                    [name]: value
                }
            }
        },

        addReceiverInfo: 
            (state, action: PayloadAction<{ name: string, value: string }>) => {
            const { name, value } = action.payload;
            return {
                ...state,
                giftCardReceiver: {
                    ...state.giftCardReceiver,
                    [name]: value
                }
            }
        },

        confirmGiftCardBuy: (state, action: PayloadAction<IGiftCard>) => {
            return { 
                ...state, 
                giftCardInAction: action.payload,
                validationError: false,
                giftCardAmount: "",
                paymentMethod: null,
                giftCardBuyer: {
                    fullName: "",
                    email: "",
                    phone: "",
                    address: ""
                },
                giftCardReceiver: {
                    fullName: "",
                    email: "",
                    phone: "",
                    address: "",
                    specialNote: ""
                },
            }
        }

    }
})

export const {
    fetchDataLoading,
    fetchDataSuccess,
    fetchDataFail,
    updateState,
    addBuyerInfo,
    addReceiverInfo,
    confirmGiftCardBuy,
} = giftCardSlice.actions;

export default giftCardSlice.reducer;
