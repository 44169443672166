import styles from "./index.module.css";


const Header: React.FC<{ text: string }> = ({ text }) => {
    return (
        <h4 className = {styles.header}>{text}</h4>
    );
}


export default Header;