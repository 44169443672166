import { RiFolderReceivedLine } from "react-icons/ri";
import styles from "./DataTable.module.css";
import EditIcon from "../../components/icons/EditIcon";
import DeleteIcon from "../../components/icons/DeleteIcon";
import SellIcon from "../../components/icons/SellIcon";
import IconButton from "./IconButton";

interface ActionButtonProps {
    actionTypeList: string[]
    itemId: string
    showButtonText?: boolean
    disableReceiveButton?: boolean
    handleClick: (actionType: string, itemId: string) => void
}

const ActionButtons: React.FC<ActionButtonProps> = (props) => {

    const { 
        actionTypeList,
        itemId,
        showButtonText = true, 
        disableReceiveButton,
        handleClick 
    } = props;

    return (
        <div className={styles.action_buttons}> 
            {
                actionTypeList.includes("sell")
                ?
                <IconButton 
                    buttonText={showButtonText ? "Sell" : ""}
                    icon={<SellIcon />}
                    actionType={"sell"}
                    itemId={itemId}
                    handleClick={handleClick}
                />
                :
                null
            }
            {
                actionTypeList.includes("receive")
                ?
                <IconButton 
                    buttonText={showButtonText ? "Receive" : ""}
                    icon={
                        <RiFolderReceivedLine 
                            size={"1.4rem"}
                            color="#FFAAA5"
                        />
                    }
                    actionType={"receive"}
                    itemId={itemId}
                    disabled={disableReceiveButton}
                    handleClick={handleClick}
                />
                :
                null
            }
            {
                actionTypeList.includes("edit")
                ?
                <IconButton 
                    buttonText={showButtonText ? "Edit" : ""}
                    icon={<EditIcon />}
                    actionType="edit"
                    itemId={itemId}
                    handleClick={handleClick}
                />
                :
                null
            }
            {
                actionTypeList.includes("delete")
                ?
                <IconButton 
                    buttonText={showButtonText ? "Delete" : ""}
                    icon={<DeleteIcon />}
                    actionType="delete"
                    itemId={itemId}
                    handleClick={handleClick}
                />
                :
                null
            }
        </div>            
    );

}

export default ActionButtons;