import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { FETCH_APPOINTMENT_DATA } from "../../../store/calendar/constants";
import { 
    updateState, 
    toggleActivityByModal, 
    updateAppointment 
} from "../../../store/calendar/calendarReducer";
import { 
    Modal, 
    ModalBackground, 
    ModalContent, 
    ModalCloseButton 
} from "../../../components/common/Modal";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { formatDateTime } from "../../../helpers/date-time/dateTimeHelpers";
import { rescheduleAppointment } from "../../../helpers/calendar/rescheduleAppointment";
import styles from "./RescheduleModal.module.css";
import SelectDate from "./SelectDate";
import SelectStylist from "./SelectStylist";
import SelectTime from "./SelectTime";
import FindTimesButton from "./FindTimesButton";


const RescheduleModal: React.FC = () => {

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const userState = useSelector((state: RootState) => state.userState);
    const { devicesWidth: { isLargeDevice } } = useWindowWidth();
    const {
        calendarViewType,
        dayViewDate,
        monthViewDates,
        weekViewDates,
        appointmentInAction, 
        activityBy,
        doNotSendEmailToClient,
        newAppointmentInfo,
        availableDateTimes 
    } = calendarState;

    const { selectedStylist, startTimeForRescheduling } = newAppointmentInfo;

    const closeModal = () => {
        dispatch(updateState({
            name: "isRescheduling",
            value: false
        }))
    }

    const refetchAppointmentData = () => {

        if(!userState.user) return;

        const oneDayMS = 86400000;

        let dates: Date[] = [];

        if(calendarViewType === "day") dates = [dayViewDate];
        if(calendarViewType === "week") dates = [...weekViewDates];
        if(calendarViewType === "month") dates = [...monthViewDates];

        const endDatePlusOne = new Date(dates[dates.length - 1].getTime() + oneDayMS);

        const { dateString: startDateString } = formatDateTime(dates[0]);
        const { dateString: endDateString } = formatDateTime(endDatePlusOne);

        dispatch({ 
            type: FETCH_APPOINTMENT_DATA, 
            payload: {
                tenantId: userState.user.tenantId,
                startDateString,
                endDateString
            }
        });

    }

    const callRescheduleAppointment = async() => {

        const args = {
            appointment_id: appointmentInAction?._id ?? "",
            doNotSendEmailToClient,
            activityBy,
            selectedStylist: selectedStylist?.fullName ?? "",
            selectedStylistId: selectedStylist?._id ?? "",
            selectedDateTime: startTimeForRescheduling
        }

        dispatch(toggleActivityByModal({
            activityType: "", 
            activityByLabel: "" 
        }));
        dispatch(updateState({
            name: "appointmentInAction",
            payload: null
        }));

        const message = await rescheduleAppointment(args);

        dispatch(updateAppointment(message));

        refetchAppointmentData();

    }

    return (
        <Modal>
            <ModalBackground />
            <ModalCloseButton 
                onClick={closeModal}
                rightPosition = {!isLargeDevice ? "50%" : "40px"}
                topPosition = {!isLargeDevice ? "30px" : "40px"}
                fontSize = {!isLargeDevice ? "1.4rem" : "2rem"}
            >
                X
            </ModalCloseButton>
            <ModalContent
                width = {isLargeDevice ? "70%" : "95%"}
                height="80%"
            >
                <SelectStylist />
                <FindTimesButton />
                {
                    availableDateTimes.length > 0
                    ?
                    <>
                        <SelectDate />
                        <SelectTime />
                        <div className={styles.confirm_button}>
                            <button onClick={callRescheduleAppointment}>
                                Confirm
                            </button>
                        </div>
                    </>
                    :
                    null
                }
            </ModalContent>
        </Modal>
    );

}

export default RescheduleModal;