import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { addNewFollowUpEmailInfo } from "../../store/emails/emailReducer";
import CheckboxInputField from "../../components/common/input-fields/CheckboxInputField";
import styles from "./SelectLocationTreatment.module.css";

const SelectLocation: React.FC = () => {

    const dispatch = useDispatch();
    const emailState = useSelector((state: RootState) => state.emailState);
    const { newFollowUpEmailInfo, locationList } = emailState;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, locationId: string) => {

        const { name, checked } = event.target;

        if(name === "selectAllLocation") {
            const locationIdList = locationList.map(item => item._id);
            dispatch(addNewFollowUpEmailInfo({
                name: "locationList",
                value: checked ? locationIdList : []
            }));
            return;
        }

        if(checked) {
            dispatch(addNewFollowUpEmailInfo({
                name: "locationList",
                value: [...newFollowUpEmailInfo.locationList, locationId]
            }));
            return;
        }

        const filteredLocationList = newFollowUpEmailInfo.locationList.filter(item => item !== locationId);
        dispatch(addNewFollowUpEmailInfo({
            name: "locationList",
            value: filteredLocationList
        }));

    }

    return (
        <div className={styles.select_location}>
            <h2 className={styles.select_location_treatment_header}>Select Location</h2>
            <div className={styles.location_item}>
                <CheckboxInputField 
                    name="selectAllLocation"
                    value="Select All"
                    checked={locationList.length === newFollowUpEmailInfo.locationList.length}
                    handleChange={handleChange}
                />
            </div>
            {locationList.map(location => {
                const { _id, title } = location;
                const isLocationAdded = newFollowUpEmailInfo.locationList.some(item => item === _id);
                return (
                    <div key= {_id} className={styles.location_item}>
                        <CheckboxInputField 
                            name="location"
                            value={title}
                            checked={isLocationAdded}
                            handleChange={handleChange}
                            handleChangeArgs={location._id}
                        />
                    </div>
                );
            })}
        </div>
    );

}


export default SelectLocation;