import { ITreatment } from "../../types/models/Treatment";
import styles from "./Button.module.css";


interface IProps {
    customClassName?: string,
    buttonText?: string,
    icon: React.ReactNode, 
    handleClick: (args: { actionType: string, treatment: ITreatment }) => void, 
    args?: any, 
}


const IconButton: React.FC<IProps> = (props) => {

    const { customClassName = "", buttonText, icon, handleClick, args } = props;

    return (
        <button
            className = {`${styles.icon_button} ${styles[customClassName]}`}
            onClick = {() => handleClick(args)}
        >
            {icon}
            {buttonText ? <span>{buttonText}</span> : null}
        </button>
    );

}


export default IconButton;