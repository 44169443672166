import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import useClickOutside from "../../hooks/useClickOutside";
import { RootState } from "../../store";
import { IProductCategory } from "../../types/models/Product";
import { config } from "../../Constants";
import { updateState, addNewProductInfo } from "../../store/products/productReducer";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import styles from "./AddEditProduct.module.css";
import InputField from "../../components/common/input-fields/InputField";

interface SelectCategoryProps {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const SelectCategory: React.FC<SelectCategoryProps> = ({ handleChange }) => {

    const { isVisible, setIsVisible, elementRef } = useClickOutside({ exceptions: [] })

    const dispatch = useDispatch();
    const productState = useSelector((state: RootState) => state.productState);
    const { doNotShowProductCategoryList, productCategoryList, newProductInfo } = productState;

    useEffect(() => {

        // If user is editing a product then newProductInfo.category state will be updated
        // with the selected product's category. So, this change in category will re-trigger
        // this useEffect hook and make the api call. But we don't want it, because we have
        // full category text. So need to return.
        if(doNotShowProductCategoryList) return;

        // if category._id is not null that means a category has been selected from 
        // the searched list. As any change in category triggers re-run of this useEffect
        // so we need to return it from here if a category has been selected.
        if(newProductInfo.category._id) return;

        // Only make api calls if user has typed in something and it's at least 2 characters long
        if(newProductInfo.category.name.length < 2) return;

        const cookies = new Cookies();

        const timer = setTimeout(() => {

            (async function fetchCategory() {

                const endpoint = config.url.BACKEND_API_URL + `/products/categories?searchText=${newProductInfo.category.name}`;
                const token = cookies.get("auth_token"); 
                const axiosConfig = {
                    headers: {
                        "Authorization": `Bearer ${token}`, 
                        "auth-token": token 
                    }
                }

                try {

                    const response = await axios.get(endpoint, axiosConfig);
                    dispatch(updateState({
                        name: "productCategoryList",
                        value: response.data.categoryList
                    }));
                    setIsVisible(true);
                
                } catch(error) {
                    const { message } = handleApiError(error);
                    alert(message);
                }

            })();

        }, 500);

        return () => clearTimeout(timer);

    }, [newProductInfo.category, dispatch, setIsVisible, doNotShowProductCategoryList])

    const chooseCategory = (category: IProductCategory) => {

        dispatch(addNewProductInfo({
            name: "category",
            value: { 
                _id: category._id,
                name: category.name
            }
        }));

        setIsVisible(false);

        dispatch(updateState({
            name: "productCategoryList",
            value: []
        }));

    }

    return (
        <div className={styles.select_category_brand}>
            <InputField 
                labelText="Category"
                name="category"
                autoComplete="off"
                value={newProductInfo.category.name}
                handleChange={handleChange}
            />
            {
                (productCategoryList.length > 0 && isVisible)
                ?
                <div className={styles.category_brand_list} ref={elementRef}>
                    <ul>
                        {productCategoryList.map(item => {
                            return (
                                <li key={item._id} onClick={() => chooseCategory(item)}>
                                    {item.name}
                                </li>
                            ) 
                        })}
                    </ul>
                </div>
                :
                null
            }
        </div>
    );

}

export default SelectCategory;
