import { useSelector } from "react-redux";
import { RootState } from "../../store";
import useWindowWidth from "../../hooks/useWindowWidth";
import styles from "./DataTable.module.css";
import TableMessageTR from "./TableMessageTR";
import ResponsiveProductSalesTable from "./ResponsiveProductSalesTable";

const ProductSalesTable = () => {

    const productState = useSelector((state: RootState) => state.productState);
    const { devicesWidth: { isLargeDevice } } = useWindowWidth();

    const { loading, productSaleList } = productState;
  
    return (
        <>
            {
                isLargeDevice
                ?
                <table>
                    <thead>
                        <tr className={styles.heading_tr}>
                            <th>Name</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Total Price</th>
                            <th>Sale Advisor</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading
                            ?
                            <TableMessageTR 
                                colSpan={5}
                                message="Loading..."
                            />
                            :
                            productSaleList.length < 1
                            ?
                            <TableMessageTR 
                                colSpan={5}
                                message="No Product Sales To Show"
                            />
                            :
                            productSaleList.map(productSale => {

                                const { _id, product, price, quantity, saleAdvisor } = productSale;

                                return (
                                    <tr key={_id} className={styles.data_tr}>
                                        <td>{product.name}</td>
                                        <td>€{product.discountedPrice.toFixed(2)}</td>
                                        <td>{quantity}</td>
                                        <td>€{price.toFixed(2)}</td>
                                        <td>{saleAdvisor}</td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
                :
                <ResponsiveProductSalesTable />
            }
        </>
    );

}

export default ProductSalesTable;
