import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import { RootState } from "../../store";
import { config } from "../../Constants";
import { IDiscount } from "../../types/models/Discount";
import { handleChange, editDiscount, toggleDeletePrompt } from "../../store/discounts/discountReducer";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import styles from "./index.module.css";


function DiscountList() {

    const cookies = new Cookies();

    const dispatch = useDispatch();
    const discountState = useSelector((state: RootState) => state.discountState);
    const { discountList } = discountState;

    const toggleDiscountStatus = async(discount: IDiscount) => {

        dispatch(handleChange({
            name: "actionMessage",
            value: discount.isActive ? "De-activating...." : "Activating...." 
        }));

        const endpoint = config.url.BACKEND_API_URL + "/discounts/toggle";

        const headers = { 
            "auth-token": cookies.get("auth_token") 
        };
        const requestBody = { 
            discountId: discount._id, 
            isActive: !discount.isActive 
        };

        try {

            const response = await axios.post(endpoint, requestBody, { headers });
            const { updatedDiscount } = response.data;

            const updatedDiscountList = discountList.map(discountItem => {
                if(discountItem._id === updatedDiscount._id) return updatedDiscount;
                return discountItem
            });

            dispatch(handleChange({
                name: "discountList",
                value: updatedDiscountList
            }));
            dispatch(handleChange({
                name: "actionMessage",
                value: discount.isActive ? "De-activated Successfully" : "Activated Successfully" 
            }));

        } catch(error) {
            const { message } = handleApiError(error);
            dispatch(handleChange({
                name: "actionMessage",
                value: message
            }));
        }

    }

    const editDiscountItem = (discountItem: IDiscount) => {
        dispatch(editDiscount(discountItem));
    } 
    const openDeletePrompt = (discountId: string) => {
        dispatch(toggleDeletePrompt(discountId))
    }
    

    return (
        <div className = {styles.discount_list_table}>
            <table>
                <tbody>
                    {discountList.map(discountItem => {
                        const { _id, title, isActive } = discountItem;
                        return (
                            <tr key = {_id}>
                                <td>{title}</td>
                                <td>{isActive ? "Active" : "Inactive"}</td>
                                <td className={styles.button_td}>
                                    <button 
                                        className = {styles.button} 
                                        onClick = {() => toggleDiscountStatus(discountItem)}
                                    >
                                        {isActive ? "De-activate" : "Activate"}
                                    </button>
                                    <button 
                                        className = {styles.button} 
                                        onClick = {() => editDiscountItem(discountItem)}
                                        style = {{}}
                                    >
                                        Edit
                                    </button>
                                    <button 
                                        className = {styles.button}
                                        onClick = {() => openDeletePrompt(discountItem._id)}
                                        style = {{ 
                                            backgroundImage: "linear-gradient(to right, #FF5C58, #ff5b58c4)"
                                        }}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );

}


export default DiscountList;