import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import useWindowWidth from "../../hooks/useWindowWidth";
import { handleChange } from "../../store/reports/reportReducer";
import styles from "./index.module.css";
import DropdownList from "./DropdownList";

type ButtonItem = {
    _id: number
    text: string
    buttonFor: string
}

const buttonList: ButtonItem[] = [
    { _id: 1, text: "Clients", buttonFor: "client" },
    { _id: 2, text: "Appointments", buttonFor: "appointment" },
    { _id: 3, text: "Online payments", buttonFor: "online_payment" },
    { _id: 4, text: "Invoices", buttonFor: "invoice" },
    { _id: 5, text: "Freelancer's Invoices", buttonFor: "stylist_invoice" },
    { _id: 6, text: "Client Packages", buttonFor: "client_bought_package" }
];


const TableButtons: React.FC = () => {

    const dispatch = useDispatch();
    const reportState = useSelector((state: RootState) => state.reportState);
    const { showingTableFor } = reportState;
    const { windowWidth } = useWindowWidth();

    const [dropdownSelectedValue, setDropdownSelectedValue] = useState<string>("Clients");

    const handleClick = (buttonItem: ButtonItem) => {
        dispatch(handleChange({
            name: "showingTableFor",
            value: buttonItem.buttonFor
        }));
        setDropdownSelectedValue(buttonItem.text);
    }

    const handleDropdownClick = (buttonItem: ButtonItem) => {
        dispatch(handleChange({
            name: "showingTableFor",
            value: buttonItem.buttonFor
        }));
        setDropdownSelectedValue(buttonItem.text);
    }

    return (
        <>
            {
                windowWidth >= 1000
                ?
                <div className={styles.table_buttons}>
                    {buttonList.map(item => {
                            return (
                                <button 
                                    key={item.buttonFor}
                                    onClick={() => handleClick(item)}
                                    style={{
                                        color: showingTableFor === item.buttonFor ? "#FFAAA5" : "#3ead7da1",
                                        borderBottom: showingTableFor === item.buttonFor ? "4px solid #FFAAA5" : "none"
                                    }}
                                >
                                    {item.text}
                                </button>
                            );
                    })}
                </div>
                :
                <div className={styles.table_buttons_dropdown}>
                    <DropdownList 
                        data={buttonList}
                        nameKey="text"
                        selectedValue={dropdownSelectedValue}
                        clickHandler={handleDropdownClick}
                    />
                </div>
            }
        </>
    );

}


export default TableButtons;