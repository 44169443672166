import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import { RootState } from "../../store";
import { config } from "../../Constants";
import { toggleDeletePrompt, updateActionMessage } from "../../store/treatments/treatmentReducer";
import { FETCH_DATA } from "../../store/treatments/constants";
import DeletePrompt from "../../components/common/prompts/DeletePrompt";


const DeleteTreatment = () => {

    const cookies = new Cookies();

    const dispatch = useDispatch();
    const treatmentState = useSelector((state: RootState) => state.treatmentState);
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;
    const { treatmentItemInAction, treatmentDataFetchInfo } = treatmentState;
    const { searchText, skip, limit } = treatmentDataFetchInfo;


    const closeDeletePrompt = () => {
        dispatch(toggleDeletePrompt(null))
    }

    const deleteTreatment = async() => {

        if(!treatmentItemInAction) return alert("Please select a treatment");

        dispatch(toggleDeletePrompt(null));
        dispatch(updateActionMessage("Deleting..."));

        const endpoint = config.url.BACKEND_API_URL + "/treatments/delete/" + treatmentItemInAction._id;
        const headers = { "auth-token": cookies.get("auth_token") };

        try {

            // Axios delete method is unique. Request body must be specified with data property.  
            // const response = await axios.delete(endpoint, { data: { _id: treatmentItemInAction?._id }, headers });
            const response = await axios.delete(endpoint, { headers });

            dispatch(updateActionMessage(response.data));

            dispatch({ 
                type: FETCH_DATA, 
                payload: { 
                    tenantId: user?.tenantId,
                    searchText,
                    skip,
                    limit,
                    fetchCategoryAndStylistData: "No"
                } 
            })

        } catch(error) {
            dispatch(updateActionMessage("Something went wrong"));
        }

    }
    
  
    return (
        <>
            <DeletePrompt 
                warningText = {<p>Are you sure want to delete <span>{treatmentItemInAction?.title}</span></p>}
                closeDeletePrompt={closeDeletePrompt}
                deleteItem = {deleteTreatment}
            />
        </>
    );

}


export default DeleteTreatment;