import { useSelector } from "react-redux";
import { ITreatment } from "../../types/models/Treatment";
import { RootState } from "../../store";
import styles from "./TreatmentTable.module.css";
import ActionButtons from "./ActionButtons";

interface IProps {
    handleClick: (args: { actionType: string, treatment: ITreatment}) => void
}


const ResponsiveTreatmentTable: React.FC<IProps> = ({ handleClick }) => {

    const treatmentState = useSelector((state: RootState) => state.treatmentState);
    const { treatmentList } = treatmentState;
   
  
    return (
        <div className = {styles.responsive_treatment_table}>
            {treatmentList.map(treatment => {
                const { _id, category, title, price } = treatment;
                
                return (
                    <div 
                        key = {_id}
                        className = {styles.responsive_treatment_item}
                    >
                        <div className={styles.responsive_title_actions}>
                            <p className = {styles.responsive_treatment_category}>
                                {category.title}
                            </p>
                            <ActionButtons 
                                showButtonText = {false}
                                treatment={treatment}
                                handleClick={handleClick}
                            />
                        </div>
                        <p className = {styles.responsive_treatment_title}>
                            {title}
                        </p>
                        <p>€{price.toFixed(2)}</p>
                    </div>
                );
            })}
        </div>
    );

}

export default ResponsiveTreatmentTable;