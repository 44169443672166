import { IUser } from "../types/models/User";
import styles from "./TopNav.module.css";

interface IProps {
    user: IUser | null
}


const UserInfo: React.FC<IProps> = ({ user }) => {

    return (
        <div className={styles.info}>
            <h3>{user?.fullName}</h3>
            <p>{user?.userType}</p>
        </div>         
    );
}


export default UserInfo;