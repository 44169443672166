export const validatePassword = (password: string, password2: string) => {

    // This regex will check if the typed password contains at least one uppercase, one lowercase, 
    // one symbol and it is at least 8 characters long. 
    const regEx = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*\\?])(?=.{8})");

    let isPassValid: boolean = true;
    let passValMessage: string = "";

    if(password !== password2) passValMessage = "Passwords didn't match";
    if(!regEx.test(password)) passValMessage = "Password must have an uppercase and a lowercase letter, a symbol(!@#$%^&*?) and it must be 8 characters long";

    if(passValMessage) isPassValid = false;

    return { isPassValid, passValMessage };
    
}