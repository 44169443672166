import { useSelector } from "react-redux";
import { RootState } from "../../store";
import useWindowWidth from "../../hooks/useWindowWidth";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import ResponsivePaymentTable from "./ResponsivePaymentTable";


const OnlinePaymentTable: React.FC = () => {

    const reportState = useSelector((state: RootState) => state.reportState);
    const { appointmentList } = reportState;
    const { windowWidth } = useWindowWidth()

    return (
        <>
            {
                windowWidth >= 1000 
                ?
            
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Treatment</th>
                            <th>Price</th>
                            <th>Paid Amount</th>
                            <th>Refund Amount</th>
                            <th>Client Name</th>
                            <th>Payment Method</th>
                        </tr>
                    </thead>
                    <tbody>
                        {appointmentList.map(appointment => {
                            const { 
                                _id,
                                isPartiallyPaidOnline,
                                isFullyPaidOnline,
                                appointment_date,
                                treatmentTitle,
                                treatmentPrice,
                                priceAfterDiscount,
                                clientName,
                                paymentMethod,
                                partiallyPaidAmount, 
                                partiallyPaidAmountPaymentMethod,
                                refundAmount
                            } = appointment;

                            const { day, month, year, hours, minutes } = formatDateTime(new Date(appointment_date * 1000));

                            if(!isFullyPaidOnline && !isPartiallyPaidOnline) return null;

                            const paidAmount = isFullyPaidOnline ? priceAfterDiscount : partiallyPaidAmount;
                            const newPaymentMethod = isFullyPaidOnline ? paymentMethod : partiallyPaidAmountPaymentMethod;

                            return (
                                <tr key = {_id}>
                                    <td>{`${month} ${day}, ${year}`}</td>
                                    <td>{`${hours}:${minutes}`}</td>
                                    <td>{treatmentTitle}</td>
                                    <td>€{treatmentPrice.toFixed(2)}</td>
                                    <td>€{paidAmount.toFixed(2)}</td>
                                    <td>€{refundAmount ? refundAmount.toFixed(2) : 0.00}</td>
                                    <td>{clientName}</td>
                                    <td>{newPaymentMethod}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                :
                <ResponsivePaymentTable />
            }
        </>
    );

}

export default OnlinePaymentTable;