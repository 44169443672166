import { IoMdAdd } from "react-icons/io";
import { IProduct } from "../../types/models/Product";
import styles from "./Button.module.css";

interface AddToPOButtonProps extends React.ComponentProps<"button"> {
    withText?: boolean
    product: IProduct
    addProductToPO: (product: IProduct) => void
}

const AddToPOButton: React.FC<AddToPOButtonProps> = ({ withText, product, addProductToPO }) => {

    const classes = withText ? "add_to_po_btn" : "";
  
    return (
        <button 
            className={styles[classes]}
            onClick={() => addProductToPO(product)}
        >
            {withText ? "Add To PO" :  <IoMdAdd />}
        </button>          
    );

}

export default AddToPOButton;
