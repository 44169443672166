import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { UserAuth } from "../../auth/UserAuth";
import { FETCH_DISCOUNT_AND_OTHER_DATA } from "../../store/discounts/constants";
import { handleChange } from "../../store/discounts/discountReducer";
import styles from "./index.module.css";
import AddDiscountButton from "./AddDiscountButton";
import DiscountList from "./DiscountList";
import DiscountTitle from "./DiscountTitle";
import SelectLocation from "./SelectLocation";
import SelectStylist from "./SelectStylist";
import SelectTreatment from "./SelectTreatment";
import LastMinuteDiscount from "./LastMinuteDiscount";
import OffPeakDiscount from "./OffPeakDiscount";
import SaveButton from "./SaveButton";
import CloseDiscountButton from "./CloseDiscountButton";
import DeleteDiscountPrompt from "./DeleteDiscountPrompt";
import ActionMessage from "../../components/common/messages/ActionMessage";


const TimeDiscount = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const discountState = useSelector((state: RootState) => state.discountState);
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;
    const { 
        actionMessage,
        isAddingUpdatingDiscount,
        showDeletePrompt,
        discountId 
    } = discountState;

    useEffect(() => {

        if(!UserAuth()) {
            navigate("/");
            return;
        }

        if(!user) return;

        // User must be on trial period or must have bought a sub plan to access the locations
        if(!user.stripeSubscription && !user.isOnTrialPeriod) {
            navigate("/");
            return;
        }

        dispatch({ type: FETCH_DISCOUNT_AND_OTHER_DATA });
        
    }, [user?._id]);


    const hideActionMessage = () => {
        dispatch(handleChange({
            name: "actionMessage",
            value: ""
        }));
    }

  
    return (
        <div className={styles.time_discounts}>
            {
                actionMessage !== ""
                ?
                <ActionMessage 
                    actionMessage = {actionMessage}
                    hideActionMessage = {hideActionMessage} 
                />
                :
                null
            }
            <div className = {styles.time_discounts_content}>
                {
                    isAddingUpdatingDiscount
                    ?
                    <div className = {styles.add_discount}>
                        <DiscountTitle />
                        <SelectLocation />
                        <SelectStylist />
                        <LastMinuteDiscount />
                        <OffPeakDiscount />
                        <SelectTreatment />
                        <div className = {styles.save_close_buttons}>
                            <SaveButton />
                            <CloseDiscountButton />
                        </div>
                    </div>
                    :
                    <div className = {styles.discount_list}>
                        <AddDiscountButton />
                        <DiscountList />
                    </div>
                }
            </div>
            {(showDeletePrompt && discountId) ? <DeleteDiscountPrompt /> : null}
        </div>
    );

}


export default TimeDiscount;