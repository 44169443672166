import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import { RootState } from "../store";
import { config } from "../Constants";
import { 
    fetchUserDataLoading, 
    fetchUserDataSuccess, 
    fetchUserDataFail 
} from "../store/user/userReducer";

const useUser = () => {
    
    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;

    useEffect(() => {

        if(user) return;

        const fetchUserData = async() => {

            const cookies = new Cookies();

            const authToken = cookies.get("auth_token");

            try {
    
                dispatch(fetchUserDataLoading());
    
                const options = {
                    headers: { "auth-token": authToken },
                    method: "get",
                    data: {},
                    url: config.url.BACKEND_API_URL + "/users/single-user"
                };
    
                const response = await axios(options);
                const user = response.data;
                dispatch(fetchUserDataSuccess(user));
                
            } catch (error) {
                dispatch(fetchUserDataFail())
            } 

        }

        fetchUserData()

    }, [user, dispatch]); 

}


export default useUser;