import { useSelector, useDispatch } from "react-redux";
import { IUser } from "../../types/models/User";
import { RootState } from "../../store";
import { addNewTreatmentInfo } from "../../store/treatments/treatmentReducer";
import styles from "./AddStylist.module.css";


const AddStylist: React.FC = () => {

    const dispatch = useDispatch();
    const treatmentState = useSelector((state: RootState) => state.treatmentState);

    const { newTreatmentInfo, stylistList } = treatmentState;
    const { stylists } = newTreatmentInfo;

    const addStylist = (stylist: IUser) => {

        const isStylistAddedAlready = stylists.some(stylistItem => stylistItem._id === stylist._id);

        if(isStylistAddedAlready) {
            alert(`${stylist.fullName} has already been added`);
            return;
        } 

        dispatch(addNewTreatmentInfo({ name: "stylists", value: [...stylists, stylist] }));
       
    }

    const removeStylist = (stylist: IUser) => {
        const filteredStylists = stylists.filter(item => item._id !== stylist._id);
        dispatch(addNewTreatmentInfo({ name: "stylists", value: filteredStylists }));
    }
   

    return (
        <div className={styles.add_stylist_container}>
            <h2>Add Stylists</h2>
            <div className={styles.add_stylist}>
                <div className={styles.stylist_list}>
                    {stylistList.map((stylist) => {
                        return (
                            <div className={styles.stylist_item} key = {stylist._id}>
                                <p>{stylist.fullName}</p>
                                <button onClick = {() => addStylist(stylist)}>+</button>
                            </div>
                        );
                    })}
                </div>
                <div className={styles.stylist_list}>
                    {stylists.map(stylist => {
                        return (
                            <div className={styles.stylist_item} key = {stylist._id}>
                                <p>{stylist.fullName}</p>
                                <button onClick = {() => removeStylist(stylist)}>-</button>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default AddStylist;


