import { useSelector } from "react-redux";
import { IUser } from "../../types/models/User";
import { RootState } from "../../store";
import styles from "./UserTable.module.css";
import ActionButtons from "./ActionButtons";

interface IProps {
    handleClick: (args: { actionType: string, user: IUser}) => void
}


const ResponsiveUserTable: React.FC<IProps> = ({ handleClick }) => {

    const userListState = useSelector((state: RootState) => state.userListState);
    const { userList } = userListState;
   
  
    return (
        <div className = {styles.responsive_user_table}>
            {userList.map(user => {
                const { _id, userType, fullName, email } = user;
                
                return (
                    <div 
                        key = {_id}
                        className = {styles.responsive_user_item}
                    >
                        <div className={styles.responsive_title_actions}>
                            <p className = {styles.responsive_user_type}>
                                {userType}
                            </p>
                            <ActionButtons 
                                showButtonText = {false}
                                user={user}
                                handleClick={handleClick}
                            />
                        </div>
                        <p className = {styles.responsive_user_fullname}>
                            {fullName}
                        </p>
                        <p>{email}</p>
                    </div>
                );
            })}
        </div>
    );

}

export default ResponsiveUserTable;