import { call, put, takeLatest } from "redux-saga/effects";
import { IWaitList } from "../types/models/WaitList";
import { FETCH_WAIT_LIST_DATA } from "../store/wait-list/constants";
import { fetchDataLoading, fetchDataSuccess, fetchDataFail } from "../store/wait-list/waitListReducer";
import { handleApiError } from "../helpers/error-handlers/handleApiError";
import { fetchWaitListData } from "./api/wait-list/fetchWaitListData";

function* handleFetchWaitListData() {

    try {

        yield put(fetchDataLoading())
        const waitList: IWaitList[] = yield call(fetchWaitListData);
        yield put(fetchDataSuccess(waitList))

    } catch(error) {
        const { message } = handleApiError(error);
        yield put(fetchDataFail(message))
    }

}


export default function* waitListSaga() {
    yield takeLatest(FETCH_WAIT_LIST_DATA, handleFetchWaitListData);
}
