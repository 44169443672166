import { call, put, takeLatest } from "redux-saga/effects";
import { FETCH_DATA } from "../store/treatments/constants";
import { FetchDataQueryParams, FetchedData } from "../types/pages/Treatment";
import { 
    fetchDataFail,
    fetchDataLoading,
    fetchDataSuccess
} from "../store/treatments/treatmentReducer";
import { fetchData } from "./api/treatments/fetchData";


function* handleFetchData(action: { type: string, payload: FetchDataQueryParams }) {

    try {

        yield put(fetchDataLoading())
        const result: FetchedData = yield call(fetchData, action.payload);
        yield put(fetchDataSuccess(result))

    } catch(error) {
        yield put(fetchDataFail())
    }

}


export default function* treatmentSaga() {
    yield takeLatest(FETCH_DATA, handleFetchData);
}
