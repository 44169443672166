import { useEffect, useState } from "react";

export interface DevicesWidth {
    isSmartphone: boolean
    isMiniTab: boolean
    isAverageTab: boolean
    isLargeTab: boolean
    isNoteBook: boolean
    isPC: boolean
    isLargeDevice: boolean
}

const defaultDevicesWidth: DevicesWidth = {
    isSmartphone: false,
    isMiniTab: false,
    isAverageTab: false,
    isLargeTab: false,
    isNoteBook: false,
    isPC: false,
    isLargeDevice: false
}


const useWindowWidth = () => {
    
    const [windowWidth, setWindowWidth] = useState<number>(0);
    const [devicesWidth, setDevicesWidth] = useState<DevicesWidth>(defaultDevicesWidth);

    const getDevicesWidth = (newWindowWidth: number) => {

        const isSmartphone = newWindowWidth <= 600;
        const isMiniTab = newWindowWidth > 600 && newWindowWidth <= 800;
        const isAverageTab = newWindowWidth > 800 && newWindowWidth <= 1000;
        const isLargeTab = newWindowWidth > 1000 && newWindowWidth <= 1200;
        const isNoteBook = newWindowWidth > 1200 && newWindowWidth <= 1400;
        const isPC = newWindowWidth > 1400;
        const isLargeDevice = newWindowWidth > 1200;

        setDevicesWidth({ 
            isSmartphone, 
            isMiniTab, 
            isAverageTab, 
            isLargeTab, 
            isNoteBook, 
            isPC,
            isLargeDevice 
        });

    }

    useEffect(() => {

        // We don't want to update window width and device sizes again each window is resized. 
        // So, only when component mounts and window is resized we need to update those states.
        if(!windowWidth) {
            setWindowWidth(window.innerWidth);
            getDevicesWidth(window.innerWidth);
        }
      
        window.addEventListener("resize", () => {
            setWindowWidth(window.innerWidth);
            getDevicesWidth(window.innerWidth);
        }); 

        // Clear event listener on component unmounts
        return () => {
            window.removeEventListener("resize", () => {
                setWindowWidth(0);
                setDevicesWidth(defaultDevicesWidth);
            } );
        };
       
    }, [windowWidth]);
    
    return { windowWidth, devicesWidth };

}

export default useWindowWidth;