import styles from "./Buttons.module.css";
import DownloadIcon from "../../icons/DownloadIcon";

interface DownloadIconButtonProps {
    onDownload: (args: any) => void
    args?: any
}

const DownloadIconButton: React.FC<DownloadIconButtonProps> = ({ onDownload, args }) => {

    return (
        <div className={styles.download_button}>
            <div 
                className={styles.download_btn_div} 
                onClick={() => onDownload(args)}
            >
            </div>
            <DownloadIcon />
            <span>Download</span>
        </div>
    );

}

export default DownloadIconButton;