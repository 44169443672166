import { IAppointment } from "../../../types/models/Appointment";
import styles from "./AppointmentModal.module.css";
import SectionHeader from "./SectionHeader";
import AppointmentButtons from "./AppointmentButtons";
import AppointmentStatus from "./AppointmentStatus";
import AppointmentActivityList from "./AppointmentActivityList";

type AppointmentDetailsProps = {
    appointment: IAppointment
    canUserMaintainAgendas?: boolean
}


const AppointmentDetails: React.FC<AppointmentDetailsProps> = ({ appointment, canUserMaintainAgendas }) => {

    const { activities, locationItem, treatmentTitle, status } = appointment;

    return (
        <div className = {styles.appointment_details}>
            <SectionHeader 
                headerText = "Appointment Details"
            />
            <div className = {styles.appointment_details_content}>
                <div className = {styles.appointment_basic_info}>
                    <h5>{treatmentTitle}</h5>
                    { 
                        activities.length > 0
                        ?
                        <AppointmentActivityList 
                            activities = {activities} 
                        />
                        :
                        null
                    } 
                    {
                        (locationItem && canUserMaintainAgendas) 
                        ? 
                        <AppointmentButtons /> 
                        : 
                        null
                    }
                </div>
                <AppointmentStatus 
                    status = {status} 
                />
            </div>
        </div>
    );

}

export default AppointmentDetails;