import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { UserAuth } from "../../auth/UserAuth";
import { FETCH_PACKAGE_DATA } from "../../store/packages/constants";
import { updateState, togglePackageBuyModal } from "../../store/packages/packageReducer";
import styles from "./index.module.css";
import SearchAdd from "./SearchAdd";
import BuyPackage from "./BuyPackage";
import AddEditPackage from "./AddEditPackage";
import PackageTable from "./PackageTable";
import DeletePackage from "./DeletePackage";
import ActionMessage from "../../components/common/messages/ActionMessage";
import Modal from "../../components/common/modal/Modal";

const PackageList = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const packageState = useSelector((state: RootState) => state.packageState);
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;
    const { 
        isBuying,
        isAdding, 
        isEditing, 
        isDeleting, 
        packageItemInAction,
        actionMessage,
        searchText, 
        skip, 
        limit
    } = packageState;

    useEffect(() => {

        if(!UserAuth()) {
            navigate("/");
            return;
        }

        if(!user) return;

        // User must be on trial period or must have bought a sub plan to access the packages
        if(!user.stripeSubscription && !user.isOnTrialPeriod) {
            navigate("/");
            return;
        }

        dispatch({ 
            type: FETCH_PACKAGE_DATA, 
            payload: { 
                searchText, 
                skip, 
                limit,
                fetchTreatmentData: true 
            } 
        });
        
    }, [user?._id]);

    const hideActionMessage = () => {
        dispatch(updateState({
            name: "actionMessage",
            value: ""
        }));
    }

    const closeModal = () => {
        dispatch(togglePackageBuyModal(null));
    }

    return (
        <div className = {styles.packages_page}>
            {
                actionMessage
                &&
                <ActionMessage 
                    actionMessage = {actionMessage}
                    hideActionMessage={hideActionMessage}
                />
            }
            <SearchAdd />
            <PackageTable />
            {(isAdding || isEditing) ? <AddEditPackage /> : null}
            {(isDeleting && packageItemInAction) ? <DeletePackage /> : null}
            {
                (isBuying && packageItemInAction)
                ?
                <Modal 
                    modalContent={<BuyPackage />}
                    onCloseModal={closeModal}
                />
                :
                null
            }
        </div>
    );

}

export default PackageList;