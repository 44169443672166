import { IAppointment } from "../../../types/models/Appointment";
import styles from "./AppointmentModal.module.css";
import AddPayment from "./AddPayment";
import SectionHeader from "./SectionHeader";
import PaymentBreakdown from "./PaymentBreakdown";

type PaymentStatusProps = {
    appointment: IAppointment
    canUserMaintainAgendas?: boolean
}


const PaymentStatus: React.FC<PaymentStatusProps> = ({ appointment, canUserMaintainAgendas }) => {

    const { paymentStatus, type } = appointment;

    return (
        <div className = {styles.payment_status}>
            <SectionHeader headerText = "Payment Status" />
            <div className = {styles.payment_status_content}>
                <div className = {styles.payment_info}>
                    <div className = {styles.payment_status_text}>
                        <span>{paymentStatus}</span>
                    </div>
                    <PaymentBreakdown 
                        appointment = {appointment} 
                    />
                </div>
                {
                    (type === "regular" && paymentStatus !== "Paid") 
                    &&
                    canUserMaintainAgendas
                    ? 
                    <AddPayment />
                    :
                    null
                }
            </div>
        </div>
    );

}

export default PaymentStatus;