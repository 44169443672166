import { useDispatch } from "react-redux";
import { IAppointment } from "../../types/models/Appointment";
import { updateState } from "../../store/calendar/calendarReducer";
import styles from "./Calendar.module.css";

interface IProps {
    events: IAppointment[]
    date: Date
}


const MonthViewEvents: React.FC<IProps> = ({ events, date }) => {

    const dispatch = useDispatch();
  
    const handleClick = (event: IAppointment) => {
        dispatch(updateState({
            name: "appointmentInAction",
            value: event
        }))
    }

    return (
        <>
            {events.map((event, index) => {
                if(index < 2) {
                    return (
                        <div 
                            key = {event._id}
                            className = {styles.month_event}
                            onClick = {() => handleClick(event)}
                            style = {{
                                backgroundColor: event.treatmentItem?.category.color ?? "white",
                            }}
                        >   
                            <p>{event.clientName}</p>
                            <p>{event.treatmentTitle} with {event.stylist}</p>
                        </div>
                    );
                } else {
                    return null
                }
            })}
        </>
    );

}

export default MonthViewEvents;