import { IAppointment } from "../../../types/models/Appointment";
import styles from "./AppointmentModal.module.css";
import PaymentDate from "./PaymentDate";
import PaymentInfo from "./PaymentInfo";
import Amount from "./Amount";


const PaymentBreakdown: React.FC<{ appointment: IAppointment }> = ({ appointment }) => {

    const { 
        treatmentItem,
        paymentStatus,
        paymentMethod,
        isPartiallyPaid, 
        partiallyPaidAmount, 
        fullAmountPaymentDate, 
        partiallyPaidAmountPaymentDate, 
        partiallyPaidAmountPaymentMethod,
        dueAmountPaymentDate,
        dueAmountPaymentMethod,
        couponDiscountAmount, 
        giftCardDiscountAmount, 
        packageDiscountAmount, 
        timeBasedDiscountAmount 
    } = appointment;
   
    const price = treatmentItem?.price ?? 0

    // Need to separate gift card and other discounts. And calculate to be paid amount.
    let totalOtherDiscounts = couponDiscountAmount + packageDiscountAmount;
    if(timeBasedDiscountAmount) totalOtherDiscounts += timeBasedDiscountAmount;

    const toBePaidAmount = price - giftCardDiscountAmount - totalOtherDiscounts - partiallyPaidAmount;

    return (
        <div className = {styles.payment_breakdown}>
            <table>
                <tbody>
                    <Amount 
                        title = "Price"
                        value = {`€${price.toFixed(2)}`}
                    />
                    {
                        totalOtherDiscounts > 0
                        &&
                        <Amount 
                            title = "Discounts"
                            value = {`€${totalOtherDiscounts.toFixed(2)}`}
                        />
                    }
                    {
                        giftCardDiscountAmount > 0
                        &&
                        <Amount 
                            title = "Paid By Gift Card"
                            value = {`€${giftCardDiscountAmount.toFixed(2)}`}
                        />
                    }
                    {
                        (isPartiallyPaid && partiallyPaidAmountPaymentMethod && partiallyPaidAmountPaymentDate)
                        &&
                        <PaymentInfo 
                            title = "Partially Paid Amount"
                            method = {partiallyPaidAmountPaymentMethod}
                            amount = {partiallyPaidAmount}
                            paymentDate = {partiallyPaidAmountPaymentDate}
                        />
                    }
                    {
                        (dueAmountPaymentMethod && dueAmountPaymentDate)
                        &&
                        <PaymentInfo 
                            title = "Due Paid Amount"
                            method = {dueAmountPaymentMethod}
                            amount = {toBePaidAmount}
                            paymentDate = {dueAmountPaymentDate}
                        />
                    }
                    {
                        // There is no field for due amount. But there is one for due amount payment date.
                        // So if to be paid amount is more than 0 and dueAmountPaymentDate is null then
                        // there is some amount left to be paid and we need to display that.
                        (paymentStatus !== "Paid" && toBePaidAmount > 0 && !dueAmountPaymentDate)
                        &&
                        <Amount 
                            title = "To Be Paid"
                            value = {`€${toBePaidAmount.toFixed(2)}`}
                        />
                    }
                    {
                        fullAmountPaymentDate && 
                        <PaymentDate 
                            text = "Payment"
                            paymentDate = {fullAmountPaymentDate} 
                            paymentMethod = {paymentMethod}
                        />
                    }
                </tbody>
            </table>
        </div>
    );

}

export default PaymentBreakdown;