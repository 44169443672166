import { useSelector } from "react-redux";
import { RootState } from "../../store";
import useWindowWidth from "../../hooks/useWindowWidth";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import ResponsiveClientTable from "./ResponsiveClientTable";


const ClientTable: React.FC = () => {

    const reportState = useSelector((state: RootState) => state.reportState);
    const { clientList } = reportState;
    const { windowWidth } = useWindowWidth()

    return (
        <>
            {
                windowWidth >= 1000
                ?
                
                <table>
                    <thead>
                        <tr>
                            <th>Full Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Last Visit</th>
                            <th>First Visit</th>
                            <th># Bookings</th>
                            <th>Cancelled</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clientList.map(client => {
                            const { 
                                name, 
                                email, 
                                phone, 
                                lastVisitDate, 
                                firstVisitDate,
                                totalAppointmentCount,
                                cancelledAppointmentCount 
                            } = client;

                            const { 
                                day: lastVisitDay, 
                                month: lastVisitMonth, 
                                year: lastVisitYear 
                            } = formatDateTime(new Date(lastVisitDate * 1000));

                            const { 
                                day: firstVisitDay, 
                                month: firstVisitMonth, 
                                year: firstVisitYear 
                            } = formatDateTime(new Date(firstVisitDate * 1000));

                            return (
                                <tr key = {client.email}>
                                    <td>{name}</td>
                                    <td>{email}</td>
                                    <td>{phone}</td>
                                    <td>{`${lastVisitMonth} ${lastVisitDay}, ${lastVisitYear}`}</td>
                                    <td>{`${firstVisitMonth} ${firstVisitDay}, ${firstVisitYear}`}</td>
                                    <td>{totalAppointmentCount}</td>
                                    <td>{cancelledAppointmentCount}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                :
                <ResponsiveClientTable />
            }
        </>
    );

}

export default ClientTable;