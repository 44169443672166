import styles from "./InputFields.module.css";

interface IProps {
    customClassName?: string,
    name: string,
    value: string,
    checked: boolean,
    handleChange: (event: React.ChangeEvent<HTMLInputElement>, args?: any) => void,
    handleChangeArgs?: any 
}

const CheckboxInputField: React.FC<IProps> = (props) => {

    const { 
        customClassName = "", 
        name, 
        value, 
        checked, 
        handleChange, 
        handleChangeArgs 
    } = props;

    // Important Note
    // input type checkbox must be inside a form that's autoComplete property has been set to off. Otherwise checked checkbox will
    // remain checked after page refresh which might cause heavy confusion and problem

    return (
        <div className = {`${styles.checkbox_input_field} ${styles[customClassName]}`}>
            <input 
                name = {name}
                type = "checkbox" 
                value = {value} 
                checked = {checked}
                onChange = {(event) => handleChange(event, handleChangeArgs)}
            />
            <span>{value}</span>
        </div>
    );

}


export default CheckboxInputField;


