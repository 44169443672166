import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getFormattedDateTime } from "../../../helpers/date-time/getFormattedDateTime";
import styles from "./CalendarHeader.module.css";


const ViewDateInfo: React.FC = () => {

    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { 
        calendarViewType, 
        dayViewDate, 
        monthViewDates, 
        weekViewDates 
    } = calendarState;

    let dateWeekMonthString = "";

    if(calendarViewType === "day") {
        const formattedDateViewDate = getFormattedDateTime(dayViewDate);
        const { weekDay, month, day } = formattedDateViewDate;
        dateWeekMonthString = `${weekDay} ${month} ${day}`;
    }

    if(calendarViewType === "week") {
        const firstDateOfTheWeek = getFormattedDateTime(weekViewDates[0]);
        const lastDateOfTheWeek = getFormattedDateTime(weekViewDates[weekViewDates.length - 1]);
        const { month, day } = firstDateOfTheWeek;
        const { 
            month: lastDateMonth, 
            day: lastDateDay 
        } = lastDateOfTheWeek;
        dateWeekMonthString = `${month} ${day} - ${lastDateMonth} ${lastDateDay}`;
    }

    if(calendarViewType === "month") {
        const date = monthViewDates[25];
        const formattedDate = getFormattedDateTime(date);
        const { month, year } = formattedDate;
        dateWeekMonthString = `${month} ${year}`;
    }

  
    return (  
        <div className = {styles.view_date_info}>
            <p>{dateWeekMonthString}</p>
        </div>
    );

}

export default ViewDateInfo;