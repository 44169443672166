import { useSelector, useDispatch } from "react-redux";
import { ILocation } from "../../types/models/Location";
import { RootState } from "../../store";
import { updateViewLimit, openEditLocationModal, toggleDeletePrompt } from "../../store/locations/locationReducer";
import { FETCH_LOCATION_DATA } from "../../store/locations/constants";
import useWindowWidth from "../../hooks/useWindowWidth";
import styles from "./LocationTable.module.css";
import DropdownList from "./DropdownList";
import LoadingError from "../../components/common/loading-error/LoadingError";
import ActionButtons from "./ActionButtons";
import ResponsiveLocationTable from "./ResponsiveLocationTable";


const LocationTable = () => {

    const dispatch = useDispatch();
    const locationState = useSelector((state: RootState) => state.locationState);
    const userState = useSelector((state: RootState) => state.userState);
    const { devicesWidth } = useWindowWidth();
    const { isLargeDevice } = devicesWidth;
    const { user } = userState;
    const { loading, error, locationList, locationDataFetchInfo } = locationState;
    const { searchText, skip, limit, totalLocationCount } = locationDataFetchInfo;

    const handleClick = (args: { actionType: string, location: ILocation}) => {

        const { actionType, location } = args;

        if(actionType === "Delete") {
            dispatch(toggleDeletePrompt(location));
            return;
        }

        dispatch(openEditLocationModal(location))

    }

    const handleDropdownChange = (event: React.MouseEvent<HTMLLIElement>, newLimit?: any) => {

        if(totalLocationCount <= 5) return;

        dispatch(updateViewLimit(newLimit));

        dispatch({ 
            type: FETCH_LOCATION_DATA, 
            payload: { 
                tenantId: user?.tenantId, 
                searchText, 
                skip, 
                limit: newLimit 
            } 
        });

    }

  
    return (
        <div className = {styles.location_table}> 
            <div className = {styles.table_header}>
                <h2>Locations</h2>
                <DropdownList 
                    data = {[
                        { _id: 1, limit: 5 },
                        { _id: 2, limit: 10 },
                        { _id: 3, limit: "All" },
                    ]}
                    nameKey="limit"
                    selectedValue = {limit}
                    clickHandler={handleDropdownChange}
                />
            </div>
            <div className = {styles.table_content}>
                {
                    loading
                    ?
                    <LoadingError 
                        message="loading data..."
                    />
                    :
                    error
                    ?
                    <LoadingError 
                        message="Something went wrong"
                    />
                    :
                    isLargeDevice
                    ?
                    <table>
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Address</th>
                                <th>Phone</th>
                                <th>City</th>
                                <th>Country</th>
                                <th>Zip Code</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {locationList.map(location => {
                                const { _id, title, address, city, phone, country, zipCode } = location;
                                
                                return (
                                    <tr key = {_id}>
                                        <td>{title}</td>
                                        <td>{address}</td>
                                        <td>{phone}</td>
                                        <td>{city}</td>
                                        <td>{country}</td>
                                        <td>{zipCode}</td>
                                        <td>
                                            <ActionButtons 
                                                location={location}
                                                showButtonText = {true}
                                                handleClick={handleClick}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    :
                    <ResponsiveLocationTable 
                        handleClick={handleClick}
                    />
                }
            </div>
        </div>
    );

}

export default LocationTable;