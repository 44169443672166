import React from "react";
import { IUser } from "../../types/models/User";
import { IAppointment } from "../../types/models/Appointment";
import styles from "./Calendar.module.css";
import Events from "./Events";
import TimeSlotGroup from "./TimeSlotGroup";

interface WeekViewProps {
    user: IUser | null
    selectedStylistList: IUser[]
    appointmentList: IAppointment[]
    weekViewDates: Date[]
    switchToDayView: (date: Date) => void
}

const WeekView: React.FC<WeekViewProps> = ({ 
    user, 
    selectedStylistList, 
    appointmentList, 
    weekViewDates,
    switchToDayView 
}) => {

    return (  
        <div className={styles.week_view}>
            {selectedStylistList.map(stylist => {

                const stylistAppointments = appointmentList.filter(item => item.stylistItem?._id === stylist._id);

                return (
                    <div className = {styles.week_times_container} key = {stylist._id}>
                        <div className = {styles.week_stylist_name_container} >
                            <div className = {styles.week_stylist_name}>
                                {stylist.fullName}
                            </div>
                            <div className = {styles.week_dates_container}>
                                {weekViewDates.map((weekDate, index) => {

                                    const day = weekDate.getDate();
                                    const dayName = weekDate.toLocaleString("en-us", {  weekday: "short" });

                                    return (
                                        <div 
                                            key={index} 
                                            className={styles.week_date}
                                            onClick={() => switchToDayView(weekDate)}
                                        >
                                            {day} {dayName}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className = {styles.week_events_container}>
                            {weekViewDates.map((weekDate, index) => {

                                return (
                                    <div key = {index} className = {styles.week_events}>
                                        <div className = {styles.timeslot_groups_container}>
                                            <Events 
                                                events = {stylistAppointments}
                                                date = {weekDate}
                                            />
                                            <TimeSlotGroup 
                                                user={user}
                                                date={weekDate}
                                                stylist={stylist.fullName}
                                                stylistId={stylist._id}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );

}

export default React.memo(WeekView);
