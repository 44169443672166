export const userAccessTypeGroupNames = {
    agendas: "agendas",
    features: "features",
    clients: "clients"
}

export const userAccessTypeValues = {
    maintainOwnAgendas: "maintain_own_agendas",
    viewAllAgendas: "view_all_agendas",
    maintainAllAgendas: "maintain_all_agendas",
    maintainReports: "maintain_reports",
    maintainTreatments: "maintain_treatments",
    maintainDiscounts: "maintain_discounts",
    maintainCoupons: "maintain_coupons",
    maintainPackages: "maintain_packages",
    maintainEmails: "maintain_emails",
    maintainIntakeForms: "maintain_intake_forms",
    maintainGiftCards: "maintain_gift_cards",
    maintainWaitingList: "maintain_waiting_list",
    maintainProducts: "maintain_products",
    viewOwnClientDetails: "view_own_client_details",
    maintainOwnClientDetails: "maintain_own_client_details",
    maintainAllClientDetails: "maintain_all_client_details"
}