import styles from "./HiddenDiv.module.css";

interface IProps {
    elementRef?: React.MutableRefObject<HTMLDivElement>;
    handleClick: (args?: any) => void;
    handleClickArgs?: any;
}


const HiddenDiv: React.FC<IProps> = ({ elementRef, handleClick, handleClickArgs }) => {

    return (
        <div 
            ref = {elementRef}
            className = {styles.hidden_div} 
            onClick = {() => handleClick(handleClickArgs)} 
        >
        </div>

    );
}


export default HiddenDiv;