import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import useClickOutside from "../../hooks/useClickOutside";
import { RootState } from "../../store";
import { IProductBrand } from "../../types/models/Product";
import { config } from "../../Constants";
import { updateState, addNewProductInfo } from "../../store/products/productReducer";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import styles from "./AddEditProduct.module.css";
import InputField from "../../components/common/input-fields/InputField";

interface SelectBrandProps {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const SelectBrand: React.FC<SelectBrandProps> = ({ handleChange }) => {

    const { isVisible, setIsVisible, elementRef } = useClickOutside({ exceptions: [] })

    const dispatch = useDispatch();
    const productState = useSelector((state: RootState) => state.productState);
    const { doNotShowProductBrandList, validationError, productBrandList, newProductInfo } = productState;

    useEffect(() => {

        // If user is editing a product then newProductInfo.brand state will be updated
        // with the selected product's brand. So, this change in brand will re-trigger
        // this useEffect hook and make the api call. But we don't want it, because we have
        // full brand text. So need to return.
        if(doNotShowProductBrandList) return;

        // if brand._id is not null that means a brand has been selected from 
        // the searched list. As any change in brand triggers re-run of this useEffect
        // so we need to return it from here if a brand has been selected.
        if(newProductInfo.brand._id) return;

        // Only make api calls if user has typed in something and it's at least 2 characters long
        if(newProductInfo.brand.name.length < 2) return;

        const cookies = new Cookies();

        const timer = setTimeout(() => {

            (async function fetchCategory() {

                const endpoint = config.url.BACKEND_API_URL + `/products/brands?searchText=${newProductInfo.brand.name}`;
                const token = cookies.get("auth_token"); 
                const axiosConfig = {
                    headers: {
                        "Authorization": `Bearer ${token}`, 
                        "auth-token": token 
                    }
                }

                try {

                    const response = await axios.get(endpoint, axiosConfig);
                    dispatch(updateState({
                        name: "productBrandList",
                        value: response.data.brandList
                    }));
                    setIsVisible(true);
                
                } catch(error) {
                    const { message } = handleApiError(error);
                    alert(message);
                }

            })();

        }, 500);

        return () => clearTimeout(timer);

    }, [newProductInfo.brand, dispatch, setIsVisible, doNotShowProductBrandList])

    const chooseBrand = (brand: IProductBrand) => {

        dispatch(addNewProductInfo({
            name: "brand",
            value: { 
                _id: brand._id,
                name: brand.name
            }
        }));

        setIsVisible(false);

        dispatch(updateState({
            name: "productBrandList",
            value: []
        }));

    }

    return (
        <div className={styles.select_category_brand}>
            <InputField 
                labelText="Brand"
                name="brand"
                required={true}
                autoComplete="off"
                value={newProductInfo.brand.name}
                handleChange={handleChange}
                validationError={validationError}
                validationErrorMessage="brand can't be blank"
            />
            {
                (productBrandList.length > 0 && isVisible)
                ?
                <div className={styles.category_brand_list} ref={elementRef}>
                    <ul>
                        {productBrandList.map(item => {
                            return (
                                <li key={item._id} onClick={() => chooseBrand(item)}>
                                    {item.name}
                                </li>
                            ) 
                        })}
                    </ul>
                </div>
                :
                null
            }
        </div>
    );

}

export default SelectBrand;
