import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { addNewAppointmentInfo } from "../../../store/calendar/calendarReducer";
import { formatDateTime } from "../../../helpers/date-time/dateTimeHelpers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./AddAppointmentModal.module.css";


const SelectDate: React.FC = () => {

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { newAppointmentInfo, validationError } = calendarState;
    const { appointment_date } = newAppointmentInfo;

    function selectAppointmentDate(date: Date) {

        // We are taking a detour because react-datepicker selected date causing issues.
        // User selects June 2 but somehow it becomes June 1. 
        const { dateString } = formatDateTime(date);
        const { hours, minutes } = formatDateTime(new Date());

        const selectedDate = new Date(`${dateString}T${hours}:${minutes}`);
      
        dispatch(addNewAppointmentInfo({
            name: "appointment_date",
            value: selectedDate
        }));

    }


    return (
        <div className = {styles.select_date}>
            <p className = {styles.select_date_label}>Select Date</p>
            <div className = {styles.select_date_datepicker}>
                <DatePicker 
                    selected = {appointment_date}
                    onChange = {selectAppointmentDate} 
                />
            </div>
            {
                (validationError && !appointment_date)
                &&
                <p className = {styles.select_date_warning_message}>
                    please select a date
                </p>
            }
        </div>
    );

}


export default SelectDate;