import { useSelector, useDispatch } from "react-redux";
import { ISubscription, SubscriptionPrice } from "../../../types/models/Subscription";
import { selectSubPrice } from "../../../store/profile-tenant/tenantProfileReducer";
import { RootState } from "../../../store";
import styles from "./CheckoutForm.module.css";

interface SelectPriceProps {
    setTax: React.Dispatch<React.SetStateAction<number>>
}

const SelectPrice: React.FC<SelectPriceProps> = ({ setTax }) => {

    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;
    const tenantProfileState = useSelector((state: RootState) => state.tenantProfileState);
    const selectedSubscription = tenantProfileState.selectedSubscription as ISubscription;
    const { selectedSubscriptionPrice } = tenantProfileState;

    const handleCheckboxClick = (selectedSubPriceItem: SubscriptionPrice) => {
        dispatch(selectSubPrice(selectedSubPriceItem));
        setTax(0);
    }

    return (
        <div className = {styles.select_price}>
            {selectedSubscription.prices.map(price => {

                const { amount, recurringPaymentType, stripePriceId } = price;

                let isChecked = false;
                if(selectedSubscriptionPrice) {
                    isChecked = selectedSubscriptionPrice.stripePriceId === stripePriceId ? true : false;
                }

                const disabled = user?.stripeSubscription?.stripePriceId === stripePriceId;
                const classes = `${disabled ? "price_disabled" : ""}`;
            
                return (
                    <div key={stripePriceId} className={`${styles.checkbox_wrapper} ${styles[classes]}`}>
                        <div className={styles.round}>
                            <input 
                                name="selectSession"
                                type="checkbox" 
                                readOnly
                                checked={isChecked}
                            />
                            <label 
                                htmlFor="checkbox_18" 
                                onClick={() => handleCheckboxClick(price)}
                            >
                            </label>
                            <span>€{amount.toFixed(2)} / {recurringPaymentType}</span>
                        </div>
                    </div>
                );

            })}
        </div>
    );

}

export default SelectPrice;
