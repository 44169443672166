import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { handleChange } from "../../store/discounts/discountReducer";
import InputField from "./InputField";
import styles from "./index.module.css";


const DiscountTitle: React.FC = () => {

    const dispatch = useDispatch();
    const discountState = useSelector((state: RootState) => state.discountState);
    const { discountTitle, validationError } = discountState;

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        dispatch(handleChange({ name, value }));
    }

    return (
        <div className = {styles.discount_title}>
            <InputField 
                labelText = "Discount Title"
                name = "discountTitle"
                type = "text"
                placeholder = "Anything"
                value = {discountTitle}
                handleChange = {handleInputChange}
                validationError = {validationError}
                validationErrorMessage = "discount title can't be blank"
            />
        </div>
    );

}


export default DiscountTitle;