import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { updateSearchText, openAddTreatmentModal } from "../../store/treatments/treatmentReducer";
import { FETCH_DATA } from "../../store/treatments/constants";
import styles from "./index.module.css";
import InputField from "./InputField";
import Button from "./Button";


const SearchAdd = () => {

    const dispatch = useDispatch();
    const treatmentState = useSelector((state: RootState) => state.treatmentState);
    const userState = useSelector((state: RootState) => state.userState);
    const { treatmentDataFetchInfo } = treatmentState;
    const { searchText, skip, limit } = treatmentDataFetchInfo;
    const [isSearchTextBackspacedToEmpty, setIsSearchTextBackspacedToEmpty] = useState(false);
    

    useEffect(() => {

        if(!isSearchTextBackspacedToEmpty && !searchText) return; 
      
        // Calling setTimeout directly will make api call for each character typed. But assigning
        // this function to a variable re-initializes the timer. So, just one api call will be made
        // when user stops typing.
        const delayAPICall = setTimeout(() => {

            dispatch({ 
                type: FETCH_DATA,
                payload: { 
                    tenantId: userState.user?.tenantId, 
                    searchText, 
                    skip, 
                    limit,
                    fetchCategoryAndStylistData: "No" 
                } 
            })

        }, 500);

        if(isSearchTextBackspacedToEmpty) setIsSearchTextBackspacedToEmpty(false);

        return () => clearTimeout(delayAPICall);

    }, [searchText])


    const searchTreatments = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        dispatch(updateSearchText(value))
        if(!value) setIsSearchTextBackspacedToEmpty(true);
    }

    const openModal = () => dispatch(openAddTreatmentModal());

   
    return (
        <div className = {styles.search_add_btn}>
            <InputField
                customClassName="search_input" 
                type = "text"
                name = "search"
                value = {searchText}
                placeholder="Search"
                handleChange={searchTreatments}
            />
            <Button
                customClassName="add_btn"
                buttonText="Add New Treatment"
                handleClick = {openModal}
            />
        </div>
    );

}


export default SearchAdd;