import styles from "./LoginSignup.module.css";

interface IProps {
    buttonText: string,
    disabled?: boolean,
    handleSubmit: (args?: any) => void 
}

const SubmitButton: React.FC<IProps> = ({ disabled = false, buttonText, handleSubmit }) => {

    return (
        <div className = {styles.submit_button}>
            <button 
                type="submit"
                disabled={disabled}
                style = {{
                    backgroundColor: disabled ? "#ffa9a580" : "#FFAAA5"
                }}
                onClick = {handleSubmit}
            >
                {buttonText}
            </button>
        </div> 
    );

}

export default SubmitButton;