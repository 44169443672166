import styles from "./DataTable.module.css";

interface NextPrevViewProps {
    skip: number
    limit: number
    totalItemCount: number
    onNextPrevButtonClick: (buttonClickType: string) => void
}

const NextPrevView: React.FC<NextPrevViewProps> = (props) => {

    const { skip, limit, totalItemCount, onNextPrevButtonClick } = props;

    const fromItemCount = !totalItemCount ? 0 : skip + 1;
    const toItemCount = limit + skip > totalItemCount ? totalItemCount : limit + skip;

    return (
        <div className={styles.next_prev_view}>
            <div className={styles.item_count}>
                <p>Showing {fromItemCount} to {toItemCount} of {totalItemCount} results</p>
            </div>
            <div className={styles.next_prev_buttons}>
                <button onClick={() => onNextPrevButtonClick("prev")}>
                    Prev
                </button>
                <button onClick={() => onNextPrevButtonClick("next")}>
                    Next
                </button>
            </div>
        </div>
    );

}

export default NextPrevView;
