import axios from "axios";
import { config } from "../../Constants";
import Cookies from "universal-cookie";
import { handleApiError } from "../error-handlers/handleApiError";

type ArgsType = {
    appointment_id: string,
    activityBy: string,
    doNotSendEmailToClient: boolean, 
    selectedStylist: string,
    selectedStylistId: string,
    selectedDateTime: string
}

const cookies = new Cookies();

export const rescheduleAppointment = async (args: ArgsType) => {

    const endpoint = config.url.BACKEND_API_URL + "/appointments/reschedule";
    const headers = { 
        "auth-token": cookies.get("auth_token") 
    };

    try {

        const response = await axios.post(endpoint, args, { headers });
        const message: string = response.data.message;

        return message;

    } catch(error: any) {
       const { message } = handleApiError(error);
       return message;
    }

}