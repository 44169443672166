import useClickOutside from "../../../hooks/useClickOutside";
import styles from "./Dropdown.module.css";
import ArrowDown from "../../icons/ArrowDown";
import DropdownHiddenDiv from "./DropdownHiddenDiv";
import DropdownErrorMessage from "./DropdownErrorMessage";
import DropdownLabel from "./DropdownLabel";
import DropdownData from "./DropdownData";


interface IProps {
    customClassName?: string,
    dropdownListFor?: string,
    dropdownLabel?: string,
    validationErrorMessage?: string,
    data: any [], 
    nameKey: string, 
    selectedValue: string | number, 
    clickHandler: (args: any) => void,
}


const Dropdown = (props: IProps) => {

    const { 
        customClassName = "", 
        dropdownListFor = "", 
        dropdownLabel,
        validationErrorMessage = "",
        data, 
        nameKey, 
        selectedValue, 
        clickHandler 
    } = props;

    const { elementRef, isVisible, setIsVisible } = useClickOutside({ exceptions: [] });
    const toggleDropdown = () => setIsVisible(!isVisible);

    return (
        <div className = {styles.dropdown}>
            {
                dropdownLabel
                ?
                <DropdownLabel 
                    label = {dropdownLabel}
                />
                : null
            }
            <div className = {`${styles.dropdown_list} ${styles[customClassName]}`} >
                <DropdownHiddenDiv 
                    elementRef={elementRef}
                    handleClick={toggleDropdown}
                />
                <p>
                    {dropdownListFor === "view_item" ? `Show ${selectedValue}` : selectedValue}
                </p>
                <ArrowDown />
                <DropdownData 
                    isVisible = {isVisible}
                    data = {data}
                    nameKey={nameKey}
                    dropdownListFor = {dropdownListFor}
                    handleClick={clickHandler}
                />
            </div>
            {
                validationErrorMessage 
                ? 
                <DropdownErrorMessage 
                    errorMessage={validationErrorMessage}
                /> 
                : 
                null
            }
        </div>
    );

}


export default Dropdown;