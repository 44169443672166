import styles from "./InvoiceList.module.css";

interface IProps {
    invoiceCount: number
}


const InvoiceListHeader: React.FC<IProps> = ({ invoiceCount }) => {

    return (
        <div className = {styles.invoice_list_header}>
            <p>Invoices{`(${invoiceCount})`}</p>
            <button>Show All</button>
        </div>
    );

}


export default InvoiceListHeader;