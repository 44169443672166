import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDiscount } from "../../types/models/Discount";
import { ILocation } from "../../types/models/Location";
import { IUser } from "../../types/models/User";
import { DiscountState, FetchedData } from "../../types/pages/Discount";
import { filterStylistList } from "../../helpers/discounts/filterStylistList";
import { filterTreatmentList } from "../../helpers/discounts/filterTreatmentList";


const initialState: DiscountState = {
    loading: false,
    error: false,
    actionMessage: "",
    discountList: [],
    locationList: [],
    stylistList: [],
    treatmentList: [],
    discountTitle: "",
    validationError: false,
    filteredStylists: [],
    filteredTreatments: [],
    selectedLocations: [],
    selectedStylists: [],
    selectedTreatments: [],
    offPeakDiscountList: [],
    lastMinuteDiscountRate: 0,
    lastMinuteDiscountHours: 0,
    isAddingUpdatingDiscount: false,
    discount: null,
    discountId: null,
    showDeletePrompt: false
}


const discountSlice = createSlice({
    name: "discount",
    initialState,
    reducers: {

        fetchDataLoading: (state) => {
            return { 
                ...state, 
                loading: true 
            }
        },

        fetchDataSuccess: (state, action: PayloadAction<FetchedData>) => {

            const { 
                discountList, 
                locationList, 
                treatmentList, 
                stylistList 
            } = action.payload;

            return {
                ...state,
                loading: false,
                locationList,
                discountList,
                treatmentList,
                stylistList,
                filteredStylists: stylistList,
                filteredTreatments: treatmentList 
            }

        },

        fetchDataFail: (state) => {
            return {
                ...state,
                loading: false,
                error: true
            }
        },

        handleChange: (state, action: PayloadAction<any>) => {
            const { name, value } = action.payload;
            return {
                ...state,
                [name]: value
            }
        },

        editDiscount: (state, action: PayloadAction<IDiscount>) => {

            const discountItem = action.payload;

            const filteredStylists = filterStylistList({ 
                locationList: discountItem.locationList,
                stylistList: state.stylistList
            });
            const filteredTreatments = filterTreatmentList({
                stylistList: discountItem.stylistList,
                treatmentList: state.treatmentList
            });

            return {
                ...state,
                isAddingUpdatingDiscount: true,
                discount: action.payload,
                filteredStylists,
                filteredTreatments,
                discountTitle: discountItem.title,
                selectedLocations: discountItem.locationList,
                selectedStylists: discountItem.stylistList,
                selectedTreatments: discountItem.treatmentList,
                lastMinuteDiscountRate: discountItem.lastMinuteDiscountRate,
                lastMinuteDiscountHours: discountItem.lastMinuteDiscountHours,
                offPeakDiscountList: discountItem.offPeakDiscountList
            }

        },

        closeAddEditDiscount: (state) => {
            return {
                ...state,
                isAddingUpdatingDiscount: false,
                discount: null,
                discountTitle: "",
                validationError: false,
                lastMinuteDiscountRate: 0,
                lastMinuteDiscountHours: 0,
                offPeakDiscountList: [],
                selectedLocations: [],
                selectedStylists: [],
                selectedTreatments: [],
                filteredStylists: state.stylistList,
                filteredTreatments: state.treatmentList,
            }
        },

        selectLocation: (state, action: PayloadAction<{ locationActionType: string, selectedLocations: ILocation[] }>) => {

            // Notes
            // 1. We need to filter stylists based on selected locations.
            // 2. If user selects location then previously updated states of filteredTreatments, selectedStylists and
            // selectedTreatments should remain intact
            // 3. But if user unselects location then states of these three should be set back to their intials values
            const { locationActionType, selectedLocations } = action.payload;
            const filteredStylists = filterStylistList({ 
                locationList: selectedLocations, 
                stylistList: state.stylistList 
            });

            return {
                ...state,
                selectedLocations,
                filteredStylists,
                filteredTreatments: locationActionType === "unselect" ? state.treatmentList : state.filteredTreatments,
                selectedStylists: locationActionType === "select" ? state.selectedStylists : [],
                selectedTreatments: locationActionType === "select" ? state.selectedTreatments : []
            };

        },

        selectStylist: (state, action: PayloadAction<{ stylistActionType: string, selectedStylists: IUser[] }>) => {

            // If user selects stylist then selectedTreatments should remain. Otherwise it must be set back to it's
            // initial value to avoid conflicts
            const { stylistActionType, selectedStylists } = action.payload;
            const newFilteredTreatments = filterTreatmentList({ 
                stylistList: selectedStylists, 
                treatmentList: state.treatmentList 
            });

            return {
                ...state,
                filteredTreatments: newFilteredTreatments,
                selectedStylists,
                selectedTreatments: stylistActionType === "select" ? state.selectedTreatments : []
            }
        },

        toggleDeletePrompt: (state, action: PayloadAction<null | string>) => {
            return {
                ...state,
                discountId: action.payload,
                showDeletePrompt: !state.showDeletePrompt
            }

        }

    }
})

export const {
    fetchDataLoading,
    fetchDataSuccess,
    fetchDataFail,
    handleChange,
    editDiscount,
    closeAddEditDiscount,
    selectLocation,
    selectStylist,
    toggleDeletePrompt
} = discountSlice.actions;

export default discountSlice.reducer;