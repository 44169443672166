import { RootState } from "../store";
import { select, call, put, takeLatest } from "redux-saga/effects";
import { 
    FetchedData, 
    FetchedProductSalesData,
    FetchedPurchaseOrderData,
    ProductState
} from "../types/pages/Product";
import { fetchProducts } from "./api/products/fetchProducts";
import { fetchProductSales } from "./api/products/fetchProductSales";
import { fetchPurchaseOrders } from "./api/products/fetchPurchaseOrders";
import { 
    fetchDataLoading,
    fetchDataSuccess, 
    fetchProductSalesDataSuccess,
    fetchPurchaseOrderDataSuccess,
    fetchDataFail
} from "../store/products/productReducer";
import { 
    FETCH_PRODUCT_DATA,
    FETCH_PRODUCT_SALE_DATA,
    FETCH_PURCHASE_ORDER_DATA 
} from "../store/products/constants";
import { handleApiError } from "../helpers/error-handlers/handleApiError";

const productState = (state: RootState) => state.productState;

function* handleFetchProductData() {

    const { searchText, skip, limit }: ProductState = yield select(productState);

    try {
        yield put(fetchDataLoading());
        const result: FetchedData = yield call(fetchProducts, { searchText, skip, limit });
        yield put(fetchDataSuccess(result));  
    } catch(error: any) {
        const { message } = handleApiError(error)
        yield put(fetchDataFail(message));
    }

}

function* handleFetchProductSalesData() {

    const { searchText, skip, limit }: ProductState = yield select(productState);

    try {
        yield put(fetchDataLoading());
        const result: FetchedProductSalesData = yield call(fetchProductSales, { searchText, skip, limit });
        yield put(fetchProductSalesDataSuccess(result));  
    } catch(error: any) {
        const { message } = handleApiError(error)
        yield put(fetchDataFail(message));
    }

}

function* handleFetchPurchaseOrderData() {

    const { searchText, skip, limit }: ProductState = yield select(productState);

    try {
        yield put(fetchDataLoading());
        const result: FetchedPurchaseOrderData = yield call(fetchPurchaseOrders, { searchText, skip, limit });
        yield put(fetchPurchaseOrderDataSuccess(result));  
    } catch(error: any) {
        const { message } = handleApiError(error)
        yield put(fetchDataFail(message));
    }

}

export default function* productSaga() {
    yield takeLatest(FETCH_PRODUCT_DATA, handleFetchProductData);
    yield takeLatest(FETCH_PRODUCT_SALE_DATA, handleFetchProductSalesData);
    yield takeLatest(FETCH_PURCHASE_ORDER_DATA, handleFetchPurchaseOrderData);
}
